import { colors } from '@pay24/common'
import { TouchableOpacity, View } from 'react-native'
import React, { useState } from 'react'
import Icon from './Icon'
import { observer } from 'mobx-react'
import { ICustomInput, Input } from './Input'
import Picker from './Picker'
import { useTranslation } from '@pay24/common/src/translate'
import { Text } from './Text'

interface Props extends Omit<ICustomInput, 'value'> {
  testID?: string
  items: any[]
  model?: any
  name?: string
  label?: string
  placeholder?: string
  value: any
  onChange: (item: any) => void
  onClearPress?: () => void
  disabled?: boolean
  clearable?: boolean
  searchable?: boolean
  valueCode?: string
  valueName?: string
  renderItem?: (item: any) => void
  renderValue?: (item: any) => void
  errorText?: string
  sorted?: boolean
}

const ListPicker = ({
  testID = '',
  model,
  name,
  disabled,
  valueName = '',
  label = '',
  valueCode = '',
  style,
  value,
  // theme,
  clearable = true,
  errorText,
  onChange,
  onClearPress,
  items,
  sorted,
  searchable,
  ...rest
}: Props) => {
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()

  const toggleModal = () => {
    if (!disabled) {
      setVisible((visb) => !visb)
    }
  }

  valueName = valueName || 'name'
  valueCode = valueCode || 'id'
  if (model && name) {
    value = model[name]
  }

  let text = ''
  if (value && typeof value === 'object') text = value[valueName]
  if (value && typeof value !== 'object') {
    const i = items.find((item) => item[valueCode] === value)
    text = i ? i[valueName] : value
  }
  return (
    <>
      <Input
        testID={testID}
        label={t(label)}
        errorText={errorText}
        value={!text ? '' : String(text)}
        onPress={toggleModal}
        editable={false}
        clearable={clearable}
        autoCorrect={false}
        autoCapitalize={'none'}
        onChangeText={() => {
          if (onClearPress) {
            onClearPress()
          }
        }}
        inputComponent={(props) => (
          <Text
            {...props}
            size={15}
            style={{ flex: 1, marginHorizontal: 5 }}
            onPress={toggleModal}
          >
            {!text ? '' : String(text)}
          </Text>
        )}
        rightComponent={
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: 6,
            }}
          >
            <TouchableOpacity onPress={toggleModal} disabled={disabled}>
              <Icon name="chevron-down" color={colors.metal} size={22} />
            </TouchableOpacity>
          </View>
        }
        disabled={disabled}
        {...rest}
        style={[{ marginBottom: 10 }, style]}
      />
      <Picker
        testID={testID}
        items={items}
        title={label || ''}
        valueCode={valueCode}
        valueName={valueName}
        model={model}
        name={name}
        sorted={sorted}
        searchable={searchable}
        show={visible}
        onClose={toggleModal}
        onChange={(item) => {
          if (onChange) {
            onChange(item)
          }
        }}
        {...rest}
      />
    </>
  )
}

export default observer(ListPicker)
