import { colors, hooks } from '@pay24/common'
import { Text } from '@ui-kitten/components'
import React, { memo, useCallback, useMemo } from 'react'
import { Pressable, ScrollView, TouchableOpacity, View } from 'react-native'
import Icon from './Icon'

const { useResponsive } = hooks

interface PageItem {
  value: number
  label: string
  selected: boolean
}

function toPageItem(page: number, selected = false): PageItem {
  return {
    value: page,
    label: page.toString(),
    selected,
  }
}

function usePages(page = 1, size = 40, count = 0) {
  const { lg } = useResponsive()
  const pages_count = count > 0 ? Math.ceil(count / size) : 0
  return useMemo(() => {
    if (pages_count === 0) {
      return []
    }
    if (pages_count < (lg ? 10 : 8)) {
      return new Array(pages_count).fill(1).map((_, i) => {
        const p = i + 1
        return toPageItem(p, p === page)
      })
    }
    const pages: PageItem[] = []
    page > 1 && pages.push(toPageItem(1))
    page > (lg ? 4 : 3) &&
      pages.push(toPageItem(Math.ceil((page - (lg ? 3 : 2)) / 2) + 1))
    lg && page > 3 && pages.push(toPageItem(page - 2))
    page > 2 && pages.push(toPageItem(page - 1))
    pages.push(toPageItem(page, true))
    const d = pages_count - page
    d > 1 && pages.push(toPageItem(page + 1))
    lg && d > 2 && pages.push(toPageItem(page + 2))
    d > (lg ? 3 : 2) &&
      pages.push(toPageItem(pages_count - Math.floor((d - (lg ? 2 : 1)) / 2)))
    d > 0 && pages.push(toPageItem(pages_count))
    return pages
  }, [page, pages_count, lg])
}

interface PaginationProps {
  page?: number
  size?: number
  count?: number

  onPageSelect?(page: number): void
}

const Pagination = memo(function Pagination(props: PaginationProps) {
  const { page = 1, size = 40, count = 0, onPageSelect } = props
  const pages = usePages(page, size, count)

  const renderPage = useCallback(
    (i: PageItem) => (
      <Page
        key={i.value}
        value={i.value}
        label={i.label}
        selected={i.selected}
        onPress={onPageSelect}
      />
    ),
    [onPageSelect],
  )

  const can_prev = page > 1
  const can_next = Math.ceil(count / size) > page

  const prevPage = useCallback(() => {
    onPageSelect?.(page - 1)
  }, [page, onPageSelect])

  const nextPage = useCallback(() => {
    onPageSelect?.(page + 1)
  }, [page, onPageSelect])

  return (
    <View style={{ alignItems: 'center', marginVertical: 30 }}>
      <ScrollView
        horizontal
        contentContainerStyle={{
          paddingHorizontal: 8,
          alignItems: 'center',
        }}
      >
        <TouchableOpacity
          testID="prev"
          style={{ marginRight: 5 }}
          onPress={prevPage}
          disabled={!can_prev}
        >
          <Icon
            name="chevron-left"
            color={!can_prev ? colors.metal : undefined}
          />
        </TouchableOpacity>
        {pages.map(renderPage)}
        <TouchableOpacity
          testID="next"
          style={{ marginLeft: 5 }}
          onPress={nextPage}
          disabled={!can_next}
        >
          <Icon
            name="chevron-right"
            color={!can_next ? colors.metal : undefined}
          />
        </TouchableOpacity>
      </ScrollView>
    </View>
  )
})

export default Pagination

interface PageProps extends PageItem {
  onPress?(value: number): void
}

const Page = memo(function Page(props: PageProps) {
  const { value, label, selected, onPress } = props
  const _onPress = useCallback(
    () => onPress && onPress(value),
    [value, onPress],
  )
  return (
    <Pressable
      onPress={_onPress}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
        height: 36,
        backgroundColor: selected ? colors.primary : colors.white,
        marginHorizontal: 5,
        borderRadius: 6,
      }}
    >
      <Text
        style={{
          color: selected ? colors.white : colors.black,
        }}
      >
        {label}
      </Text>
    </Pressable>
  )
})
