import { store, translate } from '@pay24/common'
import { MenuItem } from '@ui-kitten/components'
import React from 'react'
import { MenuIcon } from './MenuIcon'
import { MenuTitle } from './MenuTitle'
import { confirmAlert } from '../alert'

const { RootStore } = store
const { useTranslation } = translate

export const ExitMenuItem = ({ onPress }) => {
  const { t } = useTranslation()
  const onExit = () =>
    confirmAlert(
      t('confirmation'),
      t('confirmation_text'),
      t('cancel'),
      t('exit'),
    )
      .then(() => {
        RootStore.logout()
        onPress && onPress()
      })
      .catch(() => console.log('cancel'))
  return (
    <MenuItem
      testID={'exit'}
      style={{ paddingLeft: 16, backgroundColor: 'transparent' }}
      title={<MenuTitle title={'exit'} />}
      accessoryLeft={<MenuIcon name={'login-variant'} />}
      onPress={onExit}
    />
  )
}
