import { colors } from '@pay24/common'
import { PaymentStore } from '@pay24/common/src/store'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { isMobile } from '@pay24/common/src/utils'
import { FormBaseValues, FormDone, FormFail, Pay3D } from './DefaultTemplate'
import { requisiteScheme } from './schemes'
import { amountPlaceholder } from './utils'
import ContactPicker from '../ContactPicker'
import { Button } from '../Button'
import { Divider, DotsDropdown, ItemContainer } from '../Components'
import { InputModel } from '../Input'
import { Description, Text } from '../Text'
import { MenuItem } from '@ui-kitten/components'
import { FormikProps, useFormik } from 'formik'
import { observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import React, { useState } from 'react'
import { View } from 'react-native'
import { useTheme } from '@pay24/common/src/hooks'
import Spacer from '../Spacer'
import { i18n, useTranslation } from '@pay24/common/src/translate'

const FormInput = observer((props) => {
  const {
    service,
    source,
    valid_fields,
    add_params,
    add_param_valid_fields,
    selectRequisite = true,
  } = props
  const { payData, isAgent } = PaymentStore
  const [visible, setVisible] = useState(false)
  const options = getSnapshot<any>(payData.options)
  const { t } = useTranslation()
  const currency = 'KGS'

  const formik: FormikProps<FormBaseValues> = useFormik<FormBaseValues>({
    initialValues: {
      payData,
      options,
      isAgent,
      source,
      add_params,
    },
    enableReinitialize: true,
    validationSchema: requisiteScheme(
      service,
      valid_fields,
      add_param_valid_fields,
      selectRequisite,
    ),
    onSubmit: (values) => {
      payData.setValue('service_id', service.id)
      payData.setValue('service_code', service.type)
      payData.setValue('service_name', service.name)
      payData.setValue('requisite', payData.requisite.replace(/\s/g, ''))
      payData.setValue('form_scheme', service?.data?.form_scheme)
      PaymentStore.requisiteCheck(values.source).then((res) => {
        PaymentStore.setPaymentState(FORM.CHECK)
        PaymentStore.setStep(2)
        if (res.warning) {
          PaymentStore.setPaymentWarning(res.warning)
        }
      })
    },
  })

  const templateSum = [50, 100, 200, 500, 1000]

  return (
    <>
      {!PaymentStore.isAgent && formik.errors.source ? (
        <Text
          style={{
            color: colors.red,
            marginTop: 3,
            marginLeft: 3,
            fontSize: 10,
          }}
        >
          {formik.errors.source}
        </Text>
      ) : null}
      <Spacer />
      <InputModel
        testID="requisite"
        model={formik.values.payData}
        name="requisite"
        value={payData.requisite}
        errorText={formik.errors.payData ? formik.errors.payData.requisite : ''}
        label={service?.data?.requisite_message || 'requisite'}
        inputMask={
          service?.placeholder?.replace(/ /g, '') !== ''
            ? service?.placeholder?.replace(/_/g, '9')
            : ''
        }
        keyboardType={'phone-pad'}
        placeholder={service?.placeholder}
        rightComponent={
          <DotsDropdown placement="bottom end">
            {isMobile ? (
              <MenuItem
                title={<Text>Из контактов</Text>}
                onPress={() => setVisible(true)}
              />
            ) : null}
          </DotsDropdown>
        }
      />
      <Spacer />
      <InputModel
        testID="moneyAmount"
        label={`${t('sum')} ${currency}`}
        keyboardType="numeric"
        name="amount"
        value={formik.values.payData.amount?.toString()}
        errorText={formik.errors.payData ? formik.errors.payData.amount : ''}
        model={formik.values.payData}
        placeholder={amountPlaceholder(service)}
        rightComponent={
          <DotsDropdown placement="bottom end">
            {templateSum.map((sum) => (
              <MenuItem
                key={sum}
                title={<Text>{sum}</Text>}
                onPress={() => payData.setValue('amount', sum)}
              />
            ))}
          </DotsDropdown>
        }
      />
      <Spacer />
      <Button
        testID="nextBtn"
        disabled={PaymentStore.buttonBlock}
        onPress={() => formik.handleSubmit()}
      >
        {i18n.t('next')}
      </Button>
      {isMobile ? (
        <ContactPicker
          onClose={() => setVisible(false)}
          show={visible}
          onChange={(val) => payData.setValue('requisite', val)}
        />
      ) : null}
    </>
  )
})

const CheckItem = ({ title, model, name, currency = '' }) => (
  <>
    {model?.[name] ? (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingVertical: 3,
          alignItems: 'center',
        }}
      >
        <Description>{i18n.t(title)}:</Description>
        <Text style={{ textAlign: 'right', fontWeight: 'bold', flex: 1 }}>
          {currency ? `${model?.[name]} ${currency}` : model?.[name]}
        </Text>
      </View>
    ) : null}
  </>
)

const FormCheck = observer(
  ({ source, customFields, paymentApi, debit = true }) => {
    const { payData, buttonBlock } = PaymentStore
    const { isLight } = useTheme()
    const currency = 'KGS'

    return (
      <>
        <Spacer />
        <Text size={12} style={{ color: colors.metal }}>
          Информация о платеже
        </Text>
        <Spacer />
        <ItemContainer testID="paymentInfo" style={{ padding: 20 }}>
          {PaymentStore.warning ? (
            <>
              <Text color={colors.brown}>{PaymentStore.warning}</Text>
              <Spacer />
            </>
          ) : null}
          <CheckItem title="Абонент" model={payData} name={'customer_name'} />
          <CheckItem title="Реквизит" model={payData} name={'requisite'} />
          {customFields && customFields()}
          <CheckItem
            title="Сумма"
            model={payData}
            name={'amount'}
            currency={currency}
          />
          <Spacer />
          <CheckItem
            title="Комиссия платежа"
            model={payData}
            name={'paymentCommission'}
            currency={currency}
          />
          {!!payData.acquiringCommission && debit && (
            <CheckItem
              title="Комиссия эквайринга"
              model={payData}
              name={'acquiringCommission'}
            />
          )}
          {!!payData.commission && (
            <CheckItem
              title="Комиссия всего"
              model={payData}
              name={'commission'}
              currency={currency}
            />
          )}
          {!!payData.comment && (
            <CheckItem title="comment" model={payData} name={'comment'} />
          )}
          {debit && <CheckItem title="source" model={source} name="name" />}
          <Spacer />
          <Divider />
          <Spacer />
          <CheckItem
            title="К списанию"
            model={payData}
            name={'totalAmount'}
            currency={currency}
          />
        </ItemContainer>
        <Spacer />
        <Button
          testID="nextBtn"
          disabled={buttonBlock}
          onPress={() => {
            const data = {
              amount: payData.amount,
              requisite: payData.requisite,
              service_name: payData?.service_name,
              service_id: payData.service_id,
              service_code: payData.service_code,
              form_scheme: payData?.form_scheme,
              options: getSnapshot(payData.options),
            }
            if (paymentApi) {
              paymentApi(data)
            } else {
              PaymentStore.payData.setValue('theme', isLight ? 'light' : 'dark')
              PaymentStore.pay(source, () => {
                PaymentStore.setStep(PaymentStore.step + 1)
              })
            }
          }}
        >
          {i18n.t('next')}
        </Button>
      </>
    )
  },
)

export default function ChargeTemplate() {
  return {
    steps: 4,
    [FORM.INITIAL]: FormInput,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
