import { useTheme } from '@ui-kitten/components'
import MCI from 'react-native-vector-icons/MaterialCommunityIcons'
import React from 'react'

type IconProps = {
  testID?: string
  style?: any
  size?: number
  name?: string
  color?: string
  width?: number
  onPress?: () => void
}

export default (props: IconProps) => {
  const {
    testID,
    style = {},
    size = 24,
    name,
    color = '',
    width = undefined,
    ...rest
  } = props
  const theme = useTheme()
  return (
    <MCI
      testID={testID || null}
      size={size}
      name={name}
      width={width}
      color={color || theme['text-basic-color']}
      style={style}
      {...rest}
    />
  )
}
