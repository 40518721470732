import { hooks } from '@pay24/common'
import { observer } from 'mobx-react'
import React from 'react'
import { TouchableOpacity, View, ViewStyle } from 'react-native'
import Image from '../Image'
import { RootStore } from '@pay24/common/src/store'
import { getBucketPath } from '@pay24/common/src/utils'
import { Text } from '../Text'
import placeholderImage from '../../../assets/img/noavatar.png'

const { useTheme } = hooks

interface ProfileMenuItemProps {
  onPress: () => void
  avatar: string
  login: string
  compact?: boolean
  style?: ViewStyle
}

export const ProfileMenuItem = observer(function ProfileMenuItem(
  props: ProfileMenuItemProps,
) {
  const { onPress, login, avatar, compact = false, style } = props
  const { isLight, primary } = useTheme()
  const { isAuthorized } = RootStore
  if (!isAuthorized) return null
  return (
    <View
      style={[
        {
          paddingHorizontal: compact ? 10 : 16,
          paddingVertical: compact ? 5 : 12,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderBottomWidth: !compact ? 1 : 0,
          borderBottomColor: isLight
            ? 'rgba(0, 0, 0, 0.05)'
            : 'rgba(255, 255, 255, 0.05)',
        },
        style,
      ]}
    >
      <TouchableOpacity
        testID={'profile_settings'}
        onPress={onPress}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <Image
          style={{
            width: compact ? 40 : 50,
            height: compact ? 40 : 50,
            borderRadius: 25,
            borderWidth: 1,
            borderColor: 'rgba(255, 255, 255, 0.08)',
          }}
          source={
            avatar
              ? {
                  uri: `${getBucketPath(avatar, 'avatar')}`,
                }
              : placeholderImage
          }
        />

        <Text
          style={{ marginLeft: 10 }}
          size={compact ? 14 : 16}
          color={primary}
        >
          {login || ''}
        </Text>
      </TouchableOpacity>
    </View>
  )
})
