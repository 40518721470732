import { Layout, Spinner, Modal as KittenModal } from '@ui-kitten/components'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  FlatList,
  Image as NativeImage,
  TouchableOpacity,
  View,
} from 'react-native'
import Modal from 'react-native-modal'
import KAZ_flag from '../../assets/flags/kaz-flag.png'
import KGZ_flag from '../../assets/flags/kgz-flag.png'
import RUS_flag from '../../assets/flags/rus-flag.png'
import TJK_flag from '../../assets/flags/tjk-flag.png'
import UZB_flag from '../../assets/flags/uzb-flag.png'
import Icon from './Icon'
import { Input } from './Input'
import { Text } from './Text'
import { useResponsive, useTheme } from '@pay24/common/src/hooks'
import { useTranslation } from '@pay24/common/src/translate'
import { api, colors } from '@pay24/common'

const COUNTRY_FLAGS = {
  KG: KGZ_flag,
  KZ: KAZ_flag,
  UZB: UZB_flag,
  TJK: TJK_flag,
  RU: RUS_flag,
}

type Data = {
  name: string
  id: string | number
}

interface ICountry extends Data {}

// const COUNTRIES: ICountry[] = [{ id: 'KG', name: 'Кыргызская Республика' }]
const DEFAULT_COUNTRY: ICountry = { id: 'KG', name: 'Кыргызская Республика' }

interface IRegion {
  country: Data
  id: string
  name: string
}

interface IDistrict {
  region: Data
  name: string
  id: string
}

const keyExtractor = (item) => {
  return 'country' in item
    ? item.country.id
    : 'region' in item
    ? item.region.id
    : 'district' in item
    ? item.district.id
    : item.id
}

interface ISelectLocation {
  label?: string
  selectCountry?: (country: ICountry | null) => void
  selectRegion: (region: IRegion | null) => void
  selectDistrict?: (district: IDistrict | null) => void
  location: ICountry | IRegion | IDistrict | string | null
  errorText?: string | undefined
  customRegions?: Data[]
  showInModal?: boolean
}

const regex = /^г\./

const KYRGYZSTAN_ID = 'KG'

async function fetchRegionsDistricts(country_id: string | number): Promise<{
  regions: IRegion[]
  districts: IDistrict[]
}> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { regions } = await pay24.getRegions(country_id)
  const districts: IDistrict[] = await Promise.all(
    regions.map(async (r) => {
      const { districts: _districts } = await api.pay24.getDistricts(r.id)
      return _districts.map((d) => ({ ...d }))
    }),
  )
  return { regions, districts: districts.flat() }
}

function SelectLocation(props: ISelectLocation) {
  const { selectCountry, label = 'region_district' } = props
  const {
    selectRegion,
    selectDistrict,
    location,
    errorText,
    customRegions = [],
    showInModal = true,
  } = props

  const [open, setOpen] = useState<boolean>(false)
  const [country, setCountry] = useState<ICountry | null>(null)
  const [regions, setRegions] = useState<IRegion[]>([])
  const [region, setRegion] = useState<IRegion | null>(null)
  const [districts, setDistricts] = useState<IDistrict[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { sm: _sm } = useResponsive()
  const { t } = useTranslation()
  const showPicker = () => setOpen(true)
  const closePicker = () => setOpen(false)

  useEffect(() => {
    const canFetch =
      (!showInModal ? false : !open) ||
      customRegions.length > 0 ||
      regions.length > 0

    if (canFetch) return
    setLoading(true)
    fetchRegionsDistricts(KYRGYZSTAN_ID)
      .then((resp) => {
        setRegions(resp.regions)
        setDistricts(resp.districts)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [
    open,
    country,
    regions.length,
    selectDistrict,
    showInModal,
    customRegions.length,
  ])

  const onPressItem = useCallback(
    (item: IPickerItem) => {
      if ('country' in item) {
        setCountry((c) => (!c ? item.country : null))
        setRegion(null)
        setRegions([])
        setDistricts([])
      } else if ('region' in item) {
        if (item.region.name.includes('г') || customRegions.length > 0) {
          closePicker()
          selectCountry && selectCountry(DEFAULT_COUNTRY)
          selectDistrict && selectDistrict(null)
          selectRegion(item.region)
        }
        setRegion((r) =>
          !r && !regex.test(item.region.name) && !customRegions.length
            ? item.region
            : null,
        )
      } else {
        selectCountry && selectCountry(DEFAULT_COUNTRY)
        selectRegion(region)
        selectDistrict && selectDistrict(item.district)
        closePicker()
      }
    },
    [
      country,
      customRegions.length,
      region,
      selectCountry,
      selectDistrict,
      selectRegion,
    ],
  )

  const renderItem = useCallback(
    ({ item }) => <PickerItem item={item} onPress={onPressItem} />,
    [onPressItem],
  )

  const parent_items = useMemo(() => {
    const parent_items_: IRegion[] = []
    if (region) parent_items_.push(region)
    return parent_items_
  }, [region])

  const child_items = useMemo(() => {
    if (regions.length > 0 && !region) {
      const withoutCities = regions.filter((r) => !regex.test(r.name))
      const onlyCities = regions.filter((r) => regex.test(r.name))
      return [...onlyCities, ...withoutCities]
    }

    if (customRegions.length > 0)
      return customRegions.map((cr) => ({ ...cr, country: { id: 'KG' } }))
    return []
  }, [customRegions, region, regions])

  const data = useMemo(
    () => [
      ...parent_items.map((pi) => {
        const key = 'country' in pi ? 'region' : 'country'
        return {
          variant: PickerItemVariant.Parent,
          [key]: pi,
        }
      }),
      ...child_items.map((ci) => {
        const key = 'country' in ci ? 'region' : 'country'
        return {
          variant: PickerItemVariant.Children,
          [key]: ci,
        }
      }),
      ...districts
        .filter((d) => d.region.id === region?.id)
        .map((d) => ({
          variant: PickerItemVariant.None,
          district: d,
        })),
    ],
    [child_items, districts, parent_items, region?.id],
  )

  const ModalWrapper = _sm ? LocationModalWeb : LocationModalNative

  const rightComponent = (
    <Icon color={colors.metal} name="chevron-down" size={22} />
  )
  const onClearText = (value) => {
    if (!value) {
      selectCountry && selectCountry(null)
      selectRegion(null)
      selectDistrict && selectDistrict(null)
      setRegion(null)
      setCountry(null)
      setRegions([])
      setDistricts([])
    }
  }

  return showInModal ? (
    <>
      <Input
        label={t(label)}
        onPress={showPicker}
        editable={false}
        value={typeof location === 'string' ? location : location?.name || ''}
        overlayPress
        rightComponent={rightComponent}
        clearable
        onChangeText={onClearText}
        errorText={errorText}
      />
      <ModalWrapper visible={open} closeModal={closePicker}>
        <ModalTitle closeModal={closePicker} label={label} />
        <FlatList
          data={data}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          ListFooterComponent={loading ? <Loader /> : null}
        />
      </ModalWrapper>
    </>
  ) : (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ListFooterComponent={loading ? <Loader /> : null}
    />
  )
}

function ModalTitle({ closeModal, label }) {
  const { isLight } = useTheme()
  const { t } = useTranslation()
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingVertical: 13,
        borderBottomWidth: 1,
        borderColor: isLight ? colors.borderLight : colors.borderDark,
      }}
    >
      <Text category="h1" size={16}>
        {t(label)}
      </Text>
      <Icon name="close" onPress={closeModal} testID="close_modal" />
    </View>
  )
}

function LocationModalWeb({ visible, closeModal, children }) {
  return (
    <KittenModal
      visible={visible}
      onBackdropPress={closeModal}
      backdropStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      style={{ width: 400, maxHeight: 400, minHeight: 300 }}
    >
      <Layout style={{ flex: 1, borderRadius: 5 }}>{children}</Layout>
    </KittenModal>
  )
}

function LocationModalNative({ visible, closeModal, children }) {
  return (
    <Modal
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={visible}
      onBackdropPress={closeModal}
      onBackButtonPress={closeModal}
      style={{ margin: 0, justifyContent: 'flex-end', paddingTop: 100 }}
    >
      <Layout style={{ maxHeight: '100%', minHeight: 300 }}>{children}</Layout>
    </Modal>
  )
}

export default observer(SelectLocation)

enum PickerItemVariant {
  Parent = 'parent',
  Children = 'children',
  None = '',
}

type IPickerItem = {
  variant: PickerItemVariant
} & ({ country: ICountry } | { region: IRegion } | { district: IDistrict })

interface IPickerItemProps {
  item: IPickerItem
  onPress: (item) => void
}

function PickerItem({ item, onPress }: IPickerItemProps) {
  const { isLight } = useTheme()
  const _onPress = () => onPress(item)
  const _item =
    'country' in item
      ? item.country
      : 'region' in item
      ? item.region
      : 'district' in item
      ? item.district
      : item

  return (
    <TouchableOpacity
      style={{
        paddingHorizontal: 16,
        paddingVertical: 8,
        borderBottomWidth: 1,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        borderColor: isLight ? colors.borderLight : colors.borderDark,
      }}
      onPress={_onPress}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {item.variant === PickerItemVariant.Parent ? (
          <Icon name="chevron-left" size={20} color={colors.metal} />
        ) : null}
        {'country' in item && item.variant === PickerItemVariant.Children ? (
          <NativeImage
            source={COUNTRY_FLAGS[_item.id]}
            style={{ width: 24, height: 24 }}
          />
        ) : null}
        {'region' in item && item.variant === PickerItemVariant.Children ? (
          <Icon name="map-outline" size={20} color={colors.metal} />
        ) : null}
        {'district' in item ? (
          <Icon name="map-marker-outline" size={20} color={colors.metal} />
        ) : null}
        <View style={{ flex: 1, marginLeft: 8 }}>
          <Text>{_item.name}</Text>
        </View>
        {item.variant === PickerItemVariant.Children ? (
          <Icon name="chevron-right" size={20} color={colors.metal} />
        ) : null}
      </View>
    </TouchableOpacity>
  )
}

function Loader() {
  return (
    <View style={{ alignItems: 'center', marginTop: 8 }}>
      <Spinner />
    </View>
  )
}
