import { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'
import { BREAK_POINTS } from './constants'

/**
 * @return {string}
 */
function getBreakPoint () {
  const { width } = Dimensions.get('window')
  const match = BREAK_POINTS.find(p => p.value <= width)
  return match && match.key || BREAK_POINTS[BREAK_POINTS.length - 1].key
}

/**
 * @return {string}
 */
export default function useBreakPoint () {
  const [point, setPoint] = useState(getBreakPoint())
  useEffect(() => {
    const onChange = () => setPoint(getBreakPoint())
    return Dimensions.addEventListener('change', onChange).remove
  }, [])
  return point
}
