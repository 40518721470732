import React, { useCallback, useRef } from 'react'
import GoogleRecaptcha from 'react-google-recaptcha'
import constants from '../constants'

export const useRecaptcha = () => {
  const googleReCaptcha = useRef<any>(null)

  const getToken = useCallback(async () => {
    googleReCaptcha.current.reset()
    return (await googleReCaptcha.current.executeAsync()) as string
  }, [googleReCaptcha])

  return {
    element: (
      <GoogleRecaptcha
        ref={googleReCaptcha}
        size="invisible"
        sitekey={constants.RECAPTCHA_SITE_KEY}
      />
    ),
    getToken,
  }
}
