import { observer } from 'mobx-react'
import { hooks, store, utils } from '@pay24/common'
import { View } from 'react-native'
import React from 'react'
import * as Progress from 'react-native-progress'

const { useResponsive } = hooks
const { isWeb, safeAreaUtils } = utils
const { RootStore } = store

export const BusyProgress = observer(() => {
  const { screenWidth } = useResponsive()
  return (
    <View
      style={{
        position: 'absolute',
        zIndex: 100000,
        top: isWeb ? 0 : safeAreaUtils.getStatusBarHeight(true),
        left: 0,
        right: 0,
        overflow: 'hidden',
      }}
    >
      {RootStore.isBusy ? (
        <Progress.Bar
          indeterminate
          borderRadius={0}
          width={screenWidth}
          borderWidth={0}
          height={6}
          unfilledColor="rgba(0, 153, 255, 0.2)"
          color="#0099ff"
          indeterminateAnimationDuration={1500}
        />
      ) : null}
    </View>
  )
})
