import CryptoJS from 'crypto-js'

function encrypt(data: string, password: string) {
  const encrypted = CryptoJS.AES.encrypt(data, password)
  return encrypted.toString()
}

function decrypt(securedString: string, password: string) {
  const decrypted = CryptoJS.AES.decrypt(securedString, password)
  return decrypted.toString()
}

function sha1Hash(value: string) {
  const hash = CryptoJS.SHA1(value)
  return CryptoJS.enc.Hex.stringify(hash)
}

export default {
  encrypt,
  decrypt,
  sha1Hash,
}
