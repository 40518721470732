import { useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { GAP_SIZE, SCREEN_MAX_WIDTH } from './constants'
import useBreakPoint from './useBreakPoint'
import useBreakPointValue from './useBreakPointValue'

const styles = StyleSheet.create({
  screen: { width: '100%', alignSelf: 'center' },
})

export default function useScreenWrapperStyle (style) {
  const point = useBreakPoint()
  const maxWidth = useBreakPointValue(point, SCREEN_MAX_WIDTH)
  const paddingHorizontal = useBreakPointValue(point, GAP_SIZE)
  return useMemo(() => [styles.screen, { maxWidth, paddingHorizontal }, style], [maxWidth, paddingHorizontal, style])
}
