import { ScrollView, TouchableOpacity, View, ViewStyle } from 'react-native'
import React, { ReactNode, useMemo, useState } from 'react'
import { hooks, translate } from '@pay24/common'
import Modal from './Modal'
import { isObservableArray } from 'mobx'
import FlatList from './FlatList'
import SearchBar from './SearchBar'
import { Text } from './Text'
import Spacer from './Spacer'

const { useResponsive } = hooks
const { useTranslation } = translate

const itemStyle: ViewStyle = {
  alignItems: 'center',
  flexDirection: 'row',
  paddingVertical: 5,
  paddingHorizontal: 15,
}

type Props = {
  testID?: string
  title?: string
  searchable?: boolean
  show?: boolean
  onClose?: () => void
  valueName?: string
  valueCode?: string
  renderItem?: (item: any) => void
  items: any[]
  onChange?: (item: any) => void
  sorted?: boolean
  model?: any
  name?: string
}

export default ({
  testID = '',
  title,
  searchable,
  show,
  onClose,
  valueName = 'id',
  renderItem,
  items,
  onChange,
  sorted,
  model,
  name,
}: Props) => {
  const { sm, screenWidth, screenHeight } = useResponsive()
  const [value, setValue] = useState('')
  const { t } = useTranslation()

  let data = items || []
  if (value) {
    data = items.filter((i) => {
      if (i[valueName]) {
        return i[valueName]
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase())
      } else {
        return i
          .toString()
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase())
      }
    })
  }

  if (sorted) {
    if (isObservableArray(data))
      data = data
        .slice()
        .sort(
          (a, b) => a[valueName] && a[valueName].localeCompare(b[valueName]),
        )
    else
      data = data.sort(
        (a, b) => a[valueName] && a[valueName].localeCompare(b[valueName]),
      )
  }

  const renderItems = useMemo(() => {
    valueName = valueName || 'name'
    return (
      <View style={{ maxHeight: 500 }}>
        <FlatList
          testID={testID}
          data={data}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              key={index}
              style={itemStyle}
              onPress={() => {
                if (model && name) {
                  model[name] = item
                }
                onChange && onChange(item)
                onClose && onClose()
              }}
            >
              {renderItem ? (
                (renderItem(item) as ReactNode)
              ) : (
                <Text style={{ fontSize: 16, flex: 1 }}>{item[valueName]}</Text>
              )}
            </TouchableOpacity>
          )}
          noText={undefined}
          noDivider={undefined}
        />
      </View>
    )
  }, [data])

  return (
    <Modal
      visible={show}
      onClose={onClose}
      title={t(title || '')}
      style={!sm ? { width: screenWidth, height: screenHeight } : null}
    >
      {searchable && (
        <View style={{ paddingHorizontal: 10 }}>
          <SearchBar value={value} onChangeText={(val) => setValue(val)} />
          <Spacer />
        </View>
      )}
      {!!show && renderItems}
    </Modal>
  )
}
