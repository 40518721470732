import { i18n } from '@pay24/common/src/translate'
import * as yup from 'yup'

// общая схема
export const requisiteScheme = (
  service,
  validFields,
  add_param_valid_fields = {},
  selectRequisite = true,
) => {
  return yup.object().shape({
    payData: yup.object({
      requisite: selectRequisite
        ? yup.string().required(i18n.t('required_requisite'))
        : yup.string(),
      amount: yup
        .number()
        .min(service.min_amount, (value) =>
          i18n.t('required_min', { min: value.min }),
        )
        .max(service.max_amount, (value) =>
          i18n.t('required_max', { max: value.max }),
        )
        .typeError(i18n.t('required_amount'))
        .required(i18n.t('required_amount')),
    }),
    options: yup.object(validFields),
    isAgent: yup.boolean(),
    source: yup
      .object()
      .nullable()
      .when('isAgent', {
        is: (value) => value === false,
        then: yup.object().nullable().required(i18n.t('required_source')),
      }),
    add_params: yup.object(add_param_valid_fields),
  })
}

// // схема для паспорт{а (менять не обязательно)
// export const passportScheme = yup.object().shape({
//   options: yup.object({
//     payer_dateto: yup
//       .string()
//       .required(i18n.t('required_payer_dateto'))
//       .nullable(),
//     payer_issuer: yup
//       .string()
//       .required(i18n.t('required_payer_issuer'))
//       .nullable(),
//     payer_passport_num: yup
//       .string()
//       .required(i18n.t('required_payer_passport_num'))
//       .nullable(),
//     payer_inn: yup.string().required(i18n.t('required_inn')).nullable(),
//   }),
//   f: yup
//     .string()
//     .typeError(i18n.t('required_last_name'))
//     .required(i18n.t('required_last_name')),
//   i: yup
//     .string()
//     .typeError(i18n.t('required_name'))
//     .required(i18n.t('required_name')),
//   isAgent: yup.boolean(),
//   source: yup
//     .object()
//     .nullable()
//     .when('isAgent', {
//       is: (value) => value === false,
//       then: yup.object().nullable().required(i18n.t('required_source')),
//     }),
// })}

// схема для получателя
export const receiverScheme = yup.object().shape({
  f: yup.string().required(i18n.t('required_last_name')),
  i: yup.string().required(i18n.t('required_name')),
  isAgent: yup.boolean(),
  source: yup
    .object()
    .nullable()
    .when('isAgent', {
      is: (value) => value === false,
      then: yup.object().nullable().required(i18n.t('required_source')),
    }),
})
