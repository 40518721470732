import React from 'react'
import QrReader from 'react-qr-reader'

export default ({ onScanned, onError }) => (
  <QrReader
    delay={500}
    onError={(e) => {
      if (onError) onError(e)
    }}
    onScan={onScanned}
    style={{ width: 400 }}
  />
)
