import { RootStore } from '@pay24/common/src/store'
import React, { forwardRef, ReactNode, Ref, useMemo, useState } from 'react'
import { RefreshControl, View, Pressable } from 'react-native'
import { DrawerActions, useNavigation } from '@react-navigation/native'
import { Layout } from '@ui-kitten/components'
import { colors, hooks, translate, utils } from '@pay24/common'
import { Text } from '@pay24/ui'
import Icon from '@pay24/ui/src/Icon'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { observer } from 'mobx-react'
import { isWeb } from '@pay24/common/src/utils'

const { useTheme, useResponsive } = hooks
const { useTranslation } = translate
const { safeAreaUtils, isPay } = utils

const statusBarHeight = safeAreaUtils.getStatusBarHeight(true)

const HEADER_HEIGHT = isWeb ? 50 : 45

const HEIGHT = HEADER_HEIGHT + statusBarHeight

interface Props {
  onBackPress?: () => void
  onMenuPress?: () => void
  children?: ReactNode
  title?: string | ReactNode
  rightComponent?: ReactNode
  back?: boolean
  menu?: boolean
  scrollable?: boolean
  onRefresh?: () => void
  onLoadMore?: () => void
  backScreen?: string
  header?: ReactNode
}

interface HeaderProps {
  onBackPress?: () => void
  onMenuPress?: () => void
  title?: string | ReactNode
  rightComponent?: ReactNode
  back?: boolean
  menu?: boolean
  backScreen?: string
  children?: ReactNode
}

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
  const paddingToBottom = 40
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  )
}

export const ScreenHeader = observer(
  ({
    onBackPress,
    onMenuPress,
    title,
    rightComponent,
    backScreen,
    back,
    menu,
    children,
  }: HeaderProps) => {
    const { md } = useResponsive()
    const { isLight, primary } = useTheme()
    const { t } = useTranslation()
    const navigation = useNavigation()
    const backPress = () => {
      if (onBackPress) {
        onBackPress()
      } else {
        if (navigation.canGoBack()) {
          navigation.goBack()
        } else {
          backScreen && navigation.navigate(backScreen as never)
        }
      }
    }

    const menuPress = () => {
      if (onMenuPress) {
        onMenuPress()
      } else {
        navigation.dispatch(DrawerActions.toggleDrawer())
      }
    }

    return (
      <Layout
        level={isLight ? '1' : '3'}
        style={{
          paddingTop: statusBarHeight,
          paddingLeft: back ? 0 : !md && menu ? 0 : 12,
          paddingRight: 12,
          marginBottom: md ? 10 : 0,
          borderRadius: md ? 8 : 0,
          minHeight: HEADER_HEIGHT + statusBarHeight,
          marginHorizontal: md ? 10 : 0,
          justifyContent: 'center',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {back ? (
            <Pressable
              testID="goBackBtn"
              onPress={backPress}
              style={{ marginHorizontal: 10, padding: 8 }}
            >
              <Icon
                name={'chevron-left'}
                style={{ height: 24, paddingHorizontal: 0 }}
              />
            </Pressable>
          ) : null}

          {!md && menu ? (
            <Pressable
              testID="menuBtn"
              onPress={menuPress}
              style={{ marginHorizontal: 10, padding: 8 }}
            >
              <Icon
                name={'menu'}
                style={{ height: 24, paddingHorizontal: 0 }}
              />
            </Pressable>
          ) : null}

          {title ? (
            typeof title === 'string' ? (
              <Text style={{ flex: 1, fontWeight: '600' }} size={18}>
                {t(title)}
              </Text>
            ) : (
              title
            )
          ) : null}
          {rightComponent}
          {isPay && md ? (
            <Pressable
              style={{ flexDirection: 'row', alignItems: 'center' }}
              onPress={() => RootStore.setFullScreen(!RootStore.fullScreen)}
            >
              <Icon
                name={RootStore.fullScreen ? 'arrow-collapse' : 'arrow-expand'}
                color={colors.metal}
                size={18}
              />
              <Text color={primary} style={{ marginLeft: 5 }}>
                {t(RootStore.fullScreen ? 'collapse' : 'expand')}
              </Text>
            </Pressable>
          ) : null}
        </View>
        {children}
      </Layout>
    )
  },
)

export default forwardRef(
  (
    {
      title,
      children,
      back = false,
      menu = false,
      onMenuPress,
      onBackPress,
      rightComponent,
      scrollable = true,
      onRefresh,
      onLoadMore,
      backScreen,
      header,
    }: Props,
    ref: Ref<KeyboardAwareScrollView>,
  ) => {
    const { md } = useResponsive()
    const { isLight } = useTheme()

    const [refreshing, setRefreshing] = useState(false)

    const _onRefresh = React.useCallback(() => {
      setRefreshing(true)
      if (onRefresh) onRefresh()
      setRefreshing(false)
    }, [onRefresh])

    const refreshControl = useMemo(
      () =>
        onRefresh ? (
          <RefreshControl
            refreshing={refreshing}
            onRefresh={_onRefresh}
            progressViewOffset={HEIGHT}
            style={{ zIndex: 1000 }}
          />
        ) : undefined,
      [_onRefresh, onRefresh, refreshing],
    )

    const Container = scrollable ? KeyboardAwareScrollView : View

    return (
      <Layout
        level={isLight ? (md ? '3' : '1') : md ? '1' : '3'}
        style={{ flex: 1, paddingTop: md ? 10 : 0 }}
      >
        <Container
          ref={ref}
          style={{ flex: 1 }}
          refreshControl={refreshControl}
          onMomentumScrollEnd={(event) => {
            if (isCloseToBottom(event.nativeEvent)) {
              if (onLoadMore) {
                onLoadMore()
              }
            }
          }}
          stickyHeaderIndices={[0]}
          showsVerticalScrollIndicator={false}
        >
          {header ? (
            header
          ) : (
            <ScreenHeader
              backScreen={backScreen}
              menu={menu}
              back={back}
              title={title}
              onBackPress={onBackPress}
              onMenuPress={onMenuPress}
              rightComponent={rightComponent}
            />
          )}
          <Layout
            level={isLight ? '1' : '3'}
            style={{
              borderRadius: md ? 8 : 0,
              padding: 10,
              marginHorizontal: md ? 10 : 0,
              flex: 1,
            }}
          >
            {children}
          </Layout>
        </Container>
      </Layout>
    )
  },
)
