import requester from '../api/requester'

const requestPost = (url, data, silent) => {
  return requester.post(url, data, silent, 'fin')
}

const finApi = {
  getRecon: function (data = {}, silent = false) {
    return requestPost('accounting.get_provider_recon/', data, silent)
  },
  getServices: function (data = {}, silent = false) {
    return requestPost('accounting.get_services_by_pid/', data, silent)
  },
  getReportPayments(param, silent = false) {
    return requestPost('oneci.export_xls/', param, silent)
  },
  getProviderOperations: function (data = {}, silent = false) {
    return requestPost('accounting.provider_payments/', data, silent)
  },
  getProviderInfo: function (data = {}, silent = false) {
    return requestPost('accounting.get_provider/', data, silent)
  },
  saveProviderInfo: function (data = {}, silent = false) {
    return requestPost('accounting.save_provider/', data, silent)
  },
  getJobCheck: function (data = {}, silent = false) {
    return requestPost('job.check/', data, silent)
  },
  getTerminalKpd: function (data = {}, silent = false) {
    return requestPost('accounting.dealer_by_term/', data, silent)
  },
  getReconStatements: function (data = {}, silent = false) {
    return requestPost('accounting.get_dealer_recon/', data, silent)
  },
  getAgentReconStatements: function (data = {}, silent = false) {
    return requestPost('accounting.bank_recon/', data, silent)
  },
}

export default finApi
