import React, { ComponentType, useMemo } from 'react'
import type {
  ColorValue,
  DimensionValue,
  ImageProps,
  ImageStyle,
  StyleProp,
  TextStyle,
} from 'react-native'
import { StyleSheet } from 'react-native'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import './load-fonts'

type Icon = ComponentType<{
  name: string
  size?: DimensionValue
  color?: ColorValue
  style?: TextStyle
}>

type IconStyleProp = StyleProp<TextStyle> | StyleProp<ImageStyle>

function IconView({
  IconComponent,
  name,
  style,
}: Omit<ImageProps, 'style'> & {
  IconComponent: Icon
  name: string
  style?: IconStyleProp
}) {
  const _style: TextStyle | ImageStyle = useMemo(
    () => StyleSheet.flatten([style]),
    [style],
  )
  if ('tintColor' in _style) {
    const { width, height, tintColor: color, ...icon_style } = _style
    const size = max(safeNumber(height), safeNumber(width))
    return (
      <IconComponent
        name={name}
        size={size}
        color={color}
        style={{ ...icon_style, lineHeight: size }}
      />
    )
  } else if ('fontSize' in _style) {
    const { fontSize, color, ...icon_style } = _style
    const size = safeNumber(fontSize)
    return (
      <IconComponent
        name={name}
        size={size}
        color={color}
        style={{ ...icon_style, lineHeight: size }}
      />
    )
  }

  return <IconComponent name={name} style={_style} />
}

// noinspection JSUnusedGlobalSymbols
const makeIconPack = (name: string, IconComponent: Icon) => ({
  name,
  icons: new Proxy(
    {},
    {
      get: (_, icon: string) => ({
        toReactElement: (props: Partial<ImageProps>) => (
          <IconView {...props} IconComponent={IconComponent} name={icon} />
        ),
      }),
    },
  ),
})

const MaterialCommunityIconsPack = makeIconPack('mci', MaterialCommunityIcons)

export const icons = [MaterialCommunityIconsPack]

const isPositiveNumber = (value: unknown): value is number => {
  return typeof value === 'number' && value > 0
}

const safeNumber = (value: unknown): number => {
  return isPositiveNumber(value) ? value : 0
}

const max = (value1: number, value2: number) =>
  value1 > value2 ? value1 : value2
