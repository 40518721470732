import {
  formatIncompletePhoneNumber,
  getExampleNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js'
import React from 'react'
import PhoneInput from './PhoneInput'
import examples from 'libphonenumber-js/mobile/examples'

export default class PhoneEdit extends React.Component {
  state = {}
  placeholder = getExampleNumber('KG', examples).format('INTERNATIONAL')

  render() {
    const { model, name, value, onChangeText, ...props } = this.props
    let viewValue = value
    if (model && model[name]) viewValue = model[name]

    return (
      <PhoneInput
        value={viewValue ? formatIncompletePhoneNumber(viewValue, 'KG') : ''}
        onChangeText={this.checkPhone}
        {...props}
      />
    )
  }

  checkPhone = (text) => {
    const { model, name, phoneValue, onChangeText } = this.props
    if (model) {
      model.setValue(name, text)
    }
    if (phoneValue) {
      const data = parsePhoneNumberFromString(text, 'KG')
      if (data && data.isValid()) {
        console.log(data.isValid(), data.country, data.number)
        phoneValue(data)
      } else {
        phoneValue(null)
      }
    }
    if (onChangeText) {
      onChangeText(text)
    }
  }
}
