import { observer } from 'mobx-react'
import React from 'react'
import { View } from 'react-native'
import { translate, colors, store } from '@pay24/common'
import { Button } from './Button'
import Modal from './Modal'
import { Text } from './Text'
import { InputModel } from './Input'

const { useTranslation } = translate
const { AdminStore } = store

export default observer(() => {
  const { t } = useTranslation()
  if (!AdminStore.isAdminOTPRequired) return null
  return (
    <Modal
      title={t('enter_sms_code')}
      visible={AdminStore.adminOTPRequired}
      onClose={(): void => {
        AdminStore.clear()
      }}
      closeColor={colors.white}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          padding: 20,
        }}
      >
        <View style={{ marginVertical: 10 }}>
          <InputModel
            model={AdminStore}
            name={'verificationCode'}
            label={t('sms_code')}
          />
        </View>
        <View style={{ marginVertical: 5 }}>
          <Text
            style={{
              fontSize: 12,
              color: AdminStore.verified ? colors.green : colors.red,
            }}
          >
            {AdminStore.verificationMessage}
          </Text>
        </View>
        <View>
          <Button
            onPress={() => {
              AdminStore.verify()
            }}
            disabled={!AdminStore.verificationCode}
          >
            {t('verify')}
          </Button>
        </View>
      </View>
    </Modal>
  )
})
