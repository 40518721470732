import React, { ReactNode } from 'react'
import { constants, hooks, utils } from '@pay24/common'
import { Layout } from '@ui-kitten/components'
import { Platform, Pressable, StyleSheet, View, ViewStyle } from 'react-native'

const { safeAreaUtils } = utils
const { useTheme } = hooks

const bottomSpace = safeAreaUtils.getBottomSpace()

interface MobileMenuProps {
  children: ReactNode
  onClose: () => void
  style?: ViewStyle
}

export const MobileMenu = (props: MobileMenuProps) => {
  const { children, onClose, style } = props
  const { isLight } = useTheme()
  return (
    <View
      style={[
        {
          ...StyleSheet.absoluteFillObject,
          justifyContent: 'flex-end',
          marginBottom: constants.TAB_HEIGHT + bottomSpace,
          ...Platform.select({
            web: { userSelect: 'none' },
          }),
          paddingBottom: 7,
        },
        style,
      ]}
    >
      <Pressable
        onPress={onClose}
        style={{
          ...StyleSheet.absoluteFillObject,
          justifyContent: 'flex-end',
          backgroundColor: 'black',
          opacity: 0.5,
          ...Platform.select({
            web: { userSelect: 'none' },
          }),
        }}
      />
      <Layout
        style={{
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          padding: 0,
        }}
        level={isLight ? '1' : '3'}
      >
        {children}
      </Layout>
    </View>
  )
}
