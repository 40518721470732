import { colors, hooks } from '@pay24/common'
import React from 'react'
import { View, ViewStyle } from 'react-native'
import Image from '../Image'
import { LangSwitcher } from '../LangSwitcher'
import { Text } from '../Text'
import { ThemeSwitcher } from '../ThemeSwitcher'
import { DeviceInfo, isEvion, isPay } from '@pay24/common/src/utils'
import Spacer from '../Spacer'

const { useTheme } = hooks

interface SideBarHeaderProps {
  logo: string
  logoWidth?: number
  logoHeight?: number
  style?: ViewStyle
  onThemePress?: () => void
}

const version = DeviceInfo.getVersion()

export const SideBarHeader = (props: SideBarHeaderProps) => {
  const {
    logo,
    logoWidth: width = 150,
    logoHeight: height = isEvion ? 23 : 20,
    style,
    onThemePress,
  } = props
  const { isLight } = useTheme()

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          padding: 20,
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottomWidth: 1,
          borderBottomColor: isLight
            ? 'rgba(0, 0, 0, 0.05)'
            : 'rgba(255, 255, 255, 0.05)',
        },
        style,
      ]}
    >
      <Image source={logo} style={{ width, height }} />
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {!isPay && version ? (
          <Text size={12} color={colors.metal} style={{ marginRight: 20 }}>
            v.{version}
          </Text>
        ) : null}
        <ThemeSwitcher onPress={onThemePress} />
        <Spacer width={10} />
        <LangSwitcher />
      </View>
    </View>
  )
}
