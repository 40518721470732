import { acquiring as acquiringApi } from '@pay24/common/src/api'
import React, { useState } from 'react'
import { Button } from '../Button'
import { Input } from '../Input'
import PhoneEdit from '../PhoneEdit'
import Spacer from '../Spacer'

export function Balance({ onSave }) {
  let [value, setValue] = useState('')
  let [phone, setPhone] = useState<any>(null)
  let [otp, setOtp] = useState('')
  let [sent, setSent] = useState(false)

  const onSavePress = () => {
    acquiringApi
      .authEnd('balance', { phone: phone.format('E.164'), otp })
      .then((e) => {
        if (onSave) {
          onSave({
            requisite: phone.format('E.164'),
            service_code: 'balance',
            secure: btoa(JSON.stringify(e.token)),
          })
        }
      })
  }

  const sendCode = () => {
    acquiringApi
      .authStart('balance', { phone: phone.format('E.164') })
      .then(() => setSent(true))
      .catch(() => {
        setSent(false)
      })
  }

  return (
    <>
      <PhoneEdit
        label={'Номер'}
        value={value}
        phoneValue={setPhone}
        onChangeText={setValue}
        disabled={sent}
      />
      <Spacer />
      {sent && (
        <>
          <Input
            label={'Pin-код из SMS'}
            value={otp}
            focus={sent}
            onChangeText={setOtp}
          />
          <Spacer />
        </>
      )}
      {sent ? (
        <>
          <Button onPress={onSavePress}> Сохранить </Button>
          <Spacer />
          <Button ghost onPress={sendCode}>
            {' '}
            Отправить еще раз{' '}
          </Button>
        </>
      ) : (
        <Button onPress={sendCode} disabled={!phone}>
          {' '}
          Получить пин-код{' '}
        </Button>
      )}
    </>
  )
}
