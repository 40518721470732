import { Layout, Modal } from '@ui-kitten/components'
import React, { forwardRef, Ref, useCallback, useEffect } from 'react'
import {
  BackHandler,
  Dimensions,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { colors, utils, translate } from '@pay24/common'
import Icon from './Icon'
import Spacer from './Spacer'
import { Text } from './Text'
import ToolbarButton from './ToolbarButton'
import { BusyProgress } from './BusyProgress'
import { isMobile } from '@pay24/common/src/utils'

const { composeStyle, isAndroid } = utils
const { useTranslation } = translate

const drawerWidth = 380

const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
  const paddingToBottom = 40
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  )
}

type IProps = {
  visible?: boolean
  onClose?: () => void
  title?: string
  children?: React.ReactNode
  style?: ViewStyle
  onBackPress?: () => void
  onLoadMore?: () => void
  onClearPress?: () => void
  onRefresh?: () => void
  testID?: string
}

const dimensions = Dimensions.get('window')

export default forwardRef(function FilterDrawer(
  {
    onClose,
    visible,
    title,
    children,
    style,
    onBackPress,
    onLoadMore,
    onClearPress,
    onRefresh,
    testID,
  }: IProps,
  ref: Ref<ScrollView>,
) {
  const { width: screenWidth, height: screenHeight } = dimensions
  const { t } = useTranslation()
  const marginLeft = screenWidth - drawerWidth

  const backAction = useCallback(() => {
    if (visible) {
      onClose && onClose()
      return !!onClose
    } else {
      return false
    }
  }, [visible, onClose])

  useEffect(() => {
    let backHandler
    if (isMobile) {
      backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      )
    }
    return () => backHandler && backHandler.remove()
  }, [backAction, visible])

  return (
    <Modal
      testID={testID}
      visible={visible}
      backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
      style={composeStyle(
        {
          height: screenHeight,
          width: drawerWidth,
          margin: 0,
          padding: 0,
          left: marginLeft,
        },
        screenWidth <= drawerWidth + 30 && { left: 0, width: '100%' },
      )}
      onBackdropPress={onClose}
    >
      {isAndroid ? <BusyProgress /> : null}
      <Layout level="1" style={composeStyle({ flex: 1 }, style)}>
        <SafeAreaView style={{ height: screenHeight }}>
          <Spacer />
          {title ? (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginHorizontal: 12,
                marginVertical: 4,
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {onBackPress ? (
                  <Icon
                    name="chevron-left"
                    onPress={onBackPress}
                    style={{ marginRight: 10 }}
                  />
                ) : null}
                <Text size={16}>{t(title)}</Text>
              </View>
              {onClearPress ? (
                <TouchableOpacity onPress={onClearPress}>
                  <Text size={16} color={colors.primary}>
                    {t('clear')}
                  </Text>
                </TouchableOpacity>
              ) : null}
              {onRefresh ? (
                <TouchableOpacity onPress={onRefresh}>
                  <Icon name={'refresh'} />
                </TouchableOpacity>
              ) : null}
              <ToolbarButton onPress={onClose} icon={'close'} />
            </View>
          ) : (
            <ToolbarButton
              style={{ alignSelf: 'flex-end' }}
              onPress={onClose}
              icon={'close'}
            />
          )}
          <Spacer />
          <ScrollView
            ref={ref}
            style={{ paddingHorizontal: 12 }}
            onScroll={({ nativeEvent }) => {
              if (isCloseToBottom(nativeEvent)) {
                if (onLoadMore) {
                  onLoadMore()
                }
              }
            }}
            scrollEventThrottle={16}
          >
            {children}
          </ScrollView>
        </SafeAreaView>
      </Layout>
    </Modal>
  )
})
