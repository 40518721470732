import { useCallback, useMemo } from 'react'

export function useSelectLocation(model, source: 'shop' | 'agent' | '' = '') {
  const country_key = useMemo(
    () =>
      source === 'agent'
        ? 'agent_country'
        : source === 'shop'
        ? 'shop_country'
        : 'country',
    [source],
  )
  const region_key = useMemo(
    () =>
      source === 'agent'
        ? 'agent_region'
        : source === 'shop'
        ? 'shop_region'
        : 'region',
    [source],
  )
  const district_key = useMemo(
    () =>
      source === 'agent'
        ? 'agent_district'
        : source === 'shop'
        ? 'shop_district'
        : 'district',
    [source],
  )

  const modelCountry = model[country_key]
  const modelRegion = model[region_key]
  const modelDistrict = model[district_key]

  const selectCountry = useCallback(
    (country) => model.setValue(country_key, country),
    [country_key, model],
  )
  const selectRegion = useCallback(
    (region) => model.setValue(region_key, region),
    [region_key, model],
  )
  const selectDistrict = useCallback(
    (district) => model.setValue(district_key, district),
    [district_key, model],
  )

  const location = useMemo(
    () => modelDistrict || modelRegion || modelCountry,
    [modelCountry, modelDistrict, modelRegion],
  )

  const isNotCity = useMemo(() => {
    if (!modelDistrict) return false
    if (typeof modelDistrict === 'object') {
      return !modelDistrict.name?.includes('г')
    } else if (typeof modelDistrict === 'string') {
      return !modelDistrict?.includes('г')
    } else {
      return false
    }
  }, [modelDistrict?.id])

  return {
    selectCountry,
    selectRegion,
    selectDistrict,
    location,
    isNotCity,
  }
}
