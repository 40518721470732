import { composeStyle } from '@pay24/common/src/utils'
import React, { useCallback, useEffect, useMemo } from 'react'
import {
  Autocomplete,
  AutocompleteItem,
  AutocompleteProps,
} from '@ui-kitten/components'
import { Platform, StyleProp, TextStyle, TouchableOpacity } from 'react-native'
import Icon from './Icon'
import { Text } from './Text'
import capitalize from 'lodash/capitalize'
import { useTheme } from '@pay24/common/src/hooks'
import { useTranslation } from '@pay24/common/src/translate'
import { colors } from '@pay24/common'

interface IAutocompleteProps
  extends Omit<AutocompleteProps, 'onChange' | 'label'> {
  model?: any
  name?: string
  options?: string[]
  errorText?: string
  keyName?: string
  valueName?: string
  inputStyle?: StyleProp<TextStyle>
  onChange?: ((item: any) => void) | null
  onClearPress?: ((item: any) => void) | null
  capital?: boolean
  value?: any
  multiline?: boolean
  sort?: boolean
  sorted?: boolean
  rightValue?: string | number | null
  label?: string
  placeholder?: string
}

export default React.memo(
  ({
    model,
    name,
    options = [],
    value,
    inputStyle,
    errorText = '',
    caption = '',
    capital = false,
    multiline = false,
    label = '',
    onChange,
    sorted = false,
    placeholder,
    ...rest
  }: IAutocompleteProps) => {
    const [searchValue, setSearchValue] = React.useState('')
    const [data, setData] = React.useState<any>([])
    const theme = useTheme()
    const { t } = useTranslation()

    useEffect(() => {
      if (model && name && model[name] !== undefined) {
        setSearchValue(model[name])
      } else if (value) {
        setSearchValue(value)
      } else {
        setSearchValue('')
      }
    }, [value])

    useEffect(() => {
      if (searchValue) {
        setData(
          data.filter((item: any) =>
            item.toLowerCase().includes(searchValue.toLowerCase()),
          ),
        )
      } else {
        setData(options)
      }
    }, [searchValue])

    const bottomTextStyle = {
      marginTop: 3,
      marginLeft: 3,
      color: colors.metal,
      fontSize: 10,
    }

    const _inputStyle = composeStyle(
      {
        flex: 1,
        padding: 0,
        color: theme['text-basic-color'],
      },
      inputStyle,
      Platform.select({ web: { outlineColor: 'transparent' } }),
      multiline && { marginVertical: 8, height: 90 },
    )

    const bottomText = errorText ? (
      <Text style={[bottomTextStyle, { color: colors.red }]}>{errorText}</Text>
    ) : (
      !!caption && <Text style={bottomTextStyle}>{caption}</Text>
    )

    const clearButton = useCallback((props) => {
      return (
        <TouchableOpacity
          onPress={() => {
            onChangeText('')
          }}
          style={[
            props.style,
            {
              justifyContent: 'center',
              alignItems: 'center',
              marginHorizontal: 0,
            },
          ]}
        >
          <Icon name="close" color={colors.metal} size={18} />
        </TouchableOpacity>
      )
    }, [])

    const onChangeText = (query) => {
      if (model && name) {
        if (model.setValue) {
          model.setValue(name, query)
        } else {
          model[name] = query
        }
      }
      if (onChange) {
        onChange(query)
      }
      setSearchValue(query)
    }

    const renderItems = useMemo(() => {
      const items = sorted
        ? options.sort((a, b) => a.localeCompare(b))
        : options
      return items.map((o, i) => (
        <AutocompleteItem
          key={i}
          title={<Text>{t(capital ? capitalize(o) : o)}</Text>}
        />
      ))
    }, [capital, options, sorted])

    return (
      <>
        <Autocomplete
          status={'basic'}
          autoCorrect={false}
          textStyle={_inputStyle}
          accessoryRight={searchValue ? clearButton : undefined}
          value={capital ? capitalize(searchValue) : searchValue}
          placeholder={t(placeholder || 'Выберите')}
          onSelect={(index) => {
            onChangeText(data[index])
          }}
          label={t(label || '')}
          onChangeText={onChangeText}
          {...rest}
        >
          {renderItems}
        </Autocomplete>
        {bottomText}
      </>
    )
  },
)
