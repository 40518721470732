import React, { useEffect, useRef, useState } from 'react'
import {
  KeyboardTypeOptions,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputProps,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native'
import { TextInputMask, TextInputMaskTypeProp } from 'react-native-masked-text'
import { Text, Description } from './Text'
import Icon from './Icon'

import { utils, colors, hooks, translate } from '@pay24/common'
import { autorun } from 'mobx'

const { useTranslation } = translate
const { composeStyle } = utils
const { useTheme } = hooks

export interface ICustomInput extends Omit<TextInputProps, 'value'> {
  eva?: any
  containerStyle?: ViewStyle
  inputStyle?: StyleProp<TextStyle>
  leftComponent?: React.ReactElement
  rightComponent?: React.ReactElement
  onPress?: () => void
  label?: string
  labelStyle?: TextStyle
  caption?: string
  errorText?: string
  inputComponent?: (...any) => void
  clearable?: boolean
  value?: string | null
  onChangeText?: (any) => void
  inputMask?: string
  focus?: boolean
  currency?: boolean
  disabled?: boolean
  maskType?: TextInputMaskTypeProp
  multiline?: boolean
  testID?: string
  keyboardType?: KeyboardTypeOptions
  placeholder?: string
  placeholderTextColor?: string
  maxLength?: number
  secureTextEntry?: boolean
  overlayPress?: boolean
  inputRequired?: boolean
  accessoryRight?: any
}

export const Input = ({
  eva,
  containerStyle,
  inputStyle,
  leftComponent,
  rightComponent,
  onPress,
  label,
  labelStyle,
  caption = '',
  errorText = '',
  inputComponent,
  clearable = true,
  value,
  onChangeText,
  inputMask = '',
  focus = false,
  currency,
  maskType = 'custom',
  multiline = false,
  disabled = false,
  testID,
  keyboardType,
  placeholder = '',
  placeholderTextColor = '#9DA5AF',
  maxLength,
  secureTextEntry,
  overlayPress = false,
  inputRequired,
  accessoryRight,
  ...restProps
}: ICustomInput) => {
  const [focused, setFocused] = useState(false)
  const textInput = useRef<TextInput>(null)
  const { t } = useTranslation()
  const theme = useTheme()
  useEffect(() => {
    if (focus) {
      textInput?.current?.focus()
    }
  }, [focus])

  const Container = onPress ? Pressable : View

  const bottomTextStyle = {
    marginTop: 3,
    marginLeft: 3,
    color: colors.metal,
    fontSize: 12,
  }

  const bottomText =
    errorText && !!errorText.trim() ? (
      <Text style={[bottomTextStyle, { color: colors.red }]}>
        {t(errorText)}
      </Text>
    ) : (
      !!caption && <Text style={bottomTextStyle}>{caption}</Text>
    )

  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  const _inputStyle = composeStyle(
    {
      flex: 1,
      fontSize: 16,
      minHeight: 22,
      paddingVertical: 0,
      color: theme['text-basic-color'],
    },
    inputStyle,
    Platform.select({ web: { outlineColor: 'transparent', outline: 'none' } }),
    multiline && { marginVertical: 8, height: 90 },
  )

  const renderInput = () => {
    if (inputComponent)
      return inputComponent({
        onFocus,
        onBlur,
        onChangeText,
        value,
        style: _inputStyle,
        testID: testID,
        ...restProps,
      })

    if (inputMask || maskType !== 'custom') {
      return (
        <TextInputMask
          autoCorrect={false}
          autoCapitalize={'none'}
          style={_inputStyle}
          onFocus={onFocus}
          onBlur={onBlur}
          type={maskType}
          readOnly={disabled}
          options={{ mask: inputMask }}
          value={!value ? '' : String(value)}
          testID={testID}
          placeholder={t(placeholder)}
          placeholderTextColor={placeholderTextColor}
          keyboardType={keyboardType}
          maxLength={maxLength}
          onChangeText={(text) => {
            if (onChangeText) {
              onChangeText(text)
            }
          }}
        />
      )
    }

    return (
      <TextInput
        autoCorrect={false}
        multiline={multiline}
        autoCapitalize={'none'}
        style={_inputStyle}
        onFocus={onFocus}
        ref={textInput}
        readOnly={disabled}
        onBlur={onBlur}
        value={!value ? '' : String(value)}
        testID={testID}
        placeholder={t(placeholder)}
        placeholderTextColor={placeholderTextColor}
        keyboardType={keyboardType}
        secureTextEntry={secureTextEntry}
        onChangeText={(text) => {
          if (onChangeText) {
            onChangeText(text)
          }
        }}
        {...restProps}
      />
    )
  }

  return (
    <>
      {!!label && (
        <Text
          style={[
            {
              marginBottom: 2,
              color: colors.metal,
              fontSize: 12,
              fontWeight: '600',
            },
            labelStyle,
          ]}
        >
          {t(label) + (inputRequired ? '*' : '')}
        </Text>
      )}
      <Container onPress={onPress} disabled={disabled}>
        <View
          style={composeStyle(
            {
              backgroundColor: theme['background-basic-color-2'],
              borderColor: theme['border-basic-color-4'],
            },
            focused && {
              borderColor: theme['color-primary-500'],
              backgroundColor: theme['background-basic-color-1'],
            },
            disabled && {
              backgroundColor: theme['background-basic-color-2'],
            },
            !!errorText && { borderColor: colors.red },
            {
              flexDirection: 'row',
              alignItems: 'center',
              minHeight: 44,
              paddingVertical: 8,
              borderWidth: 2,
              borderRadius: 8,
              paddingHorizontal: 10,
            },
            containerStyle,
          )}
        >
          {leftComponent}
          <View style={{ flex: 1 }}>
            {renderInput() as JSX.Element}
            {overlayPress && (
              <Pressable style={StyleSheet.absoluteFill} onPress={onPress} />
            )}
          </View>
          {!disabled && !!value && clearable && (
            <Icon
              testID={'clear_' + testID}
              name="close"
              color={colors.metal}
              size={20}
              onPress={() => {
                if (onChangeText) {
                  onChangeText(keyboardType === 'numeric' ? 0 : '')
                }
              }}
            />
          )}
          {rightComponent}
          {accessoryRight ? <>{accessoryRight}</> : null}

          {currency && <Description>KGS</Description>}
        </View>
      </Container>
      {bottomText}
    </>
  )
}

interface IInputModel extends ICustomInput {
  model?: any
  name: string
  size?: string
  disabled?: boolean
  testID?: string
  inputRequired?: boolean
}

export const InputModel = ({
  model,
  name,
  value,
  label = '',
  caption = '',
  rightComponent,
  errorText = '',
  leftComponent,
  placeholder = '',
  keyboardType = 'default',
  onChangeText,
  disabled = false,
  testID = '',
  inputRequired = false,
  ...rest
}: IInputModel) => {
  const [text, setText] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    autorun(() => {
      if (model && name && model[name] !== undefined) {
        setText(model[name])
      } else if (value) {
        setText(value)
      } else {
        setText('')
      }
    })
  }, [value])

  const onChange = (txt) => {
    if (keyboardType === 'phone-pad')
      txt = (txt || '').replace(/[^\d-+_*#,.\\/|]/g, '')

    if (keyboardType === 'numeric') {
      txt = txt ? txt.trim() : ''
      if (!isNaN(txt)) {
        txt = txt.length > 0 ? parseFloat(txt.replace(/[^0-9]/g, '')) : null
      } else {
        return
      }
    }
    if (model && name) {
      if (model.setValue) {
        model.setValue(name, txt)
      } else {
        model[name] = txt
      }
    }

    if (onChangeText) {
      onChangeText(txt)
    }
    setText(txt)
  }

  return (
    <Input
      // disabled={disabled || rootStore.isBusy}
      disabled={disabled}
      placeholder={placeholder}
      placeholderTextColor={colors.metal}
      caption={caption}
      errorText={errorText}
      label={t(label)}
      value={text === null || text === undefined ? '' : String(text)}
      rightComponent={rightComponent}
      leftComponent={leftComponent}
      keyboardType={keyboardType}
      {...rest}
      onChangeText={onChange}
      testID={testID}
      inputRequired={inputRequired}
    />
  )
}
