import { View, ViewStyle } from 'react-native'
import { Spinner } from '@ui-kitten/components'
import React from 'react'
import { EvaSize } from '@ui-kitten/components/devsupport'

export const Loading = ({
  isLoading = true,
  style,
  size = 'large',
}: {
  isLoading?: boolean
  style?: ViewStyle
  size?: EvaSize
}) =>
  isLoading ? (
    <View
      style={[
        { flex: 1, alignItems: 'center', justifyContent: 'center' },
        style,
      ]}
    >
      <Spinner size={size} />
    </View>
  ) : null
