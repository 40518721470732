import React, { useCallback, useEffect, useMemo } from 'react'
import {
  Autocomplete,
  AutocompleteItem,
  AutocompleteProps,
} from '@ui-kitten/components'
import {
  Platform,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native'
import Icon from './Icon'
import { Text } from './Text'
import capitalize from 'lodash/capitalize'
import { translate, hooks, colors, utils } from '@pay24/common'

const { useTranslation } = translate
const { useTheme } = hooks
const { composeStyle } = utils

interface IAutocompleteProps
  extends Omit<AutocompleteProps, 'onChange' | 'label'> {
  options?: any[]
  errorText?: string
  keyName?: string
  label?: string
  valueName?: string
  inputStyle?: StyleProp<TextStyle>
  onChange?: ((item: any) => void) | null
  onClearPress?: ((item: any) => void) | null
  capital?: boolean
  value?: any
  multiline?: boolean
  sort?: boolean
  sorted?: boolean
  rightValue?: string | number | null
  testID?: string
}

export default React.memo(
  ({
    options = [],
    value,
    inputStyle,
    errorText = '',
    caption = '',
    capital = false,
    multiline = false,
    valueName = 'name',
    label,
    onChange,
    sorted = false,
    testID,
    ...rest
  }: IAutocompleteProps) => {
    const [searchValue, setSearchValue] = React.useState('')
    const [data, setData] = React.useState<any>([])
    const theme = useTheme()
    const { t } = useTranslation()

    useEffect(() => {
      setData(
        sorted
          ? options.sort((a: any, b: any) =>
              a[valueName].localeCompare(b[valueName]),
            )
          : options,
      )
    }, [options])

    useEffect(() => {
      if (value === null || value === undefined) {
        setSearchValue('')
      } else if (typeof value === 'number') {
        setSearchValue(
          options.find((o) => o.id === value) &&
            options.find((o) => o.id === value)[valueName],
        )
      } else {
        setSearchValue(value)
      }
    }, [value])

    useEffect(() => {
      if (searchValue) {
        setData(
          data.filter((item: any) =>
            item[valueName].toLowerCase().includes(searchValue.toLowerCase()),
          ),
        )
      } else {
        setData(options)
      }
    }, [searchValue])

    const bottomTextStyle = {
      marginTop: 3,
      marginLeft: 3,
      color: colors.metal,
      fontSize: 10,
    }

    const _inputStyle = composeStyle(
      {
        flex: 1,
        padding: 0,
        color: theme['text-basic-color'],
      },
      inputStyle,
      Platform.select({ web: { outlineColor: 'transparent' } }),
      multiline && { marginVertical: 8, height: 90 },
    )

    const bottomText = errorText ? (
      <Text style={[bottomTextStyle, { color: colors.red }]}>{errorText}</Text>
    ) : (
      !!caption && <Text style={bottomTextStyle}>{caption}</Text>
    )

    const clearButton = useCallback((props) => {
      return (
        <TouchableOpacity
          testID={'removeCitizenship'}
          onPress={() => {
            setSearchValue('')
            if (onChange) {
              onChange(null)
            }
          }}
          style={[
            props.style,
            {
              justifyContent: 'center',
              alignItems: 'center',
              marginHorizontal: 0,
            },
          ]}
        >
          <Icon name="close" color={colors.metal} size={18} />
        </TouchableOpacity>
      )
    }, [])

    const downArrow = () => (
      <Icon name="chevron-down" color={colors.metal} size={22} />
    )

    const onChangeText = (query) => {
      setSearchValue(query)
      if (
        data.length > 0 &&
        query.toLowerCase() === data[0][valueName].toLowerCase()
      ) {
        onChange && onChange(data[0])
      }
    }

    const renderItems = useMemo(() => {
      return data.map((o, i) => (
        <AutocompleteItem
          testID={'citizenship_country_' + o.id}
          key={i}
          title={
            <Text>{t(capital ? capitalize(o[valueName]) : o[valueName])}</Text>
          }
        />
      ))
    }, [data])

    return (
      <View testID={testID}>
        <Autocomplete
          status={'basic'}
          autoCorrect={false}
          textStyle={_inputStyle}
          accessoryRight={searchValue ? clearButton : downArrow}
          value={t(capital ? capitalize(searchValue) : searchValue)}
          placeholder={rest.placeholder || t('select')}
          onSelect={(index) => {
            setSearchValue(data[index][valueName])
            if (onChange) {
              onChange(data[index])
            }
          }}
          label={t(label || '')}
          onChangeText={onChangeText}
          {...rest}
        >
          {renderItems}
        </Autocomplete>
        {bottomText}
      </View>
    )
  },
)
