import i18n from 'i18next'
import { observer } from 'mobx-react'
import React, { ComponentProps } from 'react'
import { _constants, store, translate, colors, hooks } from '@pay24/common'
import { ItemContainer } from '../Components'
import { Text } from '../Text'
import { Input } from '../Input'
import Spacer from '../Spacer'
import { Button } from '../Button'
import { Alert } from '../alert'

const { RootStore, SecurityStore } = store
const { MESSAGE_TYPE } = _constants
const { useTranslation } = translate
const { useTheme } = hooks
const { STEP_REQUEST_OTP, STEP_SEND_DATA, STEP_INPUT_DATA } = SecurityStore

export default observer(
  ({ storage = undefined, testMode = false }: ComponentProps<any>) => {
    const { user } = RootStore
    let securityStore = SecurityStore.SecurityStore
    if (storage) {
      securityStore = storage
    }
    if (!testMode) {
      securityStore.setEmail(user?.data.profile?.email)
    }

    return (
      <>
        {securityStore.emailState === STEP_REQUEST_OTP ? (
          <StepRequestOtp securityStore={securityStore} />
        ) : securityStore.emailState === STEP_INPUT_DATA ? (
          <StepInputData securityStore={securityStore} />
        ) : securityStore.emailState === STEP_SEND_DATA ? (
          <StepSendData securityStore={securityStore} />
        ) : null}
      </>
    )
  },
)

const StepRequestOtp = observer(function StepRequestOtp({ securityStore }) {
  return (
    <>
      <ItemContainer style={{ padding: 8, marginBottom: 10 }}>
        <Text color={colors.metal}>
          Адрес электронной почты нужен для новостей и уведомлений
        </Text>
      </ItemContainer>
      {securityStore?.email ? (
        <Input
          disabled
          label="email"
          testID="email"
          onChangeText={(value) => securityStore.setEmail(value)}
          value={securityStore.email}
        />
      ) : (
        <Input
          label="email"
          testID="email"
          onChangeText={(value) => securityStore.setNewEmail(value)}
          value={securityStore.newEmail}
        />
      )}
      <Spacer />
      <Button
        testID="submit1"
        onPress={() =>
          securityStore?.email
            ? securityStore.emailChange1()
            : securityStore.emailChange2()
        }
      >
        {i18n.t('next')}
      </Button>
    </>
  )
})

const StepInputData = observer(function StepInputData({ securityStore }) {
  const { primary } = useTheme()
  const { t } = useTranslation()
  return (
    <>
      <ItemContainer style={{ padding: 8, marginBottom: 10 }}>
        <Text color={colors.metal}>
          {`На Вашу почту ${securityStore.email} отправлено сообщение с кодом подтверждения. `}
        </Text>
      </ItemContainer>
      <Input
        label="enter_code"
        testID="code_old"
        onChangeText={(value) => securityStore.setEmailOldCode(value)}
        value={securityStore.emailOldCode}
      />
      <Spacer />
      <Input
        label="Новый адрес email"
        testID="new_email"
        onChangeText={(value) => securityStore.setNewEmail(value)}
        value={securityStore.newEmail}
      />
      <Spacer />
      <Button
        testID="submit2"
        disabled={!securityStore.emailOldCode || !securityStore.newEmail}
        onPress={() => securityStore.emailChange2()}
      >
        {t('confirm')}
      </Button>
      <Spacer />
      <Text>Я не получил код, </Text>
      <Text onPress={securityStore.emailChange1} color={primary}>
        отправить код еще раз
      </Text>
    </>
  )
})

const StepSendData = observer(function StepSendData({ securityStore }) {
  const { t } = useTranslation()
  return (
    <>
      <ItemContainer style={{ padding: 8, marginBottom: 10 }}>
        <Text color={colors.metal}>
          {`На Вашу почту ${securityStore.newEmail} отправлено сообщение с кодом подтверждения. `}
        </Text>
      </ItemContainer>
      <Input
        label="enter_code"
        testID="new_code"
        onChangeText={(value) => securityStore.setEmailNewCode(value)}
        value={securityStore.emailNewCode}
      />
      <Spacer />
      <Spacer height={20} />
      <Button
        testID="submit3"
        disabled={!securityStore.emailNewCode}
        onPress={() => {
          securityStore.emailChange3().then((resp) => {
            if (resp.result === 0) {
              Alert.alert(
                '',
                t('Адрес электронной почты успешно изменен'),
                [{ text: 'OK', onPress: () => null }],
                { cancelable: true },
              )
            } else {
              RootStore.setMessage({
                type: MESSAGE_TYPE.WARNING,
                text: 'Неправильный код.',
              })
            }
          })
        }}
      >
        {i18n.t('confirm')}
      </Button>
    </>
  )
})
