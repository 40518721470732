import {
  Text as KittenText,
  TextProps as KittenTextProps,
} from '@ui-kitten/components'
import React from 'react'

import { colors, utils } from '@pay24/common'
import Spacer from './Spacer'
import type { TextElement } from '@ui-kitten/components/ui/text/text.component'

const { composeStyle, isWeb } = utils

declare type ChildElement = React.ReactText | TextElement

export declare type TextChildrenProp = ChildElement | ChildElement[]

export interface TextProps extends KittenTextProps {
  size?: number
  color?: string
  testID?: string
  'data-tag'?: string
  fontWeight?:
    | 'normal'
    | 'bold'
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
}

export const Text = ({
  children,
  size = 14,
  color,
  style,
  testID,
  nativeID,
  fontWeight,
  'data-tag': dataTag = '',
  ...rest
}: TextProps) => (
  <KittenText
    ellipsizeMode="tail"
    nativeID={nativeID || testID}
    testID={testID}
    style={composeStyle(
      { fontSize: size },
      fontWeight ? { fontWeight } : {},
      color ? { color } : {},
      style,
    )}
    {...rest}
    {...(isWeb && dataTag ? { 'data-tag': dataTag } : {})}
  >
    {children}
  </KittenText>
)

export const MainTitle = ({ children, testID, ...rest }: TextProps) => (
  <Text numberOfLines={1} size={20} {...rest} testID={testID}>
    {children}
  </Text>
)

export const SubTitle = ({
  children,
  numberOfLines = 1,
  ...rest
}: TextProps) => {
  return (
    <>
      <Text
        color={colors.metal}
        numberOfLines={numberOfLines || 1}
        size={16}
        {...rest}
      >
        {children}
      </Text>
      <Spacer height={4} />
    </>
  )
}

export const Title = ({ children, ...rest }: TextProps) => (
  <Text size={16} {...rest}>
    {children}
  </Text>
)

export const Description = ({
  size = 14,
  numberOfLines = 10,
  children,
  color = colors.metal,
  ...rest
}: TextProps) => (
  <Text numberOfLines={numberOfLines} size={size} color={color} {...rest}>
    {children}
  </Text>
)
