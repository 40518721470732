import { acquiring as acquiringApi } from '@pay24/common/src/api'
import { MESSAGE_TYPE } from '@pay24/common/src/constants'
import { RootStore } from '@pay24/common/src/store'
import PhoneEdit from '../PhoneEdit'
import React, { useState } from 'react'
import { Button } from '../Button'
import Spacer from '../Spacer'

export function ODengi({ navigation }) {
  let [phone, setPhone] = useState<any>(null)
  let [value, setValue] = useState('')

  const onSavePress = async () => {
    if (!phone) {
      RootStore.setMessage({
        type: MESSAGE_TYPE.ERROR,
        text: 'Не правильный формат',
      })
      return
    }
    await acquiringApi.authEnd('odengi', { phone: phone.format('E.164') })
    navigation.navigate('debit')
  }

  return (
    <>
      <PhoneEdit
        label={'Номер'}
        value={value}
        phoneValue={setPhone}
        onChangeText={setValue}
      />
      <Spacer />
      <Button disabled={!value} onPress={onSavePress}>
        Сохранить
      </Button>
    </>
  )
}
