import { colors, translate } from '@pay24/common'
import React from 'react'
import Icon from './Icon'
import { type ICustomInput, Input } from './Input'

const { useTranslation } = translate

export default ({ placeholder, ...rest }: ICustomInput) => {
  const { t } = useTranslation()

  return (
    <Input
      leftComponent={
        <Icon
          name="magnify"
          color={colors.metal}
          size={24}
          style={{ marginRight: 5 }}
        />
      }
      placeholder={t(placeholder || 'Поиск...')}
      {...rest}
    />
  )
}
