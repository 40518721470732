import { colors, permissions } from '@pay24/common'
import { useTranslation } from '@pay24/common/src/translate'
import { screenHeight, screenWidth } from '@pay24/common/src/utils'
import React, { useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import Contacts from 'react-native-contacts'
import FlatList from './FlatList'
import Icon from './Icon'
import Modal from './Modal'
import SearchBar from './SearchBar'
import { Text, Title } from './Text'

const RenderItems = ({ onChange, onClose, contact, value }) => {
  if (!contact) return null
  let contacts
  if (value && value.length > 1) {
    contacts = contact.filter(
      (c) =>
        (c.givenName &&
          c.givenName
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase())) ||
        (c.familyName &&
          c.familyName.toLocaleLowerCase().includes(value.toLocaleLowerCase())),
    )
  } else {
    contacts = contact
  }

  return (
    <FlatList
      data={contacts}
      renderItem={({ item, index }) => (
        <View key={index} style={{ paddingHorizontal: 10 }}>
          <TouchableOpacity
            style={{ flexDirection: 'row', alignItems: 'center' }}
            onPress={() => {
              const phone = reformat(item.phoneNumbers[0].number)
              if (onChange) {
                onChange(phone)
              }
              onClose()
            }}
          >
            <View style={{ flex: 1 }}>
              <Title numberOfLines={1} ellipsizeMode={'tail'}>
                {item.givenName} {item.familyName}
              </Title>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                <View
                  style={{
                    backgroundColor: colors.primary,
                    borderRadius: 5,
                    padding: 3,
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                >
                  <Text style={{ color: 'white', fontSize: 12 }}>
                    {item.phoneNumbers[0].number}
                  </Text>
                </View>
              </View>
            </View>
            <Icon
              name="chevron-right"
              style={{ marginHorizontal: 9 }}
              size={20}
            />
          </TouchableOpacity>
        </View>
      )}
    />
  )
}

const reformat = (val) => {
  if (!val) return ''
  let v = val.replace(/[^\d]/g, '')

  if (v.startsWith('996')) v = v.replace('996', '')
  if (v.startsWith('997')) v = v.replace('997', '')
  if (v.startsWith('998')) v = v.replace('998', '')
  if (v.startsWith('992')) v = v.replace('992', '')
  if (v.startsWith('7')) v = v.replace('7', '')
  if (v.startsWith('0')) v = v.replace('0', '')
  return v
}

interface ContactPickerProperty {
  show: boolean
  onClose: () => void
  onChange: (val) => void
}

export default function ContactPicker({
  show,
  onClose,
  onChange,
}: ContactPickerProperty) {
  const [value, setValue] = useState('')
  const [granted, setGranted] = useState('denied')
  const [contact, setContact] = useState<any>([])
  const { t } = useTranslation()

  const getPermision = () => {
    permissions.contacts().then(async (r) => {
      if (r === 'granted') {
        const contacts = await Contacts.getAll()
        if (contacts && contacts.length > 0) {
          let c = contacts.sort(
            (a: any, b) =>
              a.givenName && a.givenName.localeCompare(b.givenName),
          )
          c = c.filter((a) => a.phoneNumbers && a.phoneNumbers.length > 0)
          setContact(c)
        }
      }
      setGranted(r)
    })
  }

  useEffect(() => {
    show && getPermision()
  }, [show])

  return (
    <Modal
      visible={show}
      onClose={onClose}
      title={t('contacts')}
      style={{
        width: screenWidth,
        height: screenHeight,
        borderWidth: 0,
        borderRadius: 0,
      }}
    >
      <SearchBar value={value} onChangeText={(val) => setValue(val)} />
      {granted === 'granted' ? (
        <RenderItems
          value={value}
          contact={contact}
          onClose={onClose}
          onChange={onChange}
        />
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 30,
          }}
        >
          <Text size={18} style={{ textAlign: 'center' }}>
            Нужно разрешение к контактам в настройках телефона
          </Text>
        </View>
      )}
    </Modal>
  )
}
