import React, { ReactElement, useMemo } from 'react'
import DataTable, { TableProps } from 'react-data-table-component'
import { translate, colors, hooks } from '@pay24/common'
import { Text } from 'react-native'
import { NoData } from './Components'

const { useTranslation } = translate
const { useTheme } = hooks

interface Props<T> extends TableProps<T> {
  rows?: number
  renderHeader?: ReactElement
  renderNoData?: ReactElement
  rowKey?: string
  noHeader?: boolean
}

const DEFAULT_PAGES = [10, 25, 50, 100, 200]

function Table<T>(props: Props<T>) {
  const {
    data,
    columns,
    fixedHeaderScrollHeight,
    pagination,
    rows,
    paginationTotalRows,
    paginationRowsPerPageOptions,
    paginationServer,
    onChangeRowsPerPage,
    onChangePage,
    onRowClicked,
    renderHeader,
    renderNoData,
    progressPending,
    noHeader = true,
    rowKey,
    ...rest
  } = props

  const _theme = useTheme()
  const { t } = useTranslation()
  const { isLight } = _theme

  const customStyles = useMemo(
    () => ({
      table: {
        style: {
          // backgroundColor: 'transparent',
          borderTopRightRadius: noHeader ? 5 : 0,
          borderTopLeftRadius: noHeader ? 5 : 0,
          borderBottomRightRadius: '5px',
          borderBottomLeftRadius: '5px',
          overflow: 'hidden',
        },
      },
      rows: {
        style: {
          margin: 0,
          alignItems: 'stretch',
        },
      },
      header: {
        style: {
          borderTopRightRadius: '5px',
          borderTopLeftRadius: '5px',
          fontSize: 16,
          minHeight: 40,
          paddingLeft: 5,
          color: colors.metal,
          borderBottom: `1px solid`,
        },
      },
      headRow: {
        style: {
          backgroundColor: isLight
            ? _theme['background-basic-color-1']
            : _theme['background-basic-color-3'],
          overflow: 'hidden',
        },
      },
      headCells: {
        style: {
          // override the cell padding for head cells
          minHeight: 40,
          fontSize: '12px',
          color: _theme['text-basic-color'],
          paddingLeft: '5px',
          fontWeight: '600',
        },
      },
      cells: {
        style: {
          padding: '0px',
          paddingBottom: '10px',
          paddingTop: '10px',
          cursor: 'pointer',
          fontSize: '14px',
          // borderLeftWidth: '1px',
          // borderLeftStyle: 'solid',
          // borderLeftColor: _theme['background-basic-color-2']
        },
      },
      pagination: {
        style: {
          borderBottomRightRadius: '5px',
          borderBottomLeftRadius: '5px',
        },
      },
    }),
    [_theme, isLight, noHeader],
  )

  const cols = useMemo(() => {
    if (!columns || columns.length === 0) return []
    let cls: any[] = []
    columns.forEach((c) => {
      let a = { ...c }
      a.name = t((c as any).name)
      a.style = {
        ...a.style,
        ...{ 'padding-right': '5px', 'padding-left': '5px' },
      }
      cls.push(a)
    })
    return cls
  }, [columns, t])

  const noData = useMemo(() => <NoData title={'no_data_full_web'} />, [])

  return (
    <>
      {renderHeader}
      <DataTable
        noHeader={noHeader}
        columns={cols?.filter((c) => c.show !== false)}
        data={data}
        fixedHeader
        responsive
        striped
        keyField={rowKey}
        paginationComponentOptions={useMemo(
          () => ({
            rowsPerPageText: t('rows_per_page'),
            rangeSeparatorText: 'из',
          }),
          [],
        )}
        pagination={pagination === undefined ? true : pagination}
        paginationPerPage={rows ? rows : 100}
        paginationRowsPerPageOptions={
          paginationRowsPerPageOptions
            ? paginationRowsPerPageOptions
            : DEFAULT_PAGES
        }
        customStyles={customStyles}
        highlightOnHover
        theme={isLight ? 'light' : 'dark'}
        noDataComponent={renderNoData ? renderNoData : noData}
        paginationServer={paginationServer}
        paginationTotalRows={paginationTotalRows}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onRowClicked={onRowClicked}
        fixedHeaderScrollHeight={fixedHeaderScrollHeight || '500px'}
        dense
        progressPending={progressPending}
        progressComponent={
          <Text style={{ padding: 30, fontWeight: '600', fontSize: 16 }}>
            Загрузка...
          </Text>
        }
        {...rest}
      />
    </>
  )
}

export default Table
