import React from 'react'
import { Pressable, ViewStyle } from 'react-native'
import Icon from './Icon'
import { Text } from './Text'
import { colors, hooks, translate } from '@pay24/common'

const { useResponsive, useTheme } = hooks
const { useTranslation } = translate

export interface IToolbarButtonProps {
  testID?: string
  onPress?: () => void
  icon?: string
  label?: string
  style?: ViewStyle
}

const ToolbarButton = ({
  testID = 'toolbarBtnID',
  onPress,
  icon,
  label = '',
  style = {},
}: IToolbarButtonProps) => {
  const { md } = useResponsive()
  const { primary } = useTheme()
  const { t } = useTranslation()

  return (
    <Pressable
      testID={testID}
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 20,
          padding: 3,
        },
        style,
      ]}
      onPress={() => {
        if (onPress) {
          onPress()
        }
      }}
    >
      <Icon name={icon} size={24} color={colors.metal} />
      {!!label && md && (
        <Text style={{ marginHorizontal: 7 }} color={primary}>
          {t(label)}
        </Text>
      )}
    </Pressable>
  )
}

export default ToolbarButton
