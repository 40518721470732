import {
  CheckBox,
  CheckBoxProps as KittenCheckBoxProps,
} from '@ui-kitten/components'
import React, { useEffect, useState } from 'react'

interface CheckBoxProps
  extends Omit<KittenCheckBoxProps, 'checked' | 'onChange'> {
  value?: boolean
  label?: string
  model?: any
  name?: string
  onChange?: (checked: boolean) => void
}

export default (props: CheckBoxProps) => {
  const { label, value = false, onChange, model, name } = props
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (model && name && model[name] !== undefined) {
      setChecked(model[name])
    } else {
      setChecked(value === undefined ? false : value)
    }
  }, [value])

  useEffect(() => {
    if (model && name) {
      setChecked(model[name] === undefined ? false : model[name])
    }
  }, [model && name && model[name]])

  const onCheckedChange = () => {
    if (model && name) {
      if (model.setValue) {
        model.setValue(name, !checked)
      } else {
        model[name] = !checked
      }
    }
    if (onChange) {
      onChange(!checked)
    }
    setChecked(!checked)
  }
  return (
    <CheckBox {...props} checked={checked} onChange={onCheckedChange}>
      {label}
    </CheckBox>
  )
}
