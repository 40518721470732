import { TouchableWithoutFeedback } from '@ui-kitten/components/devsupport'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { _constants, store, colors, translate } from '@pay24/common'
import { Alert } from '../alert'
import { ItemContainer } from '../Components'
import { Input } from '../Input'
import { Text } from '../Text'
import Spacer from '../Spacer'
import { Button } from '../Button'
import Icon from '../Icon'

const { useTranslation, i18n } = translate
const { MESSAGE_TYPE } = _constants
const { SecurityStore, RootStore } = store

interface PasswordProps {
  setMain: () => void
  storage?: any
}

export default observer(function Password(props: PasswordProps) {
  const { t } = useTranslation()
  const { storage = undefined, setMain } = props

  let securityStore = SecurityStore.SecurityStore
  if (storage) {
    securityStore = storage
  }
  const [securePassword, setSecurePassword] = useState(true)
  const [secureNewPassword, setSecureNewPassword] = useState(true)
  const [secureConfirmNewPassword, setSecureConfirmNewPassword] = useState(true)
  const [showError, setShowError] = useState(false)

  const resetPasswords = () => {
    securityStore.setPassword('')
    securityStore.setNewPassword('')
    securityStore.setConfirmPassword('')
  }

  const onSavePassword = () => {
    if (securityStore.disabled) {
      setShowError(true)
    } else if (securityStore.incorrectNewPassword) {
      RootStore.setMessage({
        type: MESSAGE_TYPE.WARNING,
        text: t('incorrect_new_password'),
      })
    } else {
      securityStore.saveNewPassword().then(() => {
        Alert.alert(
          '',
          i18n.t('password_changed'),
          [{ text: 'OK', onPress: () => null }],
          { cancelable: true },
        )
        resetPasswords()
        setMain()
      })
    }
  }

  return (
    <>
      <ItemContainer style={{ marginBottom: 10, padding: 8 }}>
        <Text color={colors.metal}>{t('pswd_description')}</Text>
      </ItemContainer>
      <Input
        testID="password"
        label="current_password"
        placeholder="min_7_symbols"
        secureTextEntry={securePassword}
        errorText={showError ? 'Проверьте пароль' : ''}
        rightComponent={
          <PasswordInputRightComponent
            value={securePassword}
            setState={setSecurePassword}
          />
        }
        value={securityStore.password}
        onChangeText={(v) => {
          securityStore.setPassword(v)
        }}
      />
      <Spacer />
      <Input
        testID="new_password"
        label="create_password"
        placeholder="min_7_symbols"
        secureTextEntry={secureNewPassword}
        errorText={showError ? 'Проверьте новый пароль' : ''}
        rightComponent={
          <PasswordInputRightComponent
            value={secureNewPassword}
            setState={setSecureNewPassword}
          />
        }
        value={securityStore.newPassword}
        onChangeText={(v) => {
          securityStore.setNewPassword(v)
        }}
      />
      <Spacer />
      <Input
        testID="confirm_new_password"
        label="confirm_new_password"
        placeholder="min_7_symbols"
        secureTextEntry={secureConfirmNewPassword}
        errorText={showError ? 'Проверьте новый пароль' : ''}
        rightComponent={
          <PasswordInputRightComponent
            value={secureConfirmNewPassword}
            setState={setSecureConfirmNewPassword}
          />
        }
        value={securityStore.confirmNewPassword}
        onChangeText={(v) => {
          securityStore.setConfirmPassword(v)
        }}
      />
      <Spacer />
      <Button testID="submit" onPress={onSavePassword}>
        {t('save')}
      </Button>
    </>
  )
})

function PasswordInputRightComponent({ setState, value }) {
  return (
    <TouchableWithoutFeedback onPress={() => setState(!value)}>
      <Icon
        name={value ? 'eye-outline' : 'eye-off-outline'}
        color={colors.metal}
      />
    </TouchableWithoutFeedback>
  )
}
