import React from 'react'
import { FlatList } from 'react-native'
import { Divider, NoData } from './Components'
import { isObservableArray } from 'mobx'

export default ({ data, noText, ...rest }) => (
  <FlatList
    data={data ? (isObservableArray(data) ? data.slice() : data) : []}
    ListEmptyComponent={<NoData title={noText} />}
    ItemSeparatorComponent={() => <Divider style={{ marginVertical: 5 }} />}
    keyExtractor={(item, index) => index.toString()}
    {...rest}
  />
)
