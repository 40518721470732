import walletApi from '@pay24/common/src/api/wallet'
import { Button } from '../Button'
import { ItemContainer } from '../Components'
import { Input } from '../Input'
import Spacer from '../Spacer'
import { Text } from '../Text'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useTranslation } from '@pay24/common/src/translate'

const Deposit = ({ model, service_id, navigation }) => {
  const {
    getSource,
    onAdd,
    services,
    value,
    changeValue,
    changeCustomer,
    customer,
    requisiteFound,
  } = model
  const service = getSource(service_id)
  let [checking, setChecking] = useState(false)
  const { t } = useTranslation()

  const search = async () => {
    setChecking(false)
    const requisite = value.replace(/\s/g, '')
    try {
      const r = await walletApi.checkRequisite(
        { requisite, service_id: service.id },
        true,
      )
      changeCustomer(
        r.customer_name ? r.customer_name.trim() : 'Найден',
        r.result,
      )
    } catch (e: any) {
      changeCustomer(e.message)
    }
  }

  useEffect(() => {
    if (value && RegExp(service?.validation).test(value)) {
      setChecking(true)
    } else {
      setChecking(false)
    }
  }, [value])

  const onSavePress = () => {
    const item = { requisite: value.split(' ').join(''), id: service.id }
    const serviceFind = services.find((s) => s.id === item.id)
    const params = {
      type: 'credit',
      name: serviceFind?.name,
      img: serviceFind?.image,
      service_code: serviceFind?.type,
      ...item,
    }
    onAdd(params).then(() => {
      navigation.navigate('credit')
    })
  }
  //1180000067350845

  return (
    <>
      {!customer ? (
        <>
          <Input
            label={service.data?.requisite_message || 'requisite'}
            value={value}
            testID={'requisite'}
            inputMask={
              service?.placeholder?.replace(/ /g, '') !== ''
                ? service?.placeholder?.replace(/_/g, '9')
                : ''
            }
            keyboardType={'phone-pad'}
            placeholder={service.placeholder}
            onChangeText={(val) => {
              changeValue(val)
            }}
            maxLength={19}
          />
          <Spacer />
          <Button testID={'search'} onPress={search} disabled={!checking}>
            {t('next')}
          </Button>
        </>
      ) : (
        <>
          <ItemContainer style={{ padding: 20 }}>
            <Item title="subscriber" value={value} t={t} />
            <Item title="subscriber" value={customer} t={t} />
          </ItemContainer>
          <Spacer />
          <Button
            disabled={requisiteFound}
            onPress={onSavePress}
            testID={'save'}
          >
            {t('save')}
          </Button>
        </>
      )}
    </>
  )
}

const Item = ({
  title,
  value,
  t,
}: {
  title: string
  value: string
  t: any
}) => (
  <View
    style={{
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginHorizontal: 10,
      paddingVertical: 5,
      alignItems: 'center',
    }}
  >
    <Text>{t(title)}:</Text>
    <Text style={{ textAlign: 'right', fontWeight: 'bold' }}>{value}</Text>
  </View>
)

export default observer(Deposit)
