import React, { useEffect, useState } from 'react'
import {
  Datepicker as KittenDatePicker,
  I18nConfig,
  NativeDateService,
} from '@ui-kitten/components'
import moment from 'moment'
import { constants, colors, store, translate } from '@pay24/common'
import { observer } from 'mobx-react'
import Icon from './Icon'
import { RenderProp } from '@ui-kitten/components/devsupport'
import { ImageProps } from 'react-native'
import { Text } from './Text'
import { DatepickerProps } from '@ui-kitten/components/ui/datepicker/datepicker.component'
import { autorun } from 'mobx'

const { useTranslation } = translate
const { RootStore } = store

export const i18nn: I18nConfig = {
  dayNames: {
    short: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    long: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ],
  },
  monthNames: {
    short: [
      'Янв',
      'Фев',
      'Март',
      'Апр',
      'Май',
      'Июнь',
      'Июль',
      'Авг',
      'Сен',
      'Окт',
      'Ноя',
      'Дек',
    ],
    long: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
  },
}
export const i18nnKg: I18nConfig = {
  dayNames: {
    short: ['Же', 'Дү', 'Ше', 'Ша', 'Бе', 'Жу', 'Иш'],
    long: [
      'Жекшемби',
      'Дүйшөмбү',
      'Шейшемби',
      'Шаршемби',
      'Бейшемби',
      'Жума',
      'Ишемби',
    ],
  },
  monthNames: {
    short: [
      'Үчтүн',
      'Бир',
      'Жалг',
      'Чын',
      'Бугу',
      'Кул',
      'Теке',
      'Баш',
      'Аяк',
      'Тогуз',
      'Жети',
      'Беш',
    ],
    long: [
      'Үчтүн Айы',
      'Бирдин Айы',
      'Жалган Куран',
      'Чын Куран',
      'Бугу',
      'Кулжа',
      'Теке',
      'Баш Оона',
      'Аяк Оона',
      'Тогуздун айы',
      'Жетинин Айы',
      'Бештин Айы',
    ],
  },
}

const localeDateServiceKg = new NativeDateService('kg', {
  i18n: i18nnKg,
  startDayOfWeek: 1,
  format: 'DD.MM.YYYY',
})

const localeDateServiceEn = new NativeDateService('en', {
  startDayOfWeek: 1,
  format: 'DD.MM.YYYY',
})

const localeDateService = new NativeDateService('ru', {
  i18n: i18nn,
  startDayOfWeek: 1,
  format: 'DD.MM.YYYY',
})

const CalendarIcon = (props) => {
  delete props.style.tintColor
  return <Icon {...props} name="calendar-outline" color={colors.metal} />
}

interface Props extends Omit<DatepickerProps, 'min' | 'max'> {
  onChange?: (date: moment.Moment | null) => void
  model?: any
  name?: string
  value?: string | moment.Moment
  min?: string | moment.Moment
  max?: string | moment.Moment
  label?: string
  inputRequired?: boolean
  placeholder?: string
  rightComponent?: RenderProp<Partial<ImageProps>>
  leftComponent?: RenderProp<Partial<ImageProps>>
  errorText?: string
  caption?: string
  testID?: string
  disabled?: boolean
}

export default observer(
  ({
    placeholder,
    onChange,
    model,
    errorText,
    caption,
    name,
    value,
    label,
    inputRequired = false,
    min,
    max,
    rightComponent,
    leftComponent,
    testID,
    disabled,
    nativeID,
    ...rest
  }: Props) => {
    const [localValue, setValue] = useState<moment.Moment | null>(null)
    const { t } = useTranslation()

    useEffect(() => {
      autorun(() => {
        try {
          if (value) {
            setValue(moment(value))
          } else if (model && name && model[name]) {
            setValue(moment(model[name]))
          }
        } catch (e) {
          setValue(moment())
        }
      })
      return () => setValue(null)
    }, [value])

    useEffect(() => {
      let val = localValue
      if (model && name) {
        val = model[name]
        if (!val && value) {
          if (model.setValue) {
            model.setValue(name, value)
          } else {
            model[name] = value
          }
        }
      }
    }, [localValue])

    const bottomTextStyle = {
      marginTop: 3,
      marginLeft: 3,
      color: colors.metal,
      fontSize: 10,
    }

    const bottomText = errorText ? (
      <Text style={[bottomTextStyle, { color: colors.red }]}>{errorText}</Text>
    ) : (
      !!caption && <Text style={bottomTextStyle}>{caption}</Text>
    )

    const setDate = (date) => {
      const dt = moment(date)

      if (model && name) {
        if (model.setValue) {
          model.setValue(name, dt.format(constants.DB_DATE_FORMAT))
        } else {
          model[name] = dt.format(constants.DB_DATE_FORMAT)
        }
      }
      if (onChange) {
        onChange(dt)
      }

      setValue(dt)
    }

    return (
      <>
        <KittenDatePicker
          disabled={disabled}
          testID={testID}
          nativeID={nativeID || testID}
          placeholder={placeholder || t('date').toString()}
          dateService={
            RootStore.language === 'en'
              ? localeDateServiceEn
              : RootStore.language === 'kg'
              ? localeDateServiceKg
              : localeDateService
          }
          min={
            min
              ? moment(min).toDate()
              : moment('1940-01-01', constants.DB_DATE_FORMAT).toDate()
          }
          max={
            max
              ? moment(max).toDate()
              : moment('2040-01-01', constants.DB_DATE_FORMAT).toDate()
          }
          label={t(label || '').toString() + (inputRequired ? '*' : '')}
          date={localValue ? localValue.toDate() : undefined}
          onSelect={(dt) => {
            setDate(dt)
          }}
          accessoryRight={rightComponent ? rightComponent : CalendarIcon}
          accessoryLeft={leftComponent && leftComponent}
          {...rest}
        />
        {bottomText}
      </>
    )
  },
)
