import React, { useState } from 'react'
import SocialShare from './SocialShare'
import Modal from './Modal'
import { utils } from '@pay24/common'

const { DeviceInfo, isWeb } = utils

export const useShare = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const [data, setData] = useState<Record<string, any>>({})

  async function onShare(payload: Record<string, any> = { title: 'Pay24' }) {
    setData(payload)
    if (isWeb) {
      setVisible(true)
    } else {
      try {
        await DeviceInfo.openShare(payload)
      } catch (err) {}
    }
  }

  return {
    component: visible ? (
      <Modal visible={visible} title="share" onClose={() => setVisible(false)}>
        <SocialShare url={data.message || data.url} title={data.title} />
      </Modal>
    ) : null,
    onShare,
  }
}
