export const PAGES = {
  HISTORY_DETAIL: 'history_detail',
  CHEQUE: 'cheque',
  UNALLOCATED: 'unallocated',
  CHEQUE_SHOT: 'cheque_shot',
  LAST_PAYMENTS: 'last_payments',
  PAYMENT_RECEIVER: 'payment_receiver',
  DEPOSIT_TERMINAL: 'deposit_through_terminal',
} as const

export type PageName = (typeof PAGES)[keyof typeof PAGES]

export const TITLES: Record<PageName, string> = {
  [PAGES.HISTORY_DETAIL]: 'details',
  [PAGES.UNALLOCATED]: 'unallocated_funds',
  [PAGES.CHEQUE_SHOT]: 'cheque',
  [PAGES.CHEQUE]: 'cheque',
  [PAGES.LAST_PAYMENTS]: 'last_payments',
  [PAGES.PAYMENT_RECEIVER]: 'payment_receiver',
  [PAGES.DEPOSIT_TERMINAL]: 'deposit_through_terminal',
}
