import React, { ReactNode, useCallback } from 'react'
import { Pressable, TouchableOpacity, ViewStyle } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Layout } from '@ui-kitten/components'
import Icon from './Icon'
import { MainTitle } from './Text'
import { utils, hooks, translate as i18translate } from '@pay24/common'

const { safeAreaUtils } = utils
const { useResponsive, useTheme } = hooks
const { useTranslation } = i18translate

const statusBarHeight = safeAreaUtils.getStatusBarHeight(true)

interface ToolbarProps {
  title?: string | null | ReactNode
  rightComponent?: ReactNode
  back?: boolean
  menu?: boolean
  leftComponent?: ReactNode
  onBackPress?: () => void | undefined
  style?: ViewStyle
  onPress?: () => void
  testID?: string
  translate?: boolean
  titleTestID?: string
  backScreen?: string
}

export default (props: ToolbarProps) => {
  const {
    title = '',
    rightComponent,
    back,
    leftComponent,
    onBackPress,
    style = {},
    onPress,
    testID = 'toolbarID',
    titleTestID,
    backScreen,
    translate = true,
  } = props

  const { isLight } = useTheme()
  const { t } = useTranslation()

  const { md } = useResponsive()
  const HEADER_HEIGHT = 60

  return (
    <Pressable onPress={onPress} testID={testID}>
      <Layout
        style={[
          {
            height: HEADER_HEIGHT + statusBarHeight,
            paddingHorizontal: md ? 20 : 10,
            paddingRight: md ? 30 : 10,
            flexDirection: 'row',
            paddingTop: statusBarHeight,
            width: '100%',
            alignItems: 'center',
          },
          style,
        ]}
        level={isLight ? '1' : '3'}
      >
        {back ? (
          <BackButton
            back={back}
            onBackPress={onBackPress}
            backScreen={backScreen}
          />
        ) : null}
        {leftComponent}
        {typeof title === 'string' ? (
          <MainTitle style={{ flex: 1 }} testID={titleTestID}>
            {translate ? t(title + '') : title}
          </MainTitle>
        ) : (
          title
        )}
        {rightComponent}
      </Layout>
    </Pressable>
  )
}

interface BackButtonProps {
  back?: boolean
  backScreen?: string
  onBackPress?: () => void
}

function BackButton(props: BackButtonProps) {
  const { backScreen = 'home', onBackPress, back } = props

  const navigation = useNavigation()

  let onPressBack = useCallback(() => {
    if (back) {
      if (onBackPress) {
        onBackPress()
      } else {
        if (navigation.canGoBack()) {
          navigation.goBack()
        }
      }
    }
  }, [back, backScreen, navigation, onBackPress])

  return (
    <TouchableOpacity onPress={onPressBack}>
      <Icon
        testID="goBackBtn"
        name="chevron-left"
        size={32}
        style={{ marginRight: 10 }}
      />
    </TouchableOpacity>
  )
}
