import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Animated, Text, View, Pressable } from 'react-native'
import { observer } from 'mobx-react'
import {
  utils,
  colors,
  hooks,
  translate,
  store,
  _constants,
} from '@pay24/common'
import Icon from './Icon'

const { RootStore } = store
const { composeStyle, safeAreaUtils, isWeb, isMobile } = utils
const { useResponsive } = hooks
const { i18n } = translate

interface IMessage {
  type?: 'error' | 'info' | 'success' | 'warning'
  text?: string
}

export default observer(() => {
  const [message, setMessage] = useState<IMessage | null>(null)
  const { sm, md } = useResponsive()

  useEffect(() => {
    if (RootStore?.message) {
      if (typeof RootStore?.message === 'string') {
        setMessage({ type: 'error', text: RootStore?.message })
      } else {
        setMessage({
          type: RootStore?.message.type || 'info',
          text: RootStore?.message.text,
        })
      }
    } else {
      setMessage(null)
    }
  }, [RootStore?.message])

  const onClose = () => {
    if (RootStore?.message) {
      RootStore.setMessage(null)
    }
  }

  return useMemo(() => {
    if (!message) return null
    return (
      <Toast
        visible={!!message}
        onClose={onClose}
        message={message}
        right={0}
        md={md}
      />
    )
  }, [md, message, sm])
})

const DURATION = 5000

const Toast = ({ visible, onClose, message, right, md }) => {
  // const [show, setShow] = useState(false)
  const fadeAnim = useRef(new Animated.Value(0)).current
  let timeOut
  const close = (delay) => {
    if (timeOut) {
      clearTimeout(timeOut)
    }
    timeOut = setTimeout(() => {
      Animated.timing(fadeAnim, {
        useNativeDriver: !isWeb,
        toValue: 0,
        duration: 500,
      }).start(() => {
        onClose()
      })
    }, delay || DURATION)
  }

  useEffect(() => {
    if (visible) {
      // setShow(true)
      Animated.timing(fadeAnim, {
        useNativeDriver: !isWeb,
        toValue: 1,
        duration: 500,
      }).start(() => close(null))
    }
  }, [close, fadeAnim, visible])

  const backgroundColor = useMemo(() => {
    return {
      backgroundColor: _constants.MESSAGE_COLOR[message?.type || 'error'],
    }
  }, [message?.type])

  const statusbarHeight = safeAreaUtils.getStatusBarHeight(true)
  const padding = isMobile
    ? { padding: 10, paddingTop: statusbarHeight + 10 }
    : { padding: 15 }

  return (
    <Animated.View
      style={composeStyle(
        fadingContainer,
        {
          opacity: fadeAnim,
          flex: 1,
          borderRadius: isMobile ? 0 : 5,
          marginRight: isMobile ? 0 : 12,
          marginTop: isMobile ? 0 : 12,
          maxHeight: 300,
        },
        backgroundColor,
        padding,
        right,
      )}
    >
      <Icon
        name={_constants.MESSAGE_ICONS[message?.type]}
        color={colors.white}
        style={{ marginRight: 10 }}
      />
      <View style={{ marginRight: 50, flex: 1 }}>
        <Text
          style={{ fontSize: md ? 16 : 14, color: colors.white }}
          testID={'error_message'}
        >
          {i18n.t(message?.text)}
        </Text>
      </View>
      <Pressable testID={'close_error_message'} onPress={() => close(1)}>
        <Icon name="close" color={colors.white} />
      </Pressable>
    </Animated.View>
  )
}

const fadingContainer = {
  position: 'absolute',
  zIndex: 10000,
  elevation: 999,
  right: 0,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}
