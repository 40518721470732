import {
  FormCheck,
  FormDone,
  FormFail,
  FormInput,
  Pay3D,
} from './DefaultTemplate'
import { getItems } from './utils'
import { PaymentStore } from '@pay24/common/src/store'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import ListPicker from '../ListPicker'
import Spacer from '../Spacer'
import { observer } from 'mobx-react'
import React from 'react'
import * as yup from 'yup'

const FormInitial = observer((props) => {
  const { service, source } = props
  const { payData } = PaymentStore

  const getValue = (key, value) => {
    if (!payData.options) return null
    const k = payData.getOptions(key)
    const v = payData.getOptions(value)
    return { id: k, name: v }
  }

  const valid_fields = {
    currency_code: yup.string().required('Выберите валюту'),
  }

  const renderFields = (options_errors) => {
    const fields = service.data && service.data.fields
    if (!fields || fields.length === 0 || Object.keys(fields).length === 0) {
      return null
    }

    const currency = fields.find(
      (value) => value.name_for_code === 'currency_code',
    )

    return (
      <>
        <ListPicker
          testID="currency"
          errorText={options_errors?.currency_code}
          items={getItems(currency.data)}
          label={currency.label}
          value={getValue(currency.name_for_code, currency.name_for_text)}
          onChange={(val) => {
            PaymentStore.setOption(currency.name_for_code, val.id)
            PaymentStore.setOption(currency.name_for_text, val.name)
          }}
          onClearPress={() => {
            PaymentStore.deleteOption(currency.name_for_code)
            PaymentStore.deleteOption(currency.name_for_text)
          }}
        />
        <Spacer />
      </>
    )
  }

  return (
    <FormInput
      {...props}
      source={source}
      customFields={renderFields}
      valid_fields={valid_fields}
    />
  )
})

export default (isAgent) => {
  if (isAgent) {
    return {
      steps: 3,
      [FORM.INITIAL]: FormInitial,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormDone,
      [FORM.FAIL]: FormFail,
    }
  }
  return {
    steps: 4,
    [FORM.INITIAL]: FormInitial,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
