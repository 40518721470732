import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { Text } from 'react-native'
import * as yaml from 'js-yaml'
import buildStore from './build-store'
import makeInspectable from 'mobx-devtools-mst'
import buildLayout from './build-layout'

const StoreContext = createContext<{
  store: any
  form: any
}>({
  store: null,
  form: null,
})
export const useStore = () => useContext(StoreContext)

export const StoreProvider = StoreContext.Provider
export const FormEngine: React.FC<{ definition: string }> = ({
  definition,
}) => {
  const [store, setStore] = useState<any>(null)
  const [form, setForm] = useState<any>(null)
  useLayoutEffect(() => {
    try {
      const form = yaml.load(definition)
      setForm(form)
      const formStore = buildStore(form)
      setStore(formStore)
    } catch (e) {}
  }, [definition])
  if (!store) return <Text>Loading form...</Text>
  const engineStore = store.create({})
  makeInspectable(engineStore)
  return (
    <StoreProvider value={{ store: engineStore, form: form }}>
      <Layout />
    </StoreProvider>
  )
}

function Layout() {
  const { form, store } = useStore()
  const [children, setChildren] = useState([<Text>Loading components...</Text>])
  useEffect(() => {
    if (!form) return
    try {
      const newChildren = buildLayout(form, store)
      setChildren(newChildren)
    } catch (e) {}
  }, [form])
  return <>{children}</>
}
