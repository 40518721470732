import { colors } from '@pay24/common'
import { amountFormatCurrency } from '@pay24/common/src/utils'
import { useTheme } from '@pay24/common/src/hooks'
import { CheckItem, FormDone, FormFail, Pay3D } from './DefaultTemplate'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { PaymentStore, RootStore as rootStore } from '@pay24/common/src/store'
import { Button } from '../Button'
import { Divider, ItemContainer } from '../Components'
import { Input } from '../Input'
import PreviewCheck from '../PreviewCheck'
import Spacer from '../Spacer'
import { Text, Description, Title } from '../Text'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from '@pay24/common/src/translate'
import { TouchableOpacity, View } from 'react-native'

const FormInitial = observer((props) => {
  const { service, source } = props
  const { payData, getEmasPayInfo, emasPayInfo } = PaymentStore
  const { t } = useTranslation()

  useEffect(() => {
    getEmasPayInfo()
  }, [])

  let amount_message = `${t('amount_due')}: ${emasPayInfo?.bill} ${
    emasPayInfo?.currency_id
  } (${amountFormatCurrency(emasPayInfo?.amount)})`

  const onRequisiteCheck = () => {
    payData.setValue('service_id', service.id)
    payData.setValue('service_code', service.type)
    payData.setValue('service_name', service.name)
    payData.setValue('requisite', rootStore?.user?.login)
    payData.setValue(
      'explanation',
      `Оплата обучение ${service.name} на ${rootStore?.user?.login}`,
    )
    payData.setValue('document_id', emasPayInfo.document_id)
    payData.setValue('amount', parseFloat(emasPayInfo.amount))
    PaymentStore.autoPassportFill()
    payData.setValue('to_fio', 'ОсОО  "Емас"')
    const data = {
      totalAmount: payData.totalAmount,
      amount: emasPayInfo.amount,
      acquiringCommission:
        (parseFloat(source.commission) *
          (emasPayInfo.amount + payData.paymentCommission)) /
        100,
      commission: payData.paymentCommission,
      currency_id: emasPayInfo.currency_id,
      rate: emasPayInfo.rate,
      rub_rate: emasPayInfo.rub_rate,
      bill: emasPayInfo.bill,
      diff_amount_kgs: emasPayInfo.diff_amount_kgs,
    }
    payData.setValue('provider_data', data)
    PaymentStore.requisiteCheck(source).then(() => {
      PaymentStore.setPaymentState(FORM.CHECK)
      PaymentStore.setStep(2)
    })
  }

  return (
    <>
      {!PaymentStore.isAgent && !source ? (
        <Text
          style={{
            color: colors.red,
            marginTop: 3,
            marginLeft: 3,
            fontSize: 10,
          }}
        >
          {t('required_source')}
        </Text>
      ) : null}
      <Spacer />
      {emasPayInfo?.bill && emasPayInfo?.bill > 0 ? (
        <Title>{amount_message}</Title>
      ) : null}
      <Spacer />
      <Input
        testID="amount"
        label="sum"
        value={emasPayInfo?.bill + emasPayInfo?.commission?.foreign_amount}
        disabled
      />
      <Spacer />
      <Button
        testID="nextBtn"
        disabled={PaymentStore.buttonBlock || !emasPayInfo?.bill}
        onPress={onRequisiteCheck}
      >
        {t('next')}
      </Button>
      <Spacer />
    </>
  )
})

const FormCheck = observer(({ source, customFields }) => {
  const { payData, buttonBlock, isAgent, emasPayInfo } = PaymentStore
  const { isLight, primary } = useTheme()
  const [checkVisible, setCheckVisible] = useState(false)
  const { t } = useTranslation()

  const onPay = () => {
    PaymentStore.payData.setValue('theme', isLight ? 'light' : 'dark')
    const data = {
      totalAmount: payData.totalAmount,
      amount: emasPayInfo.amount,
      acquiringCommission:
        (parseFloat(source.commission) *
          (emasPayInfo.amount + payData.paymentCommission)) /
        100,
      commission: payData.paymentCommission,
      currency_id: emasPayInfo.currency_id,
      rate: emasPayInfo.rate,
      rub_rate: emasPayInfo.rub_rate,
      bill: emasPayInfo.bill,
      diff_amount_kgs: emasPayInfo.diff_amount_kgs,
    }

    payData.setValue('provider_data', data)
    PaymentStore.pay(source, () => {
      PaymentStore.setStep(PaymentStore.step + 1)
    })
  }

  return (
    <>
      <Spacer />
      <Text size={12} style={{ color: colors.metal }}>
        {source?.source !== 'NETEX'
          ? t('payment_info')
          : 'Информация об обмене'}
      </Text>
      <Spacer />
      <ItemContainer testID="paymentInfo" style={{ padding: 20 }}>
        <CheckItem title="subscriber" model={payData} name={'customer_name'} />
        <CheckItem title="requisite" model={payData} name={'requisite'} />
        {customFields && customFields()}
        <CheckItem title="sum" model={payData} name={'amount'} currency />
        <Spacer />
        <CheckItem
          title={
            source?.source !== 'NETEX'
              ? 'payment_method'
              : 'Криптовалюта для обмена'
          }
          model={source}
          name={'name'}
        />
        <CheckItem
          title="payment_commission"
          model={payData}
          name={'paymentCommission'}
          currency
        />
        {!!payData.acquiringCommission && (
          <CheckItem
            title="acquiring_commission"
            model={payData}
            name={'acquiringCommission'}
          />
        )}
        {!!payData.commission && (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingVertical: 3,
              alignItems: 'center',
            }}
          >
            <Description>{t('total_commission') + ':'}</Description>
            <Text style={{ textAlign: 'right', fontWeight: 'bold', flex: 1 }}>
              {(payData?.commission / emasPayInfo.rate).toFixed(2)}{' '}
              {emasPayInfo.currency_id} ({payData?.commission} {'KGS'})
            </Text>
          </View>
        )}
        <Divider />
        <Spacer />
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: 3,
            alignItems: 'center',
          }}
        >
          <Description>{t('to_be_written_off') + ':'}</Description>
          <Text style={{ textAlign: 'right', fontWeight: 'bold', flex: 1 }}>
            {(payData?.totalAmount / emasPayInfo.rate).toFixed(2)}{' '}
            {emasPayInfo.currency_id} ({payData?.totalAmount} {'KGS'})
          </Text>
        </View>
        <Spacer />
        {isAgent ? (
          <TouchableOpacity
            testID="preview_cheque"
            onPress={() => setCheckVisible(true)}
            style={{
              alignSelf: 'center',
            }}
          >
            <Text color={primary}>{t('preview_cheque')}</Text>
          </TouchableOpacity>
        ) : null}
      </ItemContainer>
      <Spacer />
      <Button testID="nextBtn" disabled={buttonBlock} onPress={onPay}>
        {t('next')}
      </Button>

      <PreviewCheck
        item={payData}
        visible={checkVisible}
        onClose={() => setCheckVisible(false)}
      />
    </>
  )
})

export default (isAgent) => {
  if (isAgent) {
    return {
      steps: 3,
      [FORM.INITIAL]: FormInitial,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormDone,
      [FORM.FAIL]: FormFail,
    }
  }
  return {
    steps: 4,
    [FORM.INITIAL]: FormInitial,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
