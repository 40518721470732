import BankAsia from './BankAsiaTemplate'
import BPDTemplate from './BPDTemplate'
import Demir from './DemirTemplate'
import EMAS from './EMASTemplate'
import Bironex from './BironexTemplate'
import MBank from './MbankTemplate'
import JivayaVoda from './JivayaVodaTemplate'
import FINCABank from './FINCABankTemplate'
import Kzbanks from './KzbanksTemplate'
import MTS from './MTSTemplate'
import Tel from './TelTemplate'
import Uzcard from './UzcardTemplate'
import PMFK from './PMFKTemplate'
import Charge24FromTemplate from './ChargeTemplate'
import Currency from './CurrencyTemplate'
import DefaultPassportTemplate from './DefaultPassportTemplate'
import DefaultTemplate from './DefaultTemplate'
import GNS from './GNSTemplate'
import GNSTransport from './GNSTransportTemplate'
import MicroCreditCompanyMolBulakFinance from './MicroCreditCompanyMolBulakFinance'
import SpravkaNeSudCreate from './SpravkaNeSudCreateTemplate'
import Valorant from './ValorantTemplate'

const templates = {
  std: DefaultTemplate,
  id_std: DefaultPassportTemplate,
  residue_with_fio: DefaultPassportTemplate,
  manual_amount: DefaultTemplate,
  currency: Currency,
  gns: GNS,
  gns_transport: GNSTransport,
  kadastr: DefaultTemplate,
  MolBulak: MicroCreditCompanyMolBulakFinance,
  fincabank: FINCABank,
  mvd: SpravkaNeSudCreate,
  'mp-valorant': Valorant,
  'mp-mtc': MTS,
  uzcard_mp: Uzcard,
  'mp-kzbanks': Kzbanks,
  tel: Tel,
  pmfk: PMFK,
}

export function loadTemplate(service, isAgent = false) {
  const template = templates[service.data.form_scheme]
  if (service.type === 'EMAS_EMAS') {
    return EMAS(isAgent)
  }
  if (service.type.startsWith('CBK')) {
    return MBank(isAgent)
  }
  if (service.type.startsWith('BankAsia_Pay')) {
    return BankAsia(isAgent)
  }
  if (['JivayaVoda_JivayaVoda'].includes(service.type)) {
    return JivayaVoda(isAgent)
  }
  if (['DemirBank_CRACNT'].includes(service.type)) {
    return Demir(isAgent)
  }
  if (['OsOOCheckPay_Bironex'].includes(service.type)) {
    return Bironex(isAgent)
  }
  if (service.type === 'Pay24_Charge24') {
    return Charge24FromTemplate()
  }
  if (service?.template && service.template.startsWith('coca_cola')) {
    return BPDTemplate()
  }
  if (!template) {
    return DefaultTemplate(isAgent)
  }
  return template(isAgent)
}

export * from './utils'
