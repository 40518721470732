import React, { useEffect, useState } from 'react'
import { Toggle } from '@ui-kitten/components'
import { hooks } from '@pay24/common'
import { ViewStyle } from 'react-native'
import { EvaStatus } from '@ui-kitten/components/devsupport'

const { useTheme } = hooks

export default ({
  label,
  testID,
  value = false,
  onChange,
  status,
  model,
  name,
  style,
}: {
  label?: string
  model?: any
  name?: string
  style?: ViewStyle
  testID?: string
  onChange?: (value: boolean) => void
  status?: EvaStatus
  value?: boolean
}) => {
  const [checked, setChecked] = useState<boolean>(false)
  const { isLight } = useTheme()

  useEffect(() => {
    if (model && name && model[name] !== undefined) {
      setChecked(model[name])
    } else {
      setChecked(value === undefined ? false : !!value)
    }
  }, [value])

  useEffect(() => {
    if (model && name) {
      setChecked(model[name] === undefined ? false : model[name])
    }
  }, [model, name, model && model[name || '']])

  const onCheckedChange = () => {
    if (model && name) {
      if (model.setValue) {
        model.setValue(name, !checked)
      } else {
        model[name] = !checked
      }
    }
    if (onChange) {
      onChange(!checked)
    }
    setChecked(!checked)
  }

  return (
    <Toggle
      testID={testID}
      checked={checked}
      onChange={onCheckedChange}
      style={style}
      status={isLight ? 'warning' : status}
    >
      {label}
    </Toggle>
  )
}
