import React, { useRef } from 'react'
import { hooks } from '@pay24/common'

const { useDragScroll } = hooks

export default ({ children }) => {
  const scrollWrapperRef = useRef(null)
  const { isDragging } = useDragScroll({
    sliderRef: scrollWrapperRef,
  })

  return (
    <div className="scroll-box">
      <div className="scroll-box__wrapper" ref={scrollWrapperRef}>
        <div
          className="scroll-box__container"
          role="list"
          style={{ pointerEvents: isDragging ? 'none' : undefined }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}
