import { colors, hooks, translate, utils } from '@pay24/common'
import {
  IndexPath,
  Layout,
  Select as KittenSelect,
  SelectItem,
  SelectProps as KittenSelectProps,
} from '@ui-kitten/components'
import { capitalize } from 'lodash'
import isObject from 'lodash/isObject'
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { SafeAreaView, ScrollView, TouchableOpacity, View } from 'react-native'
import Modal from 'react-native-modal'
import Icon from './Icon'
import { Input } from './Input'
import { Text } from './Text'

const { isIos } = utils
const { useResponsive } = hooks
const { useTranslation } = translate

function findIndex(value: any, items: any[], keyName: string) {
  if (value === null || value === undefined) return -1
  if (isObject(value)) {
    return items.findIndex((option) => option[keyName] === value[keyName])
  }
  return items.findIndex((option) => option[keyName] === value)
}

function makeDisplayLabel(
  index: number,
  items: any[],
  valueName: string,
  rightValue: string | number | null,
) {
  const displayValue: string =
    index < 0 || !items || items.length === 0 ? '' : items[index][valueName]
  const displayRightValue: string =
    index < 0 || !items || items.length === 0 || !displayValue || !rightValue
      ? ''
      : ': ' + items[index][rightValue]
  return [displayValue, displayRightValue]
}

function useLabel(
  displayValue: string,
  displayRightValue: string,
  capital: boolean,
  noTranslate: boolean,
): string {
  const { t } = useTranslation()
  return useMemo(() => {
    const capitalized = capital ? capitalize(displayValue) : displayValue || ''
    if (noTranslate) {
      return capitalized + displayRightValue
    } else {
      return t(capitalized) + displayRightValue
    }
  }, [t, capital, displayRightValue, displayValue, noTranslate])
}

export interface SelectProps
  extends Omit<KittenSelectProps, 'onSelect' | 'label'> {
  options?: any[]
  errorText?: string
  keyName?: string
  valueName?: string
  onChange?: ((item: any) => void) | null
  onClearPress?: (() => void) | null
  capital?: boolean
  label?: string
  value?: any
  sort?: boolean
  rightValue?: string | number | null
  noTranslate?: boolean
  placeholder?: string
  style?: any
  inputRequired?: boolean
  nativeID?: string
}

interface ISelectWeb {
  items: any[]
  getTitle: (item) => string
  value: string | object | number
  keyName: string
  valueName: string
  rightValue: string | number | null
  placeholder: string | undefined
  onClearPress: (() => void) | null | undefined
  onSelect?: ((item: any) => void) | null
  label: string | undefined
  noTranslate: boolean
  capital: boolean
  style?: any
  inputRequired?: boolean
  testID?: string
  nativeID?: string
}

interface ISelectModalNative {
  visible: boolean
  closeModal: () => void
  children: ReactNode
}

interface ISelectMobile {
  placeholder: string
  onSelect?: ((item: any) => void) | null
  label: string | undefined
  items: any[]
  getTitle: (item) => string
  value: string | object | number
  rightValue: string | number | null
  valueName: string
  onClearPress?: (() => void) | null
  keyName: string
  noTranslate: boolean
  capital: boolean
  style?: any
  testID?: string
  nativeID?: string
  disabled?: boolean
}

export default React.memo(
  ({
    options = [],
    value,
    errorText,
    caption,
    keyName = 'id',
    valueName = 'name',
    rightValue = null,
    label,
    inputRequired = false,
    onChange,
    onClearPress,
    capital = false,
    sort = true,
    noTranslate = false,
    placeholder = 'select',
    style,
    testID,
    nativeID,
    ...rest
  }: SelectProps) => {
    const { sm } = useResponsive()
    const { t } = useTranslation()

    const bottomTextStyle = {
      marginTop: 3,
      marginLeft: 3,
      color: colors.metal,
      fontSize: 10,
    }
    const bottomText = errorText ? (
      <Text style={[bottomTextStyle, { color: colors.red }]}>{errorText}</Text>
    ) : (
      !!caption && <Text style={bottomTextStyle}>{caption}</Text>
    )

    const getTitle = useCallback(
      (item): string => {
        const title = noTranslate
          ? capital
            ? capitalize(item[valueName])
            : item[valueName]
          : t(capital ? capitalize(item[valueName]) : item[valueName])
        return !rightValue ? title : title + ': ' + item[rightValue]
      },
      [capital, noTranslate, rightValue, valueName],
    )

    const items =
      sort && options
        ? [...options].sort((a, b) => a[valueName].localeCompare(b[valueName]))
        : options

    const Wrapper = isIos ? SafeAreaView : View

    return (
      <Wrapper>
        {sm ? (
          <SelectWeb
            items={items}
            getTitle={getTitle}
            value={value}
            keyName={keyName}
            valueName={valueName}
            rightValue={rightValue}
            placeholder={placeholder}
            onClearPress={onClearPress}
            label={label}
            inputRequired={inputRequired}
            noTranslate={noTranslate}
            capital={capital}
            onSelect={onChange}
            style={style}
            testID={testID || label}
            nativeID={nativeID || testID}
            {...rest}
          />
        ) : (
          <SelectMobile
            items={items}
            getTitle={getTitle}
            value={value}
            keyName={keyName}
            valueName={valueName}
            rightValue={rightValue}
            placeholder={placeholder}
            onClearPress={onClearPress}
            label={label}
            noTranslate={noTranslate}
            capital={capital}
            onSelect={onChange}
            style={style}
            testID={testID || label}
            nativeID={nativeID || testID}
            {...rest}
          />
        )}
        {bottomText}
      </Wrapper>
    )
  },
)

function SelectWeb(props: ISelectWeb) {
  const {
    items,
    getTitle,
    value,
    keyName,
    label = '',
    inputRequired = false,
  } = props
  const { valueName, rightValue, placeholder = '', onClearPress } = props
  const { noTranslate, capital, onSelect, style, ...rest } = props
  const { t } = useTranslation()
  const index = findIndex(value, items, keyName)
  const [displayValue, displayRightValue] = makeDisplayLabel(
    index,
    items,
    valueName,
    rightValue,
  )
  const _value = useLabel(displayValue, displayRightValue, capital, noTranslate)
  const renderItems = useMemo(() => {
    return items?.map((o, i) => {
      const title = getTitle(o)
      return (
        <SelectItem
          testID={o.name}
          disabled={o.disabled || false}
          key={i}
          title={() => (
            <Text
              color={o.disabled ? colors.metal : ''}
              style={
                index === i ? { fontWeight: '700' } : { fontWeight: '400' }
              }
            >
              {title}
            </Text>
          )}
        />
      )
    })
  }, [getTitle, items])

  const accessoryRight =
    onClearPress && displayValue ? (
      <TouchableOpacity onPress={() => onClearPress()}>
        <Icon
          style={{
            height: 18,
            width: 18,
            color: colors.metal,
          }}
          name="close"
        />
      </TouchableOpacity>
    ) : undefined

  return (
    <KittenSelect
      {...rest}
      accessoryRight={accessoryRight}
      status="basic"
      value={_value}
      placeholder={t(placeholder)}
      selectedIndex={useMemo(
        () => (index > 0 ? new IndexPath(index) : undefined),
        [index],
      )}
      onSelect={(idx) => {
        if (onSelect) {
          onSelect(items[(idx as IndexPath).row])
        }
      }}
      style={style}
      label={t(label) + (inputRequired ? ' *' : '')}
    >
      {renderItems}
    </KittenSelect>
  )
}

function SelectModalNative({
  visible,
  closeModal,
  children,
}: ISelectModalNative) {
  return (
    <Modal
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={visible}
      onBackdropPress={closeModal}
      onBackButtonPress={closeModal}
      style={{
        margin: 0,
        justifyContent: 'flex-end',
        paddingTop: 100,
      }}
    >
      <Layout
        style={{
          borderRadius: 10,
          paddingHorizontal: 16,
          paddingTop: 12,
          maxHeight: '100%',
          paddingBottom: 16,
        }}
      >
        {children}
      </Layout>
    </Modal>
  )
}

function SelectMobile(props: ISelectMobile) {
  const {
    placeholder,
    onSelect,
    label = '',
    items,
    onClearPress,
    testID,
  } = props
  const { getTitle, value, rightValue, valueName, style, nativeID } = props
  const { keyName, noTranslate, capital } = props
  const { t } = useTranslation()

  const index = findIndex(value, items, keyName)
  const [displayValue, displayRightValue] = makeDisplayLabel(
    index,
    items,
    valueName,
    rightValue,
  )
  const _value = useLabel(displayValue, displayRightValue, capital, noTranslate)

  const [open, setOpen] = useState<boolean>(false)

  const showPicker = () => {
    if (!items || items.length === 0) return
    setOpen(true)
  }
  const closePicker = () => setOpen(false)

  const renderItem = (item, idx) => {
    const _onPress = () => {
      closePicker()
      onSelect && onSelect(item)
    }
    const title = getTitle(item)

    return (
      <TouchableOpacity
        key={idx}
        disabled={item.disabled || false}
        onPress={_onPress}
        style={{
          paddingVertical: 12,
        }}
      >
        <Text color={(item.disabled || false) && colors.metal} size={16}>
          {title}
        </Text>
      </TouchableOpacity>
    )
  }

  const _onClearPress = useCallback(() => {
    if (!onClearPress) return
    onClearPress()
  }, [onClearPress])

  return (
    <>
      <Input
        containerStyle={style}
        editable={false}
        label={t(label)}
        onPress={!props.disabled ? showPicker : undefined}
        placeholder={t(placeholder)}
        value={_value}
        onChangeText={_onClearPress}
        overlayPress
        clearable={!!onClearPress}
        testID={testID}
        nativeID={nativeID || testID}
      />
      <SelectModalNative visible={open} closeModal={closePicker}>
        <ModalHeader closePicker={closePicker} label={label} />
        <ScrollView>{items.map(renderItem)}</ScrollView>
      </SelectModalNative>
    </>
  )
}

function ModalHeader({
  label = '',
  closePicker,
}: {
  label: string | undefined
  closePicker: () => void
}) {
  const { t } = useTranslation()
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
      }}
    >
      <Text style={{ fontWeight: 'bold' }} size={16}>
        {t(label)}
      </Text>
      <Icon name="close" onPress={closePicker} testID="close_modal" />
    </View>
  )
}
