import { colors, hooks, platform } from '@pay24/common'
import { AccountStore } from '@pay24/common/src/store'
import rootStore from '@pay24/common/src/store/RootStore'
import { parsePhoneNumber } from 'libphonenumber-js'
import { observer } from 'mobx-react'
import React from 'react'
import { FrameContainer } from '../Components'
import { Col, Row } from '../grid'
import Image from '../Image'
import { ACCOUNTS } from '../paymentForms'
import Screen from '../ScreenNew'
import Spacer from '../Spacer'

const { useTheme } = hooks

export default observer(({ navigation, route }) => {
  const { onAdd } = AccountStore
  const { user } = rootStore
  const onAddPress = ({
    requisite,
    service_code,
    secure,
    bereke = false,
    ...rest
  }) => {
    if (bereke) {
      navigation.replace('debit')
    } else {
      onAdd({ type: 'debit', requisite, service_code, secure, ...rest }).then(
        () => navigation.replace('debit'),
      )
    }
  }

  const isKz = parsePhoneNumber(user?.login ?? '').country === 'KZ'

  return (
    <Screen title={'add_money_source'} back>
      {route.params?.id ? (
        <DebitForm route_id={route.params.id} onSave={onAddPress} />
      ) : (
        <DebitList navigation={navigation} isKz={isKz} />
      )}
    </Screen>
  )
})

const DebitList = ({ navigation, isKz }) => {
  if (isKz) {
    return (
      <Col xs={12} sm={6} md={4} lg={4}>
        <FrameContainer
          leftComponent={<LeftComponent img={'card.png'} id={'bereke'} />}
          title={'Visa'}
          arrow
          onPress={() => {
            navigation.push('debit/add', { id: 'bereke' })
          }}
        />
      </Col>
    )
  }

  return (
    <Row>
      {ACCOUNTS.filter((item) => item.visible).map((item, idx) => (
        <Col xs={12} sm={6} md={4} lg={4} key={idx}>
          <FrameContainer
            leftComponent={<LeftComponent img={item.img} id={item?.id} />}
            title={item.title}
            arrow
            onPress={() => {
              navigation.push('debit/add', { id: item.id })
            }}
          />
          <Spacer />
        </Col>
      ))}
    </Row>
  )
}

const LeftComponent = ({ img, id }) => {
  const { isLight } = useTheme()
  return (
    <Image
      key={id}
      style={{
        width: 60,
        height: 60,
        overflow: 'hidden',
        marginRight: 8,
        backgroundColor: colors.white,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: isLight ? colors.lightGrey : colors.borderDark,
      }}
      source={{
        uri: `${platform.acquiring.url}/static/${img}`,
      }}
      resizeMode={'contain'}
    />
  )
}

const DebitForm = ({ route_id, onSave }) => {
  let FormComponent
  const { isLight } = useTheme()
  const source = ACCOUNTS.find((a) => a.id === route_id)
  if (route_id) {
    FormComponent = source?.FormComponent
  }

  return (
    <Row>
      <Col xs={12} sm={12} md={6} lg={4}>
        <FrameContainer
          leftComponent={
            <Image
              style={{
                width: 60,
                height: 60,
                overflow: 'hidden',
                marginRight: 8,
                backgroundColor: colors.white,
                borderWidth: 1,
                borderRadius: 4,
                borderColor: isLight ? colors.lightGrey : colors.borderDark,
              }}
              source={{
                uri: `${platform.acquiring.url}/static/${source?.img}`,
              }}
              resizeMode={'contain'}
            />
          }
          title={source?.title}
        />
        <Spacer />
        {!!FormComponent && <FormComponent onSave={onSave} />}
      </Col>
    </Row>
  )
}
