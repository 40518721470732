import { PagesProperty, usePrintRef } from '@pay24/common/src/hooks'
import React from 'react'
import { NoData } from '../../Components'
import { ServiceInfo } from '../../ServiceInfo'
import HistoryInfo from '../HistoryInfo'
import { PageName, PAGES, TITLES } from '../types'
import FilterDrawer from '../../FilterDrawer'
import { ShotContainer } from './index'
import LastPayments from './LastPayments'

interface SidebarProps {
  pages: PagesProperty<PageName, { id: string | number }>[]
  openPage: (val) => void
  goBack: () => void
  onClose: () => void
  navigation: any
}

export default function HistoryDetails(props: SidebarProps) {
  const { pages, openPage, navigation, goBack, onClose } = props
  const screen = pages[pages.length - 1]?.screen
  const page = pages[pages.length - 1]
  const onBackPress = pages.length > 1 ? goBack : undefined
  const { viewRef: componentRef, print: handlePrint } = usePrintRef()

  return (
    <FilterDrawer
      title={TITLES[screen]}
      visible={pages.length !== 0}
      onClose={onClose}
      onBackPress={onBackPress}
    >
      {screen === PAGES.LAST_PAYMENTS ? (
        <LastPayments
          page={page}
          navigation={navigation}
          openPage={openPage}
          onClose={onClose}
        />
      ) : screen === PAGES.HISTORY_DETAIL ? (
        <HistoryInfo
          navigation={navigation}
          page={pages[pages.length - 1]}
          openPage={openPage}
          onClose={onClose}
        />
      ) : screen === PAGES.CHEQUE ? (
        <ShotContainer
          page={page}
          handlePrint={handlePrint}
          ref={componentRef}
        />
      ) : screen === PAGES.PAYMENT_RECEIVER ? (
        <ServiceInfo page={page} />
      ) : (
        <NoData />
      )}
    </FilterDrawer>
  )
}
