import PropTypes from 'prop-types'
import React, { Children, cloneElement, isValidElement, memo, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { GAP_SIZE } from './constants'
import useBreakPoint from './useBreakPoint'
import useBreakPointValue from './useBreakPointValue'
import useBreakPointWidth from './useBreakPointWidth'

const styles = StyleSheet.create({
  wrapper: { flexDirection: 'row', flexWrap: 'wrap' },
  wrapper_nowrap: { flexDirection: 'row', flexWrap: 'nowrap' },
})

/**
 * @param point {string}
 * @param gapSize {?number}
 * @returns {{padding:number}}
 */
function useStylePadding (point, gapSize) {
  const size = useBreakPointValue(point, GAP_SIZE)
  const padding = isFinite(gapSize) && gapSize >= 0 ? Math.round(gapSize / 2) : size
  return useMemo(() => ({ padding }), [padding])
}

function PointConsumer ({ children }) {
  const point = useBreakPoint()
  return children(point)
}

function useChildren (props, stylePadding) {
  const { point, children } = props
  const width = useBreakPointWidth(point, props)
  // noinspection JSCheckFunctionSignatures
  return useMemo(() => Children.toArray(children)
    .filter(child => isValidElement(child))
    .map(child => cloneElement(child, {
      point, ...child.props, style: [{ width }, stylePadding, child.props.style],
    })), [children, point, width, stylePadding])
}

function GridPoint (props) {
  const { point, gapSize, wrap = true, style } = props
  const stylePadding = useStylePadding(point, gapSize)
  const children = useChildren(props, stylePadding)
  return (
    <View style={[wrap ? styles.wrapper : styles.wrapper_nowrap, stylePadding, style]}>
      {children}
    </View>
  )
}

const Grid = memo(function Grid (props) {
  return props.point ? (
    <GridPoint {...props}/>
  ) : (
    <PointConsumer>
      {point => <GridPoint {...props} point={point}/>}
    </PointConsumer>
  )
})

export default Grid

Grid.propTypes = {
  gapSize: PropTypes.number,
  point: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  sm: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  lg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  xl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  style: PropTypes.any,
  wrap: PropTypes.bool,
}

Grid.defaultProps = {
  wrap: true,
}
