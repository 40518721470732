import { colors } from '@pay24/common'
import { useResponsive } from '@pay24/common/src/hooks'
import { OPERATION, STATUS } from '@pay24/common/src/store/PaymentHistoryStore'
import { View } from 'react-native'
import { Button } from './Button'
import { Text } from './Text'
import { TextDetail } from './Components'
import Spacer from './Spacer'
import {
  amountFormatCurrency,
  dateTimeFormat,
  getStatusText,
  isCharge24,
  isWeb,
} from '@pay24/common/src/utils'
import React, {
  LegacyRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useReactToPrint } from 'react-to-print'
import { pay24 as pay24Api } from '@pay24/common/src/api'
import Modal from './Modal'
import { useTranslation } from '@pay24/common/src/translate'

export default ({ detail_id, visible, onClose, navigation }) => {
  const [detail, setDetail] = useState<any>({})
  const componentRef: MutableRefObject<any> | null = isWeb ? useRef() : null
  const { sm, screenWidth, screenHeight } = useResponsive()
  const [loading, setLoading] = React.useState(true)
  const { t } = useTranslation()
  const handlePrint = isWeb
    ? useReactToPrint({
        content: () => componentRef?.current,
      })
    : null

  useEffect(() => {
    if (visible) {
      setLoading(true)
      pay24Api
        .recordDetails({
          record_id: detail_id,
          is_charge: isCharge24,
        })
        .then((r) => {
          setDetail(r)
        })
        .finally(() => setLoading(false))
    }
  }, [detail_id, visible])

  const withdraw = (record) => {
    onClose()
    navigation.navigate('money-transfer', {
      record_id: record.id,
      amount: record.amount,
    })
  }

  if (!visible || loading) return null

  return (
    <Modal
      visible={visible}
      title={t('cheque')}
      titleColor={colors.black}
      iconColor={colors.black}
      onClose={onClose}
      style={
        !sm
          ? {
              width: screenWidth,
              height: screenHeight,
              backgroundColor: colors.lightGrey,
            }
          : {
              width: 320,
              height: undefined,
              borderWidth: 0,
              borderRadius: 10,
              backgroundColor: colors.lightGrey,
            }
      }
    >
      <ChequeContent
        record={detail.record}
        forwards={detail.forwards}
        ref={componentRef}
      />

      <View
        style={{
          margin: 10,
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        {detail.record.dc === OPERATION.INCOME &&
          detail.record.status === STATUS.HOLD && (
            <Button
              onPress={() => {
                setDetail(null)
                withdraw(detail.record)
              }}
            >
              <Text>{'Вывести на счет'}</Text>
            </Button>
          )}
        {detail.record.dc === OPERATION.INCOME &&
          detail.record.status === STATUS.SUCCESS &&
          isWeb && (
            <Button
              onPress={() => {
                handlePrint && handlePrint()
              }}
            >
              <Text>{t('print_cheque')}</Text>
            </Button>
          )}
        {detail.record.dc === OPERATION.EXPENSE &&
          detail.record.status === STATUS.HOLD && (
            <>
              <Button
                onPress={() => {
                  onClose()
                }}
              >
                <Text>{'Попробовать заново'}</Text>
              </Button>
              <Button
                onPress={() => {
                  onClose()
                  withdraw(detail.record)
                }}
              >
                <Text>{'Вывести на счет'}</Text>
              </Button>
            </>
          )}
        {isWeb &&
          detail.record.dc === OPERATION.EXPENSE &&
          detail.record.status === STATUS.SUCCESS &&
          isWeb && (
            <Text
              color={colors.primary}
              onPress={() => {
                handlePrint && handlePrint()
              }}
            >
              {t('print_cheque')}
            </Text>
          )}
      </View>
    </Modal>
  )
}

export const ChequeContent = React.forwardRef(
  (props: any, ref: LegacyRef<View>) => {
    const { record, print = false } = props
    const { t } = useTranslation()

    return (
      <View
        ref={ref}
        style={[
          print
            ? { backgroundColor: 'transparent', width: 312, margin: 10 }
            : { margin: 10 },
        ]}
      >
        <Text
          size={10}
          numberOfLines={10}
          color={print ? colors.black : undefined}
          style={{
            textAlign: 'right',
          }}
        >
          {t(isCharge24 ? 'llc_charge24' : 'llc')}
        </Text>

        <View style={{ paddingVertical: 10 }}>
          <Text
            color={print ? colors.black : undefined}
            style={{ fontSize: 16, fontWeight: 'bold' }}
          >
            {t('cheque')} №: {record.id}
          </Text>
        </View>

        <View>
          <Text
            style={{ fontWeight: 'bold' }}
            color={print ? colors.black : undefined}
          >
            {record.data.payload.service_name}
          </Text>
          <Spacer />
          <TextDetail
            name={'requisite'}
            value={record.data?.payload?.requisite}
            black={print}
          />
          {record.dc === 'd' && (
            <TextDetail
              name={'amount'}
              value={amountFormatCurrency(record.data.payload.amount)}
              black={print}
            />
          )}
          {record.dc === 'c' && (
            <TextDetail
              name={'amount'}
              value={amountFormatCurrency(record.amount)}
              black={print}
            />
          )}
          {record.dc === 'd' && (
            <>
              <TextDetail
                name={'payment_commission'}
                value={amountFormatCurrency(
                  record.data?.payload?.paymentCommission,
                  true,
                )}
                black={print}
              />
              <TextDetail
                name={'acquiring_commission'}
                value={amountFormatCurrency(
                  record.data?.payload?.commission -
                    record.data?.payload?.paymentCommission >
                    0
                    ? record.data?.payload?.commission -
                        record.data?.payload?.paymentCommission
                    : 0,
                  true,
                )}
                black={print}
              />
              <TextDetail
                name={'total'}
                value={amountFormatCurrency(record.data.payload.totalAmount)}
                black={print}
              />
            </>
          )}

          <Spacer />
          <TextDetail
            name={'date'}
            value={dateTimeFormat(record.rec_date)}
            black={print}
          />
          <TextDetail
            name={'status'}
            value={getStatusText(record.status)}
            status={record.status}
            black={print}
          />
        </View>
      </View>
    )
  },
)
