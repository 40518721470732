import { colors, translate } from '@pay24/common'
import React from 'react'
import { View } from 'react-native'
import { Button } from '../../Button'
import Icon from '../../Icon'
import Modal from '../../Modal'
import Spacer from '../../Spacer'
import { Text } from '../../Text'

const { useTranslation } = translate

export const ModalIdentification = ({ visible, setVisible, navigation }) => {
  const { t } = useTranslation()
  const exit = () =>
    navigation.canGoBack() ? navigation.goBack() : navigation.replace('home')

  return (
    <Modal
      title={t('identify')}
      visible={visible}
      onClose={() => {
        setVisible(false)
        exit()
      }}
      closeColor={colors.white}
    >
      <View style={{ alignItems: 'center', width: 340 }}>
        <View
          style={{
            height: 56,
            width: 56,
            borderTopLeftRadius: 28,
            borderTopRightRadius: 28,
            borderBottomLeftRadius: 28,
            borderBottomRightRadius: 28,
            backgroundColor: colors.rose,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon color={colors.white} name="alert-octagon-outline" />
        </View>
        <Spacer />
        <Text
          size={18}
          color={colors.rose}
          style={{ textTransform: 'uppercase' }}
        >
          {t('no_identificated')}
        </Text>
        <Spacer />
        <Text color={colors.metal}>{t('status')}</Text>
        <Spacer height={18} />
        <Text style={{ textAlign: 'center' }}>
          {t('identification_requirement')}
        </Text>
        <Spacer />
        <Button
          onPress={() => {
            setVisible(false)
            navigation.navigate('profile', { screen: 'passport' })
          }}
        >
          <Text
            style={{ textTransform: 'uppercase', fontWeight: '600' }}
            color={colors.white}
          >
            {t('pass_identification')}
          </Text>
        </Button>
        <Spacer />
      </View>
    </Modal>
  )
}
