import { colors, store, translate } from '@pay24/common'
import { useIsFocused } from '@react-navigation/native'
import { observer } from 'mobx-react'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import { AccountCard } from '../../account'
import { ItemContainer } from '../../Components'
import FilterDrawer from '../../FilterDrawer'
import Icon from '../../Icon'
import Spacer from '../../Spacer'
import { SubTitle, Text } from '../../Text'

const { useTranslation } = translate
const { AccountStore, AgentStore, RootStore } = store

interface AccountSelectProperty {
  navigation: any
  service: any
  onAccountSelect: (val) => void
}

const AccountSelect = observer(
  ({ navigation, service, onAccountSelect }: AccountSelectProperty) => {
    const isFocused = useIsFocused()
    const { debit, setDebit, debitWired, debitUnWired } = AccountStore
    const { t } = useTranslation()
    const [visible, setVisible] = useState<boolean>(false)

    useEffect(() => {
      if (!isFocused) return
      AccountStore.refreshDebit(service?.id)
    }, [isFocused])

    useEffect(() => {
      onAccountSelect(debit)
    }, [debit])

    const onNavigate = useCallback(() => {
      navigation.navigate('debit', { service_id: service?.id })
      setVisible(false)
    }, [navigation, service?.id])

    let content

    if (debit) {
      content = (
        <>
          <Text
            size={12}
            style={{ fontWeight: 'bold', color: colors.metal, marginBottom: 4 }}
          >
            {t('payment_type')}
          </Text>
          <AccountCard
            item={debit}
            arrow
            requisite={debit.id === 'agent' ? AgentStore.sub_code : undefined}
            onPress={() => setVisible(true)}
          />
        </>
      )
    } else {
      content = (
        <ItemContainer
          onPress={() => setVisible(true)}
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 12,
            alignItems: 'center',
            borderRadius: 4,
          }}
        >
          <Text style={{ marginRight: 20, flex: 1 }}>
            {t('select_payment_type')}
          </Text>
          <Icon name="chevron-down" size={24} color={colors.metal} />
        </ItemContainer>
      )
    }

    return (
      <View testID="debitAccount">
        {content}
        <FilterDrawer
          title="accounts_debit"
          visible={visible}
          onClose={() => setVisible(false)}
        >
          <Text
            size={16}
            color={colors.primary}
            onPress={onNavigate}
            style={{ marginVertical: 10, textAlign: 'center' }}
          >
            {t('Настроить источник денег')}
          </Text>
          {debitWired.length > 0 && <SubTitle>{t('binded')}</SubTitle>}
          {debitWired.map((d, idx) => (
            <Fragment key={idx}>
              <AccountCard
                item={d}
                selectedItem={RootStore.user?.data.default_debit}
                requisite={d.id === 'agent' ? AgentStore.sub_code : undefined}
                onPress={() => {
                  onAccountSelect(d)
                  setDebit(d)
                  setVisible(false)
                }}
              />
              <Spacer />
            </Fragment>
          ))}
          {debitUnWired.length > 0 && <SubTitle>{t('not_binded')}</SubTitle>}
          {debitUnWired
            .filter((item) => item?.source !== 'NETEX' && item?.id !== 'crypto')
            .map((d, idx) => (
              <Fragment key={idx}>
                <AccountCard
                  item={d}
                  selectedItem={RootStore.user?.data.default_debit}
                  onPress={() => {
                    onAccountSelect(d)
                    setDebit(d)
                    setVisible(false)
                  }}
                />
                <Spacer />
              </Fragment>
            ))}
        </FilterDrawer>
      </View>
    )
  },
)

export default AccountSelect
