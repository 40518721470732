import i18n from 'i18next'
import { observer } from 'mobx-react'
import React, { ComponentProps, useEffect, useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { api, hooks, store, colors, translate, constants } from '@pay24/common'
import { Alert } from '../alert'
import { Button } from '../Button'
import { ItemContainer } from '../Components'
import PhoneInput from '../PhoneInput'
import { Text } from '../Text'
import Spacer from '../Spacer'
import { Input } from '../Input'

const { useTranslation } = translate
const { useTheme } = hooks
const { RootStore, SecurityStore } = store
const { passport } = api
const { STEP_REQUEST_OTP, STEP_INPUT_DATA, STEP_SEND_DATA } = SecurityStore

const WAIT_TIME = 120

export default observer(({ closeDrawer }: ComponentProps<any>) => {
  const { user } = RootStore
  const securityStore = SecurityStore.SecurityStore

  useEffect(() => {
    securityStore.setPhone(user?.login)
  }, [])

  return (
    <>
      {securityStore.phoneState === STEP_REQUEST_OTP ? (
        <StepRequestOtp securityStore={securityStore} />
      ) : securityStore.phoneState === STEP_INPUT_DATA ? (
        <StepInputData securityStore={securityStore} />
      ) : securityStore.phoneState === STEP_SEND_DATA ? (
        <StepSendData securityStore={securityStore} closeDrawer={closeDrawer} />
      ) : null}
    </>
  )
})

const StepRequestOtp = observer(function StepOne({ securityStore }) {
  const { t } = useTranslation()

  return (
    <>
      <ItemContainer style={{ padding: 8, marginBottom: 10 }}>
        <Text color={colors.metal}>{t('change_phone_text')}</Text>
      </ItemContainer>
      <PhoneInput
        disabled
        countryCodeDisabled
        testID="phone"
        label="Номер телефон"
        onChangeText={(value) => {
          securityStore.setPhone(value)
        }}
        getCode={(val) => securityStore.setPhoneCode(val)}
        value={securityStore.phone}
      />
      <Spacer />
      <Spacer height={20} />
      <Button testID="submit1" onPress={securityStore.phoneChange1}>
        {i18n.t('phone_change')}
      </Button>
    </>
  )
})

const StepInputData = observer(function StepInputData({ securityStore }) {
  const { primary } = useTheme()
  const { t } = useTranslation()
  const [second, setSecond] = useState(0)
  const user = RootStore.user

  const findKeyByCode = (code: string) => {
    for (let key in constants.PHONE_CODES) {
      if (constants.PHONE_CODES[key].code === code) {
        return key
      }
    }
    return undefined
  }

  useEffect(() => {
    let active = true

    passport
      .getOtpWaitTime()
      .then((resp) => active && setSecond(resp.seconds > 0 ? resp.seconds : 0))

    return () => {
      active = false
    }
  }, [])

  useEffect(() => {
    const id = setTimeout(() => setSecond((prev) => prev - 1), 1e3)
    if (second <= 0) {
      clearTimeout(id)
    }
    return () => clearTimeout(id)
  }, [second])

  return (
    <>
      <ItemContainer style={{ padding: 8, marginBottom: 10 }}>
        <Text
          color={colors.metal}
        >{`На Ваш номер ${user?.login} отправлено сообщение с кодом подтверждения.`}</Text>
      </ItemContainer>
      <Input
        testID="code_old"
        label="enter_sms_code"
        placeholder="4 цифры"
        maxLength={4}
        onChangeText={(value) => securityStore.setPhoneOldCode(value)}
        value={securityStore.phoneOldCode}
      />
      <Spacer />
      <TouchableOpacity
        onPress={() =>
          securityStore.phoneChange1().then(() => setSecond(WAIT_TIME))
        }
        disabled={!!second}
      >
        <Text>
          <Text color={primary}>Отправить код еще раз</Text>
          {second ? <Text color={primary}> ({second} сек)</Text> : null}
        </Text>
      </TouchableOpacity>
      <Spacer />
      <PhoneInput
        testID="new_phone"
        label="new_phone_number"
        getCode={(val) => securityStore.setPhoneCode(val)}
        onChangeText={(value) => securityStore.setNewPhone(value)}
        countryCode={findKeyByCode(securityStore.phoneCode)}
        value={securityStore.newPhone}
      />
      <Spacer height={20} />
      <Button
        testID="submit2"
        disabled={!securityStore.phoneOldCode || !securityStore.newPhone}
        onPress={securityStore.phoneChange2}
      >
        {t('confirm')}
      </Button>
    </>
  )
})

const StepSendData = observer(function StepSendData({
  securityStore,
  closeDrawer,
}) {
  const { t } = useTranslation()
  return (
    <>
      <ItemContainer style={{ padding: 8, marginBottom: 10 }}>
        <Text
          color={colors.metal}
        >{`На Ваш номер ${securityStore?.newPhone} отправлено сообщение с кодом подтверждения.`}</Text>
      </ItemContainer>
      <Input
        testID="new_code"
        label="enter_sms_code"
        onChangeText={(value) => securityStore.setPhoneNewCode(value)}
        value={securityStore.phoneNewCode}
      />
      <Spacer />
      <Spacer height={20} />
      <Button
        testID="submit3"
        disabled={!securityStore.newPhone}
        onPress={() => {
          securityStore.phoneChange3().then(() => {
            Alert.alert(
              '',
              t('success_number_change'),
              [{ text: 'OK', onPress: closeDrawer }],
              { cancelable: true },
            )
          })
        }}
      >
        {i18n.t('confirm')}
      </Button>
    </>
  )
})
