import React, { ReactElement, useEffect, useState } from 'react'
import { Image, TouchableOpacity, View } from 'react-native'
import { TextInputMask } from 'react-native-masked-text'
import { colors, constants, translate } from '@pay24/common'
import Icon from './Icon'
import { Input } from './Input'
import { observer } from 'mobx-react'
import { Description, Text } from './Text'
import Picker from './Picker'

const { useTranslation } = translate

interface PhoneInputProps {
  model?: any
  name?: string
  label: string
  disabled?: boolean
  flag?: boolean
  onChangeText?: (any) => void
  value?: string
  inputStyle?: any
  containerStyle?: any
  rightComponent?: ReactElement
  labelStyle?: any
  autoFocus?: boolean
  testID?: string
  getCode?: (val) => void
  defaultCountryCode?: string
  countryCode?: string
  setCountryCode?: (code: string) => void
  countryCodeDisabled?: boolean
  [x: string]: any
}

const getPhoneInfo = (phoneFullNumber, defaultCountryCode = 'kg') => {
  const findItem = Object.entries(constants.PHONE_CODES).find((item) => {
    return phoneFullNumber.indexOf(item[1].code) === 0
  }) || [
    defaultCountryCode,
    { code: constants.PHONE_CODES[defaultCountryCode].code },
  ]
  return {
    countryCode: findItem[0],
    phone: phoneFullNumber.replace(findItem[1].code, ''),
  }
}

export default observer(function PhoneInput({
  model = '',
  name = '',
  label,
  disabled = false,
  flag = true,
  onChangeText,
  value,
  inputStyle = {},
  containerStyle = {},
  rightComponent,
  labelStyle = {},
  autoFocus = false,
  testID,
  getCode,
  inputRequired = false,
  defaultCountryCode = 'kg',
  countryCode,
  setCountryCode,
  countryCodeDisabled,
  ...rest
}: PhoneInputProps) {
  const [show, setVisible] = useState(false)
  const [code, setCode] = useState(defaultCountryCode)
  const { t } = useTranslation()
  if (model && name) {
    model[name] = value
  }
  const phoneInfo = constants.PHONE_CODES[countryCode || code]
  const toggle = () => setVisible((visible) => !visible)
  const _onChange = (val) => {
    if (onChangeText) {
      onChangeText(val.replace(/\D/g, ''))
    }
    if (model && name) {
      model[name] = val
    }
  }
  useEffect(() => {
    const phoneDetailInfo = getPhoneInfo(value, getPhoneInfo(value).countryCode)
    setCode(phoneDetailInfo.countryCode)
  }, [value])

  const renderInput = (props) => {
    return (
      <TextInputMask
        testID={testID}
        disabled={disabled}
        type="custom"
        options={{ mask: phoneInfo.mask }}
        keyboardType={'phone-pad'}
        autoFocus={autoFocus}
        placeholder={phoneInfo.placeholder}
        placeholderTextColor={colors.metal}
        {...props}
      />
    )
  }

  return (
    <>
      <Input
        testID={testID + '_phone'}
        label={t(label) + (inputRequired ? ' *' : '')}
        labelStyle={labelStyle}
        value={
          !value ? '' : String(getPhoneInfo(value).phone).replace(code, '')
        }
        disabled={disabled}
        inputStyle={[{ width: '100%' }, inputStyle]}
        containerStyle={containerStyle}
        clearable={!rightComponent}
        rightComponent={rightComponent}
        leftComponent={
          <TouchableOpacity
            testID={'country_code_' + testID}
            style={{ flexDirection: 'row', alignItems: 'center' }}
            onPress={toggle}
            disabled={countryCodeDisabled}
          >
            {flag && (
              <>
                <Image
                  testID={
                    'country_flag_' + phoneInfo.flag.slice(0, 2).toUpperCase()
                  }
                  style={{ width: 25, height: 25, borderRadius: 3 }}
                  source={{
                    uri: `https://agent.pay24.asia/img/common/${phoneInfo.flag}`,
                  }}
                />
                <Icon name="menu-down" size={20} color={inputStyle?.color} />
              </>
            )}
            <Text style={[{ fontSize: 16 }, inputStyle]}>{phoneInfo.code}</Text>
          </TouchableOpacity>
        }
        onChangeText={_onChange}
        placeholder={phoneInfo.placeholder}
        keyboardType="phone-pad"
        inputComponent={renderInput}
        {...rest}
      />

      <Picker
        show={show}
        items={constants.PHONE_COUNTRIES}
        title={t('country')}
        valueName={'country'}
        renderItem={(item) => (
          <View
            testID={'country_code_' + item.code.toUpperCase()}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            <Image
              style={{
                width: 25,
                height: 25,
                marginRight: 10,
                borderRadius: 3,
              }}
              source={{
                uri: `https://agent.pay24.asia/img/common/${item.flag}`,
              }}
            />
            <Text style={{ flex: 1 }}>{t(item.country)}</Text>
            <Description>
              {constants.PHONE_CODES[item.code] &&
                constants.PHONE_CODES[item.code].code}
            </Description>
          </View>
        )}
        onClose={toggle}
        onChange={(val) => {
          if (setCountryCode) {
            setCountryCode(val.code)
          } else {
            setCode(val.code)
          }
          if (getCode) {
            getCode(
              constants.PHONE_CODES[val.code] &&
                constants.PHONE_CODES[val.code].code,
            )
          }
          if (onChangeText) {
            onChangeText('')
          }
        }}
      />
    </>
  )
})
