import { colors } from '@pay24/common'
import { FormCheck, FormDone, FormFail, Pay3D } from './DefaultTemplate'
import { requisiteScheme } from './schemes'
import { amountPlaceholder } from './utils'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { PaymentStore } from '@pay24/common/src/store'
import { Button } from '../Button'
import DatePicker from '../DatePicker'
import { InputModel } from '../Input'
import * as xml2js from 'react-native-xml2js'
import ListPicker from '../ListPicker'
import Spacer from '../Spacer'
import { Text } from '../Text'
import TextAutocomplete from '../TextAutocomplete'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from '@pay24/common/src/translate'

const FormInitial = observer((props) => {
  const { service, source, valid_fields, comments } = props
  const { payData, isAgent } = PaymentStore
  const [phase, setPhase] = useState(false)
  const [documents, setDocuments] = useState([])
  const fields = service.data && service.data.fields
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      payData,
      isAgent,
      source,
    },
    enableReinitialize: true,
    validationSchema: requisiteScheme(service, valid_fields),
    onSubmit: (values) => {
      payData.setValue('service_id', service.id)
      payData.setValue('service_code', service.type)
      payData.setValue('service_name', service.name)
      payData.setValue('form_scheme', service?.data?.form_scheme)
      PaymentStore.requisiteCheck(values.source).then((r) => {
        let body = '<body>' + r.params.count_contract_num + '</body>'
        xml2js.parseString(body, (err, res) => {
          if (res.body.loan_list.length > 1) {
            setDocuments(res.body.loan_list)
            setPhase(true)
            PaymentStore.setStep(2)
          } else {
            PaymentStore.setPaymentState(FORM.CHECK)
            PaymentStore.setStep(3)
          }
        })
      })
    },
  })

  const getDocuments = () => {
    const items: any[] = []
    let count = 1
    if (documents && documents.length > 0) {
      documents.forEach((d: any) => {
        items.push({ id: count, name: d.loan[0].loan_number[0] })
      })
    }
    return items
  }

  return !phase ? (
    <>
      {!PaymentStore.isAgent && formik.errors.source ? (
        <Text
          style={{
            color: colors.red,
            marginTop: 3,
            marginLeft: 3,
            fontSize: 10,
          }}
        >
          {formik.errors.source}
        </Text>
      ) : null}
      <Spacer />
      <InputModel
        testID="requisite"
        model={payData}
        name={'requisite'}
        errorText={formik.errors.payData ? formik.errors.payData.requisite : ''}
        label={service?.data?.requisite_message || 'requisite'}
        keyboardType={'phone-pad'}
        placeholder={service?.placeholder}
      />
      <Spacer />
      <DatePicker
        testID="select_date"
        label={fields[0].label.replace(/:$/, '')}
        value={payData.getOptions(fields[0].name)}
        onChange={(val) => {
          PaymentStore.setOption(fields[0].name, val?.toString())
        }}
      />
      <Spacer />
      <InputModel
        testID="amount"
        label="sum"
        keyboardType="numeric"
        name="amount"
        value={formik.values.payData.amount?.toString()}
        errorText={formik.errors.payData ? formik.errors.payData.amount : ''}
        model={formik.values.payData}
        placeholder={amountPlaceholder(service)}
      />
      <Spacer />
      <TextAutocomplete
        testID="comment"
        model={formik.values.payData}
        name="comment"
        label="comment"
        placeholder="optional"
        options={comments}
      />
      <Spacer />
      <Button
        testID="nextBtn"
        disabled={PaymentStore.buttonBlock}
        onPress={() => {
          formik.handleSubmit()
        }}
      >
        {t('next')}
      </Button>
      <Spacer />
    </>
  ) : (
    <>
      <Spacer />
      <ListPicker
        testID="select_number_agreement"
        items={getDocuments()}
        label={'Выбрать номер договора'}
        value={payData.getOptions(fields[1].name)}
        onChange={(val) => {
          PaymentStore.setOption(fields[1].name, val.name)
        }}
        onClearPress={() => {
          PaymentStore.deleteOption(fields[1].name)
        }}
      />
      <Spacer />
      <Button
        testID="nextBtn"
        disabled={PaymentStore.buttonBlock}
        onPress={() => {
          PaymentStore.requisiteCheck(source).then(() => {
            PaymentStore.setPaymentState(FORM.CHECK)
            PaymentStore.setStep(3)
          })
        }}
      >
        {t('next')}
      </Button>
    </>
  )
})

export default (isAgent) => {
  if (isAgent) {
    return {
      steps: 4,
      [FORM.INITIAL]: FormInitial,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormDone,
      [FORM.FAIL]: FormFail,
    }
  }
  return {
    steps: 5,
    [FORM.INITIAL]: FormInitial,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
