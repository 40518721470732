import { colors, constants, store, translate } from '@pay24/common'
import { isCharge24 } from '@pay24/common/src/utils'
import { Text } from './Text'
import { MenuItem as KittenMenuItem } from '@ui-kitten/components/ui/menu/menuItem.component'
import React from 'react'
import { CustomDropdown } from './Components'
import Image from './Image'

const { useTranslation } = translate
const { RootStore } = store

export const LangSwitcher = () => {
  const { t } = useTranslation()
  const cLang = constants.LANGUAGES.find((l) => l.code === RootStore.language)
  const languages = constants.LANGUAGES.map((item, index) => {
    if (index === 0 && isCharge24) {
      return constants.LANGUAGES[1]
    } else if (index === 1 && isCharge24) {
      return constants.LANGUAGES[0]
    }
    return item
  })
  return (
    <CustomDropdown
      textColor={colors.metal}
      title={cLang?.lang.toUpperCase() || 'RU'}
      noArrow
    >
      {languages.map((item) => (
        <KittenMenuItem
          key={item.code}
          title={<Text>{t(item.name)}</Text>}
          accessoryLeft={
            <Image
              source={{
                uri: `https://pay24.asia/api/files/get?bucket=common&filename=${item.flag}`,
              }}
            />
          }
          onPress={() => RootStore.setLanguage(item.code)}
        />
      ))}
    </CustomDropdown>
  )
}
