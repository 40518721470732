import { store } from '@pay24/common'
import React from 'react'
import { View } from 'react-native'
import Frame3D from './Frame3D'

const { RootStore, PaymentStore } = store

export default (props) => {
  const { payData } = PaymentStore
  let url = props.url || payData.payload?.url
  const obj = props.params || payData.payload?.params || {}
  let params = Object.assign({}, obj)
  if (!url) return null
  if (RootStore.token) {
    params.auth = RootStore.token
  }
  console.log({ url, params, token: RootStore.token })
  return (
    <View style={{ paddingVertical: 10 }}>
      <Frame3D
        url={url}
        params={params}
        onSuccess={props.onSuccess}
        onFail={props.onFail}
      />
    </View>
  )
}
