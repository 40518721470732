import { useTranslation } from '@pay24/common/src/translate'
import { FormCheck, FormDone, FormFail, Pay3D } from './DefaultTemplate'
import { FormInitial } from './DefaultPassportTemplate'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { PaymentStore } from '@pay24/common/src/store'
import { Input } from '../Input'
import Spacer from '../Spacer'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import * as yup from 'yup'

const FormBase = observer((props) => {
  const { service, source } = props
  const { t } = useTranslation()
  const [f, setF] = useState<string>('')
  const [i, setI] = useState<string>('')

  const add_param_valid_fields = {
    f: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Выберите латинский язык',
      )
      .required(t('required_last_name')),
    i: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Выберите латинский язык',
      )
      .required(t('required_name')),
    o: yup
      .string()
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Выберите латинский язык',
      ),
  }

  const renderFields = (options_errors, add_params_errors) => {
    const fields = service.data && service.data.fields
    if (!fields || fields.length === 0 || Object.keys(fields).length === 0) {
      return null
    }

    const fio_uzcard = fields.find((value) => value.name === 'fio_uzcard')

    PaymentStore.setOption(fio_uzcard.name, f?.concat(' ', i, ' '))

    return (
      <>
        <Input
          testID="last_name"
          errorText={add_params_errors?.f}
          label={'last_name'}
          value={f}
          onChangeText={(val) => {
            setF(val)
          }}
        />
        <Spacer />
        <Input
          testID="name"
          errorText={add_params_errors?.i}
          label={'name'}
          value={i}
          onChangeText={(val) => {
            setI(val)
          }}
        />
        <Spacer />
      </>
    )
  }

  return (
    <FormInitial
      {...props}
      source={source}
      customFields={renderFields}
      add_params={{ f, i }}
      add_param_valid_fields={add_param_valid_fields}
    />
  )
})

export default (isAgent) => {
  if (isAgent) {
    return {
      steps: 4,
      [FORM.INITIAL]: FormBase,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormDone,
      [FORM.FAIL]: FormFail,
    }
  }
  return {
    steps: 5,
    [FORM.INITIAL]: FormBase,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
