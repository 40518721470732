import { hooks } from '@pay24/common'
import { observer } from 'mobx-react'
import React, { ReactNode } from 'react'
import { StyleSheet, TextInput, TextInputProps } from 'react-native'
import { Input } from './Input'

const { useTheme } = hooks

export interface TEditable<T> extends Record<any, any> {
  setValue(name: keyof T | undefined, value: any): void
}

export interface ModelNameProps<T extends TEditable<T>> {
  model?: T
  name?: keyof T
}

export function isModelName<T extends TEditable<T>, P = ModelNameProps<T>>(
  props: P | any,
): props is P {
  return 'model' in props
}

export interface NativeInputProps<T extends TEditable<T>>
  extends ModelNameProps<T>,
    TextInputProps {
  outline?: boolean
  clearable?: boolean
  errorText?: string
  label?: string
  leftComponent?: ReactNode
}

export function isNativeEditable<T extends TEditable<T>>(
  props: NativeInputProps<T> | TextInputProps,
): props is NativeInputProps<T> {
  return isModelName<T, NativeInputProps<T>>(props)
}

const NativeInput = observer(function NativeInput<T extends TEditable<T>>(
  props: NativeInputProps<T> | TextInputProps,
) {
  let textInputProps
  if (isModelName(props)) {
    const { model, name, ...rest } = props
    textInputProps = rest
  } else {
    textInputProps = props
  }

  const { value, onChangeText, style } = props

  let _value = value
  if (isNativeEditable(props)) {
    const { model, name } = props
    if (model && name) {
      _value = model[name]?.toString() || ''
    }
  }

  const _onChangeText = (v: string) => {
    if (isNativeEditable(props)) {
      const { model, name } = props
      model?.setValue(name, v)
    }
    onChangeText && onChangeText(v)
  }

  const theme = useTheme()

  const _style = [
    styles.nativeInput,
    { color: theme['text-basic-color'] },
    style,
  ]

  if (textInputProps.outline) {
    return (
      <Input
        {...textInputProps}
        label={textInputProps.label}
        value={_value}
        onChangeText={_onChangeText}
      />
    )
  }

  return (
    <TextInput
      {...textInputProps}
      value={_value}
      onChangeText={_onChangeText}
      style={_style}
    />
  )
})

export default NativeInput

const styles = StyleSheet.create({
  nativeInput: {
    fontSize: 14,
  },
})
