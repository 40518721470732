import { colors } from '@pay24/common'
import { useTranslation } from '@pay24/common/src/translate'
import { FormCheck, FormDone, FormFail, Pay3D } from './DefaultTemplate'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { PaymentStore } from '@pay24/common/src/store'
import { Button } from '../Button'
import { InputModel } from '../Input'
import ListPicker from '../ListPicker'
import Spacer from '../Spacer'
import { Text } from '../Text'
import TextAutocomplete from '../TextAutocomplete'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import * as xml2js from 'react-native-xml2js'
import * as yup from 'yup'

const FormInitial = observer((props) => {
  const { service, source, comments } = props
  const { payData, isAgent } = PaymentStore
  const [phase, setPhase] = useState(false)
  const [documents, setDocuments] = useState([])

  const { t } = useTranslation()

  const fields = service.data && service.data.fields
  let amountPlaceholder = service.min_amount ? `от ${service.min_amount}` : ''
  amountPlaceholder =
    amountPlaceholder + (service.max_amount ? ` до ${service.max_amount} ` : '')

  const schema = yup.object().shape({
    payData: yup.object({
      requisite: yup.string().required(t('required_requisite')),
      amount: yup
        .number()
        .min(service.min_amount)
        .max(service.max_amount)
        .typeError(t('required_amount'))
        .required(t('required_amount')),
      // options: yup.object(dynamicSchema())
    }),
    isAgent: yup.boolean(),
    source: yup
      .object()
      .nullable()
      .when('isAgent', {
        is: (value) => value === false,
        then: yup.object().nullable().required(t('required_source')),
      }),
  })

  const formik = useFormik({
    initialValues: {
      payData,
      isAgent,
      source,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: () => {
      payData.setValue('service_id', service.id)
      payData.setValue('service_code', service.type)
      payData.setValue('service_name', service.name)
      payData.setValue('form_scheme', service?.data?.form_scheme)
      payData.setOptions(fields[2].name, '')
      payData.setOptions(fields[3].name, '')
      payData.setOptions(fields[4].name, '')
      PaymentStore.requisiteCheck(source).then((r) => {
        let body = '<body>' + r.params.ActiveLoans + '</body>'
        xml2js.parseString(body, (err, res) => {
          if (res.body.Loans.length > 1) {
            setDocuments(res.body.Loans)
            setPhase(true)
            PaymentStore.setStep(2)
          } else {
            PaymentStore.setPaymentState(FORM.CHECK)
            PaymentStore.setStep(3)
          }
        })
      })
    },
  })

  const getDocuments = () => {
    const items: any[] = []
    let count = 1
    if (documents && documents.length > 0) {
      documents.forEach((d: any) => {
        items.push({
          id: count,
          name:
            d.LoanNumber[0] +
            '\n' +
            d.Product[0] +
            '\n' +
            'Осталось: ' +
            d.TotalToCloseAmount,
        })
      })
    }
    return items
  }

  return !phase ? (
    <>
      {!PaymentStore.isAgent && formik.errors.source ? (
        <Text
          style={{
            color: colors.red,
            marginTop: 3,
            marginLeft: 3,
            fontSize: 10,
          }}
        >
          {formik.errors.source}
        </Text>
      ) : null}
      <InputModel
        testID="requisite"
        model={payData}
        name={'requisite'}
        errorText={formik.errors.payData ? formik.errors.payData.requisite : ''}
        label={service?.data?.requisite_message || 'requisite'}
        placeholder={service?.placeholder}
      />
      <Spacer />
      <InputModel
        testID="amount"
        label="sum"
        keyboardType="numeric"
        name="amount"
        value={formik.values.payData.amount?.toString()}
        errorText={formik.errors.payData ? formik.errors.payData.amount : ''}
        model={formik.values.payData}
        placeholder={amountPlaceholder}
      />
      <TextAutocomplete
        testID="comment"
        model={formik.values.payData}
        name="comment"
        label="comment"
        placeholder="optional"
        options={comments}
      />
      <Spacer />
      <Button
        testID="nextBtn"
        disabled={PaymentStore.buttonBlock}
        onPress={() => {
          formik.handleSubmit()
        }}
      >
        {t('next')}
      </Button>
      <Spacer />
    </>
  ) : (
    <>
      <ListPicker
        testID="select_fields"
        items={getDocuments()}
        label={fields[0].label}
        value={payData.getOptions(fields[0].name)}
        onChange={(val) => {
          let values = val.name.split('\n')
          PaymentStore.setOption(fields[0].name, values[0])
          PaymentStore.setOption('credit_name', values[1])
        }}
      />
      <Spacer />
      <Button
        testID="nextBtn"
        disabled={PaymentStore.buttonBlock}
        onPress={() => {
          PaymentStore.requisiteCheck(source).then(() => {
            PaymentStore.setPaymentState(FORM.CHECK)
            PaymentStore.setStep(3)
          })
        }}
      >
        {t('next')}
      </Button>
    </>
  )
})

export default (isAgent) => {
  if (isAgent) {
    return {
      steps: 4,
      [FORM.INITIAL]: FormInitial,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormDone,
      [FORM.FAIL]: FormFail,
    }
  }
  return {
    steps: 5,
    [FORM.INITIAL]: FormInitial,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
