import { colors } from '@pay24/common'
import { FormCheck, FormDone, FormFail, Pay3D } from './DefaultTemplate'
import { requisiteScheme } from './schemes'
import { amountPlaceholder, getItems } from './utils'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { PaymentStore } from '@pay24/common/src/store'
import { Button } from '../Button'
import { Input, InputModel } from '../Input'
import ListPicker from '../ListPicker'
import Spacer from '../Spacer'
import { Text } from '../Text'
import TextAutocomplete from '../TextAutocomplete'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import React from 'react'
import { View } from 'react-native'
import * as yup from 'yup'
import { useTranslation } from '@pay24/common/src/translate'

const FormInitial = observer((props) => {
  const { service, source, comments } = props
  const { payData, isAgent } = PaymentStore
  const { t } = useTranslation()
  const options = getSnapshot<any>(payData.options)
  const fields =
    service.data &&
    service.data.fields.filter(
      (f) =>
        f.name !== 'check_number' &&
        f.name !== 'terminal_number' &&
        f.name !== 'fio',
    )

  let valid_fields: { [k: string]: any } = {}
  if (fields.find((f) => f.name_for_code === 'currency_code')) {
    valid_fields.currency_code = yup.string().required('Выберите валюту')
  }
  if (fields.find((f) => f.name === 'month')) {
    valid_fields.month = yup.string().required('Выберите месяц')
  }
  if (fields.find((f) => f.name === 'year')) {
    valid_fields.year = yup.string().required('Выберите год')
  }

  const formik = useFormik({
    initialValues: {
      payData,
      options,
      isAgent,
      source,
    },
    enableReinitialize: true,
    validationSchema: requisiteScheme(service, valid_fields),
    onSubmit: (values) => {
      payData.setValue('service_id', service.id)
      payData.setValue('service_code', service.type)
      payData.setValue('service_name', service.name)
      payData.setValue('form_scheme', service?.data?.form_scheme)
      PaymentStore.requisiteCheck(values.source).then((resp) => {
        if (service.data.fields.find((f) => f.name === 'terminal_number')) {
          payData.setOptions('terminal_number', '00000')
        }
        if (service.data.fields.find((f) => f.name === 'check_number')) {
          payData.setOptions(
            'check_number',
            resp?.request_id
              ? resp?.request_id
              : Math.floor(10000000 + Math.random() * 90000000),
          )
        }
        if (service.data.fields.find((f) => f.name === 'fio')) {
          payData.setOptions(
            'fio',
            resp?.extra ? resp?.extra?.fio : resp?.params?.fio,
          )
        }
        PaymentStore.setPaymentState(FORM.CHECK)
        PaymentStore.setStep(2)
      })
    },
  })

  const renderFields = (_fields, errors) => {
    if (!_fields || _fields.length === 0 || Object.keys(_fields).length === 0)
      return null
    const renderField = (f, i) => {
      if (f.type === 'select') {
        let flag = f.name_for_code
        return (
          <ListPicker
            testID={`SelectComponent${i}`}
            items={getItems(f.data, flag)}
            key={i}
            errorText={errors?.[f.name_for_code]}
            label={f.label}
            value={getValue(f.name_for_code, f.name_for_text)}
            disabled={!getItems(f.data, flag).length}
            onChange={(val) => {
              PaymentStore.setOption(f.name_for_code, val.id)
              PaymentStore.setOption(f.name_for_text, val.name)
            }}
            onClearPress={() => {
              PaymentStore.setOption(f.name_for_code, null)
              PaymentStore.setOption(f.name_for_text, null)
            }}
          />
        )
      }
      return (
        <Input
          testID={`type_text${i}`}
          key={i}
          errorText={errors?.[f.name]}
          label={f.label?.replace(/:$/, '')}
          value={payData.options ? payData.getOptions(f.name) : ''}
          onChangeText={(val) => {
            PaymentStore.setOption(f.name, val)
          }}
        />
      )
    }

    return fields.map((f, i) => {
      return (
        <View key={i}>
          {renderField(f, i)}
          <Spacer />
        </View>
      )
    })
  }

  const getValue = (key, value) => {
    if (!payData.options) return null
    const k = payData.getOptions(key)
    const v = payData.getOptions(value)
    return { id: k, name: v }
  }

  return (
    <>
      {!PaymentStore.isAgent && formik.errors.source ? (
        <Text
          style={{
            color: colors.red,
            marginTop: 3,
            marginLeft: 3,
            fontSize: 10,
          }}
        >
          {formik.errors.source}
        </Text>
      ) : null}
      <Spacer />
      <Input
        testID="requisite"
        errorText={formik.errors.payData ? formik.errors.payData.requisite : ''}
        label={'requisite'}
        keyboardType={'phone-pad'}
        inputMask={service?.placeholder?.replace(/_/g, '9')}
        placeholder={service?.placeholder}
        value={payData.requisite}
        onChangeText={(val) => payData.setValue('requisite', val)}
      />
      <Spacer />
      {renderFields(fields, formik.errors.options)}
      <InputModel
        testID="amount"
        label="sum"
        keyboardType="numeric"
        name="amount"
        value={formik.values.payData.amount?.toString()}
        errorText={formik.errors.payData ? formik.errors.payData.amount : ''}
        model={formik.values.payData}
        placeholder={amountPlaceholder(service)}
      />
      <Spacer />
      <TextAutocomplete
        testID="comment"
        model={formik.values.payData}
        name="comment"
        label="comment"
        placeholder="optional"
        options={comments}
      />
      <Spacer />
      <Button
        testID="nextBtn"
        disabled={PaymentStore.buttonBlock}
        onPress={() => {
          formik.handleSubmit()
        }}
      >
        {t('next')}
      </Button>
    </>
  )
})

export default (isAgent) => {
  if (isAgent) {
    return {
      steps: 3,
      [FORM.INITIAL]: FormInitial,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormDone,
      [FORM.FAIL]: FormFail,
    }
  }
  return {
    steps: 4,
    [FORM.INITIAL]: FormInitial,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
