import { useResponsive } from '@pay24/common/src/hooks'
import { amountFormatCurrency, isWeb } from '@pay24/common/src/utils'
import { Button } from '@ui-kitten/components/ui'
import React, { forwardRef, LegacyRef, MutableRefObject, useRef } from 'react'
import { View } from 'react-native'
import { useReactToPrint } from 'react-to-print'
import Modal from './Modal'
import { Text } from './Text'
import { useTranslation } from '@pay24/common/src/translate'
import { colors } from '@pay24/common'

export default ({ visible, onClose, item }) => {
  const componentRef: MutableRefObject<any> | null = isWeb ? useRef() : null
  const { sm, screenWidth, screenHeight } = useResponsive()
  const { t } = useTranslation()
  const handlePrint = isWeb
    ? useReactToPrint({
        content: () => componentRef?.current,
      })
    : null

  if (!visible) return null

  let style
  if (sm) {
    style = {
      minWidth: 300,
      maxWidth: 800,
      backgroundColor: colors.lightGrey,
    }
  } else {
    style = {
      width: screenWidth,
      height: screenHeight,
      borderWidth: 0,
      borderRadius: 0,
      backgroundColor: colors.lightGrey,
    }
  }

  if (item) {
    return (
      <Modal
        visible={visible}
        onClose={onClose}
        title={'preview_cheque'}
        style={style}
        titleColor={colors.black}
        iconColor={colors.black}
      >
        <ChequeContent cheque={item} ref={componentRef} />
        {isWeb ? (
          <View style={{ paddingHorizontal: 20 }}>
            <Button onPress={() => handlePrint && handlePrint()}>
              {t('print')}
            </Button>
          </View>
        ) : null}
      </Modal>
    )
  }
  return <View />
}

interface ChequeProps {
  paymentCommission: number
  acquiringCommission: number
  commission: number
  customer_name: string
  requisite: string
  amount: number
  name: string
  comment: string
  totalAmount: number
}

interface ChequeContentProps {
  cheque: ChequeProps
}

const ChequeContent = forwardRef(
  ({ cheque }: ChequeContentProps, ref: LegacyRef<View>) => {
    const { sm } = useResponsive()
    const { t } = useTranslation()
    const renderItem = (title, value) => {
      return (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text color={colors.black}>{title}:</Text>
          <Text color={colors.black} style={{ textAlign: 'right' }}>
            {value}
          </Text>
        </View>
      )
    }

    return (
      <View
        ref={ref}
        style={{
          width: sm ? 320 : '100%',
          backgroundColor: colors.lightGrey,
        }}
      >
        <>
          <Text
            size={10}
            numberOfLines={10}
            color={colors.black}
            style={{
              textAlign: 'right',
              paddingHorizontal: 20,
            }}
          >
            {t('llc')}
          </Text>

          <View style={{ padding: 20 }}>
            {renderItem(t('subscriber'), cheque.customer_name)}
            {renderItem(t('requisite'), cheque.requisite)}
            {renderItem(t('amount'), amountFormatCurrency(cheque.amount))}
            {renderItem(
              'Комиссия платежа',
              amountFormatCurrency(cheque.paymentCommission),
            )}
            {renderItem('К списанию', amountFormatCurrency(cheque.totalAmount))}

            {cheque.comment ? (
              <>
                <Text color={colors.black}>{t('comment')}:</Text>
                <Text color={colors.black}>{cheque.comment}</Text>
              </>
            ) : null}
          </View>
        </>
      </View>
    )
  },
)
