import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useTheme } from '@pay24/common/src/hooks'
import Icon from './Icon'
import { colors } from '@pay24/common'

export const ThemeSwitcher = ({ onPress }: { onPress?: () => void }) => {
  const { isLight, toggleTheme } = useTheme()

  return (
    <TouchableOpacity
      onPress={() => {
        onPress && onPress()
        toggleTheme()
      }}
    >
      <Icon
        size={20}
        name={isLight ? 'weather-night' : 'weather-sunny'}
        color={colors.metal}
      />
    </TouchableOpacity>
  )
}
