import React from 'react'
import { ItemContainer } from '../Components'

const sandBoxAllows =
  'allow-forms allow-scripts allow-modals ' +
  'allow-orientation-lock allow-pointer-lock allow-popups' +
  ' allow-popups-to-escape-sandbox allow-same-origin allow-presentation allow-scripts'

export default class Frame3D extends React.Component {
  frame = React.createRef()

  shouldComponentUpdate({ url, params }) {
    return (
      this.props.url !== url &&
      JSON.stringify(this.props.params) === JSON.stringify(params)
    )
  }

  componentDidMount() {
    if (!this.frame.current) return
    const { current } = this.frame
    let { url, params, method } = this.props
    let doc = current.contentDocument
    let form = document.createElement('form')
    form.setAttribute('method', method || 'get')
    let [path, ...rest] = url.split('?')
    form.setAttribute('action', path)

    const addHidden = (key, value) => {
      let hiddenField = document.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('name', key)
      hiddenField.setAttribute('value', value)
      form.appendChild(hiddenField)
    }
    rest.forEach((p) =>
      p.split('&').forEach((q) => {
        const [key, ...v] = q.split('=')
        key && addHidden(key, v.join('='))
      }),
    )

    params && Object.keys(params).forEach((key) => addHidden(key, params[key]))

    doc.body.appendChild(form)
    form.submit()
    current.scrollIntoView()
  }

  onLoad = (e) => {
    try {
      let location = e.target.contentWindow.location
      let params = new URLSearchParams(location.search)
      if (params.get('status') === 'fail') {
        this.props.onFail && this.props.onFail(location.search)
      } else if (params.get('status') === 'success') {
        this.props.onSuccess && this.props.onSuccess(location.search)
      }
    } catch (e) {
      console.warn(e)
    }
  }

  render() {
    return (
      <ItemContainer>
        <iframe
          sandbox={sandBoxAllows}
          ref={this.frame}
          onLoad={this.onLoad}
          id="s3d"
          scrolling="yes"
          width="100%"
          height="500"
          frameBorder="0"
        />
      </ItemContainer>
    )
  }
}
