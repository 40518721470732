// Общая форма
import { colors } from '@pay24/common'
import { PaymentStore } from '@pay24/common/src/store'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { useTranslation } from '@pay24/common/src/translate'
import { isMobile } from '@pay24/common/src/utils'
import { MenuItem } from '@ui-kitten/components'
import axios from 'axios'
import { FormikProps, useFormik } from 'formik'
import { observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import React, { useState } from 'react'
import { View } from 'react-native'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { Button } from '../Button'
import { DotsDropdown } from '../Components'
import ContactPicker from '../ContactPicker'
import { Input, InputModel } from '../Input'
import Spacer from '../Spacer'
import { Text } from '../Text'
import TextAutocomplete from '../TextAutocomplete'
import { FormCheck, FormDone, FormFail, Pay3D } from './DefaultTemplate'
import { FormBaseValues } from './interfaces'
import { receiverScheme, requisiteScheme } from './schemes'
import { amountPlaceholder, categoryId, maskOfInput } from './utils'

const FormBase = observer((props) => {
  const { t } = useTranslation()
  const {
    service,
    source,
    paymentStore,
    customFields,
    valid_fields,
    add_params,
    add_param_valid_fields,
    comments,
    navigation,
    selectRequisite,
    selectComment,
  } = props
  const { payData, isAgent } = paymentStore
  const options = getSnapshot<any>(payData.options)
  const [visible, setVisible] = useState(false)

  if (service.data?.form_scheme === 'residue_with_fio') {
    payData.setValue('amount', 100)
  }

  let valid_fields_concat: any
  if (valid_fields) {
    valid_fields_concat = valid_fields
    valid_fields_concat.explanation = yup
      .string()
      .required(t('required_explanation'))
      .nullable()
  } else {
    valid_fields_concat = {
      explanation: yup.string().required(t('required_explanation')).nullable(),
    }
  }

  const formik: FormikProps<FormBaseValues> = useFormik<FormBaseValues>({
    initialValues: {
      options,
      payData,
      isAgent,
      source,
      add_params,
    },
    enableReinitialize: true,
    validationSchema: requisiteScheme(
      service,
      valid_fields_concat,
      add_param_valid_fields,
      selectRequisite,
    ),
    onSubmit: (values, { setErrors }) => {
      const should_validate =
        categoryId(service) !== 1 && !service.placeholder?.trim()
      if (should_validate) {
        const validate = new RegExp(`^${service.validation}$`)
        if (!validate.test(payData.requisite)) {
          setErrors({
            payData: { requisite: t('check_your_requisite') },
          })
          return
        }
      }
      const data = {
        system_code: 'APP',
        object_id: uuidv4(),
        first_name: '',
        last_name: '',
        patronymic: null,
        pin: '',
        word: formik.values.options?.explanation,
        no_mail: true,
      }
      axios
        .post('https://gsfr.pay24.asia/api/check_names_new', data)
        .then((response) => {
          if (response.data.review_result[0].word_status === 7) {
            setErrors({
              options: { explanation: 'Недопустимое назначение платежа' },
            })
          } else {
            payData.setValue('service_id', service.id)
            payData.setValue('service_code', service.type)
            payData.setValue('service_name', service.name)
            paymentStore.autoPassportFill()
            paymentStore.setStep(2)
          }
        })
    },
  })

  const templateSum = [50, 100, 200, 500, 1000]
  const placeholder = service?.placeholder || ''
  return (
    <>
      {!paymentStore.isAgent && formik.errors.source ? (
        <Text
          style={{
            color: colors.red,
            marginTop: 3,
            marginLeft: 3,
            fontSize: 10,
          }}
        >
          {formik.errors.source}
        </Text>
      ) : null}
      <Spacer />
      {selectRequisite ? (
        <>
          <InputModel
            testID="requisite"
            model={payData}
            name={'requisite'}
            value={payData?.requisite}
            errorText={
              formik.errors.payData ? formik.errors.payData.requisite : ''
            }
            label={service?.data?.requisite_message || 'requisite'}
            inputMask={
              categoryId(service) === 1
                ? maskOfInput(service)?.mask
                : placeholder.replace(/ /g, '') !== ''
                ? placeholder.replace(/_/g, '*')
                : ''
            }
            keyboardType={categoryId(service) === 1 ? 'phone-pad' : 'default'}
            placeholder={
              categoryId(service) === 1
                ? maskOfInput(service)?.placeholder
                : service?.placeholder
            }
            rightComponent={
              <DotsDropdown placement={undefined}>
                {isMobile && categoryId(service) === 1 ? (
                  <MenuItem
                    title={<Text>Из контактов</Text>}
                    onPress={() => setVisible(true)}
                  />
                ) : null}
                <MenuItem
                  title={<Text>{t('from_favorites')}</Text>}
                  onPress={() => {
                    navigation.navigate('favorites', {
                      service_id: service.id,
                    })
                  }}
                />
              </DotsDropdown>
            }
          />
          <Spacer />
        </>
      ) : null}
      {customFields &&
        customFields(formik.errors.options, formik.errors.add_params)}
      {service.data?.form_scheme !== 'residue_with_fio' && (
        <>
          <InputModel
            testID="moneyAmount"
            label="sum"
            keyboardType="numeric"
            name="amount"
            value={formik.values.payData.amount?.toString()}
            errorText={
              formik.errors.payData ? formik.errors.payData.amount : ''
            }
            model={formik.values.payData}
            placeholder={amountPlaceholder(service)}
            rightComponent={
              <DotsDropdown placement={undefined}>
                {templateSum.map((sum) => (
                  <MenuItem
                    key={sum}
                    title={<Text>{sum}</Text>}
                    onPress={() => payData.setValue('amount', sum)}
                  />
                ))}
              </DotsDropdown>
            }
          />
          <Spacer />
        </>
      )}
      <View>
        <Input
          testID="explanation"
          errorText={
            formik.errors.options ? formik.errors.options?.explanation : ''
          }
          label={'Назначение'}
          value={payData.getOptions('explanation')}
          onChangeText={(val) => {
            paymentStore.payData.setOptions('explanation', val)
          }}
        />
        <Spacer />
      </View>
      {selectComment ? (
        <>
          <TextAutocomplete
            testID="comment"
            model={formik.values.payData}
            name="comment"
            label="comment"
            placeholder="optional"
            options={comments}
          />
          <Spacer />
        </>
      ) : null}
      <Button
        testID="nextBtn"
        onPress={() => {
          formik.handleSubmit()
        }}
      >
        {t('next')}
      </Button>
      {isMobile && categoryId(service) === 1 ? (
        <ContactPicker
          onClose={() => setVisible(false)}
          show={visible}
          onChange={(val) => payData.setValue('requisite', val)}
        />
      ) : null}
    </>
  )
})

// Форма для получателя
export const FormReceiver = observer((props) => {
  const { source, paymentStore } = props
  const { t } = useTranslation()
  const [f, setF] = useState<string | null>('')
  const [i, setI] = useState<string | null>('')
  const [o, setO] = useState<string>('')
  const { isAgent, payData } = paymentStore

  const formik = useFormik({
    initialValues: {
      source,
      isAgent,
      f,
      i,
    },
    enableReinitialize: true,
    validationSchema: receiverScheme,
    onSubmit: (values) => {
      if (typeof i === 'string') {
        paymentStore.setOption('to_fio', f?.concat(' ', i, ' ', o))
      }
      payData.setValue('requisite', payData.requisite.replace(/\s/g, ''))
      paymentStore.requisiteCheck(values.source).then(() => {
        paymentStore.setPaymentState(FORM.CHECK)
        paymentStore.setStep(3)
      })
    },
  })

  return (
    <>
      {!paymentStore.isAgent && formik.errors.source ? (
        <Text
          style={{
            color: colors.red,
            marginTop: 3,
            marginLeft: 3,
            fontSize: 10,
          }}
        >
          {formik.errors.source}
        </Text>
      ) : null}
      <Spacer />
      <Input
        testID="lastName"
        errorText={formik.errors.f}
        label={'receiver_last_name'}
        value={f}
        onChangeText={(val) => {
          setF(val)
        }}
      />
      <Spacer />
      <Input
        testID="firstName"
        errorText={formik.errors.i}
        label={'receiver_name'}
        value={i}
        onChangeText={(val) => {
          setI(val)
        }}
      />
      <Spacer />
      <Input
        testID="surName"
        label={'receiver_patronymic'}
        value={o}
        onChangeText={(val) => {
          setO(val)
        }}
      />
      <Spacer />
      <Button
        testID="nextBtn"
        disabled={paymentStore.buttonBlock}
        onPress={() => {
          formik.handleSubmit()
        }}
      >
        {t('next')}
      </Button>
      <Spacer />
    </>
  )
})

export const FormInitial = observer((props) => {
  const {
    service,
    source,
    customFields,
    valid_fields,
    add_params,
    add_param_valid_fields,
    comments,
    selectRequisite = true,
    selectComment = true,
  } = props

  return PaymentStore.step === 1 ? (
    <FormBase
      service={service}
      source={source}
      paymentStore={PaymentStore}
      customFields={customFields}
      valid_fields={valid_fields}
      add_params={add_params}
      add_param_valid_fields={add_param_valid_fields}
      comments={comments}
      selectRequisite={selectRequisite}
      selectComment={selectComment}
    />
  ) : PaymentStore.step === 2 ? (
    <FormReceiver
      service={service}
      source={source}
      paymentStore={PaymentStore}
    />
  ) : null
})

export default (isAgent = false) => {
  if (isAgent) {
    return {
      steps: 4,
      [FORM.INITIAL]: FormInitial,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormDone,
      [FORM.FAIL]: FormFail,
    }
  }
  return {
    steps: 5,
    [FORM.INITIAL]: FormInitial,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
