import { colors, hooks, translate, utils } from '@pay24/common'
import { Divider as Hr, MenuItem } from '@ui-kitten/components'
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import {
  Platform,
  SafeAreaView,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native'

import Dropdown from './Dropdown'
import Icon from './Icon'
import Image from './Image'
import { Description, Text, Title } from './Text'

const { composeStyle, isIos, isMobile, isWeb, getStatusColor } = utils

const { useTranslation } = translate
const { useTheme } = hooks

export const ThemedSafeAreaView = ({ style, children, ...rest }: ViewProps) => {
  const theme = useTheme()
  return (
    <SafeAreaView
      {...rest}
      style={[{ backgroundColor: theme['background-basic-color-2'] }, style]}
    >
      {children}
    </SafeAreaView>
  )
}

export const BackgroundView = ({ children, style, ...rest }: ViewProps) => {
  const theme = useTheme()
  return (
    <View
      {...rest}
      style={[{ backgroundColor: theme['background-basic-color-1'] }, style]}
    >
      {children}
    </View>
  )
}

interface ItemContainerProps
  extends ViewProps,
    Omit<TouchableOpacityProps, 'hitSlop'> {
  noBg?: boolean
  testID?: string
}

export const ItemContainer = ({
  children,
  style,
  onPress,
  disabled,
  testID,
  ...rest
}: ItemContainerProps) => {
  const theme = useTheme()

  const layout = (
    <View
      testID={testID}
      // level={noBg ? '1' : isLight ? '3' : '1'}
      //noBg ? '1' : isCharge24 ? (isLight ? '1' : '3') : isLight ? '3' : '1'
      style={composeStyle(
        {
          borderRadius: 8,
          backgroundColor: theme.isLight
            ? theme['background-basic-color-2']
            : theme['background-basic-color-1'],
        },
        !isIos && { overflow: 'hidden' },
        style,
      )}
      {...rest}
    >
      {children}
    </View>
  )
  return !disabled && onPress ? (
    <TouchableOpacity activeOpacity={0.7} onPress={onPress}>
      {layout}
    </TouchableOpacity>
  ) : (
    layout
  )
}

export const FastButton = ({ icon, title, onPress, iconBgColor, style }) => {
  const { t } = useTranslation()
  return (
    <ItemContainer
      onPress={onPress}
      style={composeStyle(
        {
          borderRadius: 8,
          paddingHorizontal: 10,
          paddingVertical: 15,
          alignItems: 'center',
          flexDirection: 'column',
        },
        style,
      )}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: iconBgColor,
          borderRadius: 50,
          width: 40,
          height: 40,
          marginBottom: 10,
        }}
      >
        <Icon name={icon} color={colors.white} size={24} />
      </View>

      <Text size={14} numberOfLines={1}>
        {t(title)}
      </Text>
    </ItemContainer>
  )
}

interface ListItemProps {
  leftComponent?: ReactNode
  disabled?: boolean
  title?: string
  subTitle?: string
  centerComponent?: ReactNode
  rightComponent?: ReactNode
  arrow?: boolean
  onPress?: any
  style?: ViewStyle
  noBg?: boolean
  numberOfLines?: number
  numberOfLinesSub?: number
}

export const ListItem = ({
  leftComponent,
  disabled,
  title,
  subTitle,
  centerComponent,
  rightComponent,
  arrow,
  onPress,
  style,
  noBg,
  numberOfLines,
  numberOfLinesSub,
}: ListItemProps) => {
  let center = title ? (
    <View style={{ flex: 1 }}>
      <Title numberOfLines={numberOfLines || 1}>{title}</Title>
      {!!subTitle && (
        <Description numberOfLines={numberOfLinesSub || 1}>
          {subTitle}
        </Description>
      )}
    </View>
  ) : (
    centerComponent
  )
  return (
    <ItemContainer
      noBg={noBg}
      onPress={disabled ? null : onPress}
      style={composeStyle(
        {
          flexDirection: 'row',
          alignItems: 'center',
          padding: 5,
          opacity: disabled ? 0.3 : 1,
          paddingRight: 10,
        },
        style,
      )}
    >
      {leftComponent}
      {center}
      {rightComponent}
      {!!arrow && (
        <Icon name={'chevron-right'} size={32} color={colors.metal} />
      )}
    </ItemContainer>
  )
}

export const DotsDropdown = ({
  children,
  placement,
}: {
  children: ReactNode
  placement?: string
}) => {
  return (
    <Dropdown
      placement={placement || 'bottom start'}
      anchor={
        <TouchableOpacity>
          <Icon name="dots-vertical" color={colors.metal} />
        </TouchableOpacity>
      }
    >
      {children}
    </Dropdown>
  )
}

export const ViewDropdown = ({ onList, onGrid, title = 'view' }) => {
  const { t } = useTranslation()
  const [state, setState] = useState(1)
  const refInitialized = useRef(false)

  useEffect(() => {
    if (!refInitialized.current) {
      refInitialized.current = true
      return
    }
    if (state === 1) {
      onList()
    } else {
      onGrid()
    }
  }, [state])

  if (!isWeb) return null
  return (
    <Dropdown
      anchor={
        <TouchableOpacity
          testID="view_button"
          style={{ flexDirection: 'row', padding: 5, marginLeft: 20 }}
        >
          <Text size={14}>{t(title)}</Text>
          <Icon size={18} name="chevron-down" color={colors.metal} />
        </TouchableOpacity>
      }
    >
      <MenuItem
        testID="view_list"
        title={() => (
          <Text
            size={14}
            style={{
              flex: 1,
              marginLeft: 8,
              fontWeight: state === 1 ? 'bold' : 'normal',
            }}
          >
            {t('view-list')}
          </Text>
        )}
        onPress={() => setState(1)}
      />
      <MenuItem
        testID="view_grid"
        title={() => (
          <Text
            size={14}
            style={{
              flex: 1,
              marginLeft: 8,
              fontWeight: state === 2 ? 'bold' : 'normal',
            }}
          >
            {t('view-grid')}
          </Text>
        )}
        onPress={() => setState(2)}
      />
    </Dropdown>
  )
}

interface ICustomDropdownProps {
  title: string
  children: ReactNode
  textColor?: string
  iconColor?: string
  textSize?: number
  style?: ViewStyle
  icon?: string
  iconRight?: boolean
  noArrow?: boolean
  iconSize?: number
  dropDownStyles?: ViewStyle
  onPress?: () => void
}

export const CustomDropdown = ({
  title,
  children,
  textColor,
  iconColor = colors.metal,
  textSize = 14,
  style,
  icon = 'chevron-down',
  iconRight = true,
  iconSize = 18,
  noArrow = false,
  dropDownStyles,
  onPress,
}: ICustomDropdownProps) => {
  const { t } = useTranslation()
  return (
    <Dropdown
      style={dropDownStyles}
      anchor={
        <TouchableOpacity
          style={[
            {
              flexDirection: iconRight ? 'row' : 'row-reverse',
              padding: 5,
              alignItems: 'center',
            },
            style,
          ]}
          onPress={onPress}
        >
          <Text size={textSize} color={textColor}>
            {t(title)}
          </Text>
          {!noArrow && <Icon size={iconSize} name={icon} color={iconColor} />}
        </TouchableOpacity>
      }
      children={children}
    />
  )
}

export const ExportTo = ({ onPress, excelTitle, testID = '' }) => {
  const { t } = useTranslation()
  return (
    <Dropdown
      anchor={
        <TouchableOpacity
          testID="excel_button"
          style={{ flexDirection: 'row', padding: 5, marginLeft: 20 }}
        >
          <Text size={14}>{t('export')}</Text>
          <Icon size={18} name="chevron-down" color={colors.metal} />
        </TouchableOpacity>
      }
      children={
        excelTitle ? (
          <MenuItem
            testID={testID}
            title={() => (
              <Text size={14} style={{ flex: 1, marginLeft: 8 }}>
                {t(excelTitle)}
              </Text>
            )}
            onPress={onPress}
          />
        ) : (
          <View />
        )
      }
    />
  )
}

interface HistoryCardProps {
  children?: ReactNode
  title?: string | null
  subtitle?: string | null
  numberOfLines?: number
  type?: string | null
  operation?: string | number | null
  onPress?: () => void
  containerStyle?: ViewStyle
  subtitleStatus?: number
  img?: string
  onCopy?: () => void
  onSend?: () => void
  onShare?: () => void
  onCheque?: () => void
  onCancel?: (() => void) | null
  onEdit?: () => void
  onDeposit?: () => void
  onReload?: () => void
  onPayAgain?: () => void
  onBlock?: () => void
  onLook?: () => void
  extra?: string
  is_blocked?: boolean
}

export const HistoryCard = ({
  children,
  title,
  subtitle,
  numberOfLines,
  containerStyle,
  subtitleStatus,
  type,
  operation,
  img,
  onCopy,
  onSend,
  onShare,
  onCheque,
  onCancel,
  onEdit,
  onDeposit,
  onLook,
  onReload,
  onPayAgain,
  onBlock,
  is_blocked,
  extra,
}: HistoryCardProps) => {
  const { t } = useTranslation()
  const operations = [
    'nurtelecom',
    'NurTelecom',
    'beeline',
    'Beeline',
    'megacom',
    'Megacom',
    'salam',
    'Salam',
    'ZGeneration',
  ]
  const findOperation = operations.find((o) => o === type)

  return (
    <ItemContainer style={containerStyle}>
      <View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 10,
          }}
        >
          {img && (
            <Image
              style={{
                width: 100,
                height: 75,
                marginRight: 10,
                backgroundColor: 'white',
                borderRadius: 3,
              }}
              image_name={img}
            />
          )}

          <View style={{ flex: 1 }}>
            <Title size={14} numberOfLines={numberOfLines ? numberOfLines : 2}>
              {title}
            </Title>
            {!!subtitle && (
              <Description
                style={{
                  color: getStatusColor(subtitleStatus) || colors.metal,
                }}
              >
                {subtitle}
              </Description>
            )}
            {extra && <Description>{extra}</Description>}
          </View>

          {(onCopy ||
            onSend ||
            onShare ||
            onCheque ||
            onCancel ||
            onEdit ||
            onDeposit) && (
            <Dropdown
              anchor={
                <TouchableOpacity>
                  <Icon name="dots-vertical" color={colors.metal} />
                </TouchableOpacity>
              }
            >
              {onCheque && (
                <MenuItem
                  title={t('cheque')}
                  accessoryLeft={<Icon name="text-box-outline" size={16} />}
                  onPress={onCheque}
                />
              )}
              {onPayAgain && (
                <MenuItem
                  title="Оплатить еще раз"
                  accessoryLeft={<Icon name="wallet-outline" size={16} />}
                  onPress={onPayAgain}
                />
              )}
              {onCancel &&
                (!!findOperation ||
                  (operation === 2 && subtitleStatus === 1)) && (
                  <MenuItem
                    title={t('annul').toString()}
                    accessoryLeft={<Icon name="close" size={16} />}
                    onPress={onCancel}
                  />
                )}
              {onLook && (
                <MenuItem
                  title={`${t('look')}`}
                  accessoryLeft={<Icon name="eye" size={16} />}
                  onPress={() => onLook()}
                />
              )}
              {onDeposit && (
                <MenuItem
                  title={`${t('deposit')}`}
                  accessoryLeft={<Icon name="wallet-outline" size={16} />}
                  onPress={() => onDeposit()}
                />
              )}
              {onEdit && (
                <MenuItem
                  title={`${t('edit')}`}
                  accessoryLeft={<Icon name="pencil-box-outline" size={16} />}
                  onPress={() => onEdit()}
                />
              )}
              {onBlock && (
                <MenuItem
                  title={t(is_blocked ? 'unblock_user' : 'block_user')}
                  accessoryLeft={<Icon name="lock-outline" size={16} />}
                  onPress={() => onBlock()}
                />
              )}
              {onReload && (
                <MenuItem
                  title={t('reload')}
                  accessoryLeft={<Icon name="reload" size={16} />}
                  onPress={() => onReload()}
                />
              )}
              {onCopy && (
                <MenuItem
                  title={t('copy')}
                  accessoryLeft={<Icon name="file-edit-outline" size={16} />}
                  onPress={onCopy}
                />
              )}
              {onSend && (
                <MenuItem
                  title="Переслать"
                  accessoryLeft={<Icon name="share-outline" size={16} />}
                  onPress={onSend}
                />
              )}
              {onShare && (
                <MenuItem
                  title={t('share')}
                  accessoryLeft={<Icon name="share-variant" size={16} />}
                  onPress={onShare}
                />
              )}
            </Dropdown>
          )}
        </View>
      </View>
      <Divider />
      <View style={{ padding: 10 }}>{children}</View>
    </ItemContainer>
  )
}

interface TextDetailProps {
  title?: string
  name?: string
  value?: string | number
  status?: string
  numberOfLines?: number
  black?: boolean
  bold?: boolean
  color?: string
}

export const TextDetail = ({
  title = '',
  name = '',
  value = '',
  status = '',
  numberOfLines = 1,
  black = false,
  bold = false,
  color = '',
}: TextDetailProps) => {
  const { t } = useTranslation()
  const { isLight } = useTheme()
  const textColor = isLight ? colors.black : colors.white
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Text
        style={[bold && { fontWeight: '500' }]}
        color={
          color
            ? color
            : black
            ? colors.black
            : isLight
            ? colors.black
            : colors.white
        }
      >
        {title ? title : t(name) + ': '}
      </Text>
      <Text
        color={
          status ? getStatusColor(status) : black ? colors.black : textColor
        }
        size={14}
        style={[bold && { fontWeight: '500' }, { textAlign: 'right', flex: 1 }]}
        numberOfLines={numberOfLines}
      >
        {value}
      </Text>
    </View>
  )
}

export const FloatButton = ({ icon, title, onPress }) => {
  const { t } = useTranslation()
  const { primary } = useTheme()

  return (
    <TouchableOpacity activeOpacity={0.9} onPress={onPress}>
      <ItemContainer
        style={{
          paddingHorizontal: 12,
          paddingVertical: 12,
          marginRight: 10,
          marginBottom: 2,
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Icon name={icon} color={primary} />
        <Text
          style={{ textAlign: 'center', marginLeft: 6 }}
          size={16}
          color={primary}
        >
          {t(title || '')}
        </Text>
      </ItemContainer>
    </TouchableOpacity>
  )
}

export const Content = ({ children, style = {} }) =>
  isMobile ? (
    <View style={[{ paddingHorizontal: 10 }, style]}>{children}</View>
  ) : (
    <div id="content-view" style={style}>
      {children}
    </div>
  )

interface FrameContainerProps extends ItemContainerProps {
  icon?: string
  iconBg?: string
  title?: string | null
  number?: number
  subTitle?: ReactNode | string
  disabled?: boolean
  rightComponent?: ReactNode
  arrow?: boolean
  image?: string | null
  leftComponent?: ReactNode
  circleImg?: boolean
  account?: string | number | null
  balance?: string | number
  extra?: JSX.Element | null
  style?: ViewStyle
  country?: string
  numberOfLines?: number
  titleNumberOfLines?: number
  translate?: boolean
}

export const FrameContainer = ({
  icon,
  iconBg,
  title,
  number,
  subTitle,
  disabled = false,
  rightComponent,
  arrow,
  image,
  leftComponent,
  onPress,
  circleImg,
  account,
  balance,
  extra,
  style,
  country,
  numberOfLines = 1,
  testID,
  titleNumberOfLines = 2,
  translate = true,
}: FrameContainerProps) => {
  const { t } = useTranslation()
  const { isLight } = useTheme()
  const left = useMemo(() => {
    if (leftComponent) return leftComponent
    if (image)
      return (
        <Image
          style={{
            width: 50,
            height: 50,
            overflow: 'hidden',
            marginRight: 8,
            backgroundColor: circleImg ? 'transparent' : colors.white,
            borderWidth: circleImg ? 0 : 1,
            borderRadius: 4,
            borderColor: isLight ? colors.lightGrey : colors.borderDark,
          }}
          image_name={image}
          resizeMode={'contain'}
        />
      )
    return (
      <View
        style={{
          backgroundColor: iconBg || colors.metal,
          borderRadius: 50,
          width: 42,
          height: 42,
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: 10,
        }}
      >
        {number ? (
          <Text color={colors.white} size={20}>
            {number}
          </Text>
        ) : (
          !!icon && <Icon name={icon} size={24} color="white" />
        )}
      </View>
    )
  }, [leftComponent, image, circleImg, country, iconBg, number, icon])

  return (
    <ItemContainer
      testID={testID}
      onPress={onPress}
      disabled={disabled}
      style={composeStyle(
        {
          flexDirection: 'row',
          alignItems: 'center',
          opacity: disabled ? 0.6 : 1,
          padding: 8,
        },
        style,
      )}
    >
      {left}
      <View style={{ flex: 1 }}>
        <Text
          size={16}
          numberOfLines={titleNumberOfLines ? titleNumberOfLines : 3}
          style={{ marginBottom: !!subTitle || !!account ? 3 : 0 }}
        >
          {translate ? t(title + '') : title}
        </Text>

        {React.isValidElement(subTitle) ? (
          subTitle
        ) : subTitle ? (
          <Description numberOfLines={numberOfLines}>
            {t(subTitle + '')}
          </Description>
        ) : null}
        {extra}
        {!!account && <Description>{account}</Description>}
        {!!balance && <Description>{balance}</Description>}
      </View>
      {rightComponent}
      {!!arrow && (
        <Icon name={'chevron-right'} size={24} color={colors.metal} />
      )}
    </ItemContainer>
  )
}

export const RadioButton = ({
  testID = '',
  label = '',
  onPress,
  checked = false,
  style,
  pressTestID = '',
}: {
  testID?: string
  label?: string
  onPress?: () => void
  checked?: boolean
  style?: ViewStyle
  pressTestID?: string
}) => {
  const { primary } = useTheme()
  const { t } = useTranslation()
  return (
    <TouchableOpacity
      style={[{ flexDirection: 'row', alignItems: 'center' }, style]}
      onPress={onPress}
      testID={pressTestID}
      {...Platform.select({ web: { 'aria-selected': checked } })}
    >
      <Text style={{ marginRight: 5 }}>{t(label)}</Text>
      <Icon
        testID={testID}
        name={checked ? 'radiobox-marked' : 'radiobox-blank'}
        size={25}
        color={checked ? primary : colors.metal}
      />
    </TouchableOpacity>
  )
}

interface StatisticsProps {
  title: string
  value: string
}

export const Statistics = ({ title, value }: StatisticsProps) => {
  const { isLight } = useTheme()
  const { t } = useTranslation()
  return (
    <View
      style={composeStyle({
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 4,
        borderColor: isLight ? colors.borderLight : colors.borderDark,
        borderWidth: 1,
        marginRight: 8,
        marginVertical: 4,
      })}
    >
      <Description>{t(title) + ': '}</Description>
      <Text>{value}</Text>
    </View>
  )
}

interface IStatisticsVertical {
  title: any
  value: string | number | undefined
  onPress?: () => void
}

export const StatisticsVertical = ({
  title,
  value,
  onPress,
}: IStatisticsVertical) => {
  const { isLight } = useTheme()
  const { t } = useTranslation()
  const Wrapper: React.ElementType = onPress ? TouchableOpacity : View
  return (
    <Wrapper
      onPress={onPress}
      style={[
        {
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingVertical: 5,
          paddingHorizontal: 8,
          borderRadius: 5,
          borderWidth: 1,
          marginRight: 10,
          borderColor: isLight ? colors.borderLight : colors.borderDark,
        },
      ]}
    >
      <Text>{value}</Text>
      <Description size={12}>{t(title)}</Description>
    </Wrapper>
  )
}
export const IconButton = ({ onPress, icon, disabled = false, ...rest }) => {
  const { isLight } = useTheme()
  return (
    <TouchableOpacity
      activeOpacity={0.9}
      disabled={disabled}
      onPress={onPress}
      style={{
        flexDirection: 'row',
        alignSelf: 'flex-end',
        borderRadius: 4,
        padding: 10,
        backgroundColor: isLight ? colors.lightGrey : colors.borderDark,
      }}
      {...rest}
    >
      <Icon name={icon} color={isLight ? colors.primary : colors.secondary} />
    </TouchableOpacity>
  )
}

export const DottedDivider = () => {
  const { isLight } = useTheme()
  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderColor: isLight ? colors.borderLight : colors.borderDark,
      }}
    />
  )
}
const Div = ({ style }) => {
  const { isLight } = useTheme()
  return (
    <Hr
      style={[
        {
          backgroundColor: isLight ? colors.borderLight : colors.borderDark,
        },
        style,
      ]}
    />
  )
}

export const Divider = ({
  title = '',
  style,
  titleStyle,
}: {
  title?: string
  style?: ViewStyle
  titleStyle?: TextStyle
}) => {
  const { t } = useTranslation()
  if (title)
    return (
      <View style={[{ flexDirection: 'row', alignItems: 'center' }, style]}>
        <Div style={{ flex: 1 }} />
        <Description style={composeStyle({ marginHorizontal: 10 }, titleStyle)}>
          {t(title)}
        </Description>
        <Div style={{ flex: 1 }} />
      </View>
    )
  return <Div style={[{ marginVertical: 0 }, style]} />
}

interface IconValueProps {
  icon: string
  value: number | string
  style?: ViewStyle
}

export const IconValue = ({ icon, value, style }: IconValueProps) => (
  <View style={[{ flexDirection: 'row', alignItems: 'center' }, style]}>
    <Icon
      size={16}
      name={icon}
      color={colors.metal}
      style={{ marginRight: 5 }}
    />
    <Description>{value}</Description>
  </View>
)

export const NoData = ({ title }: { title?: string }) => {
  const { t } = useTranslation()
  return (
    <View
      style={{
        alignItems: 'center',
        marginVertical: 10,
      }}
    >
      <Icon name="archive-outline" color={colors.metal} size={32} />
      <Text style={{ textAlign: 'center', color: colors.metal }}>
        {title ? t(title) : t('no_data')}
      </Text>
    </View>
  )
}
