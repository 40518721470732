import React from 'react'
import { Alert } from './alert'
import {
  Button as KittenButton,
  ButtonProps as KittenButtonProps,
} from '@ui-kitten/components'
import { observer } from 'mobx-react'
import { colors, hooks, translate } from '@pay24/common'
import { Text, TextChildrenProp } from './Text'
import {
  ImageProps,
  Linking,
  TouchableOpacity,
  TouchableOpacityProps,
} from 'react-native'
import Icon from './Icon'
import { ItemContainer } from './Components'
import { RenderProp } from '@ui-kitten/components/devsupport'

const { useTheme } = hooks
const { useTranslation } = translate

export interface ButtonProps extends Omit<KittenButtonProps, 'children'> {
  ghost?: boolean
  icon?: string
  leftArrow?: boolean
  rightArrow?: boolean
  white?: boolean
  children?: TextChildrenProp
  onPress?: () => void
  textSize?: number
  colorText?: string
}

export const Button = observer(
  ({
    children,
    disabled = false,
    ghost = false,
    icon = '',
    leftArrow = false,
    rightArrow = false,
    style,
    white = false,
    onPress,
    colorText = '',
    testID = 'buttonID',
    textSize = 16,
    ...rest
  }: ButtonProps) => {
    const { isLight, primary } = useTheme()
    const bgColor = white ? colors.lightGrey : primary

    const textColor = white
      ? primary
      : disabled
      ? colors.white
      : isLight
      ? colors.white
      : colors.white

    const leftComponent = () => {
      if (leftArrow) return <Icon name="chevron-left" color={primary} />

      if (icon) {
        return <Icon name={icon} color={primary} />
      }
      return null
    }

    const rightComponent = () => {
      if (rightArrow) {
        return (
          <Icon
            name="chevron-right"
            color={disabled ? colors.metal : primary}
          />
        )
      }
      return null
    }
    return (
      <KittenButton
        appearance={'ghost'}
        testID={testID}
        onPress={onPress}
        accessoryLeft={leftComponent as RenderProp<Partial<ImageProps>>}
        accessoryRight={rightComponent as RenderProp<Partial<ImageProps>>}
        style={[
          {
            borderRadius: 8,
            borderWidth: 0,
            minHeight: 42,
            opacity: disabled ? 0.5 : 1,
            backgroundColor: ghost ? 'transparent' : bgColor,
          },
          style,
        ]}
        disabled={disabled}
        {...rest}
      >
        {() => (
          <Text color={colorText || textColor} size={textSize}>
            {children}
          </Text>
        )}
      </KittenButton>
    )
  },
)

interface TabButtonProps extends Omit<TouchableOpacityProps, 'children'> {
  circle?: boolean
  active?: boolean
  children?: TextChildrenProp
  testID?: string
}

export const TabButton = ({
  children,
  onPress,
  active = false,
  disabled,
  style,
  testID,
}: TabButtonProps) => {
  const { isLight, primary } = useTheme()

  // return useMemo(() => {
  return (
    <TouchableOpacity
      activeOpacity={0.9}
      disabled={disabled}
      onPress={onPress}
      style={style}
      testID={testID}
    >
      <ItemContainer
        style={{
          paddingHorizontal: 12,
          paddingVertical: 12,
          marginRight: 10,
          marginBottom: 2,
        }}
      >
        <Text
          style={{ textAlign: 'center' }}
          size={16}
          color={
            isLight
              ? active
                ? colors.black
                : primary
              : active
              ? colors.white
              : colors.orange
          }
        >
          {children}
        </Text>
      </ItemContainer>
    </TouchableOpacity>
  )
  // }, [active, theme, disabled])
}

export const ConfirmButton = ({
  confirmTitle,
  confirmMessage,
  onConfirm,
  onCancel,
  cancelButtonText,
  okButtonText,
  children,
  ...rest
}) => (
  <KittenButton
    onPress={() =>
      Alert.alert(
        confirmTitle || 'Подтверждение',
        confirmMessage || 'Вы уверены?',
        [
          { text: okButtonText || 'Да', onPress: onConfirm },
          { text: cancelButtonText || 'Отмена', onPress: onCancel },
        ],
      )
    }
    {...rest}
  >
    {children}
  </KittenButton>
)

export const GhostButton = ({ url, title, testID }) => {
  const { primary } = useTheme()
  const { t } = useTranslation()
  return (
    <TouchableOpacity testID={testID} onPress={() => Linking.openURL(url)}>
      <Text
        size={16}
        numberOfLines={2}
        style={{ textAlign: 'right' }}
        color={primary}
      >
        {t(title)}
      </Text>
    </TouchableOpacity>
  )
}

export interface SecondaryButtonProps
  extends Omit<TouchableOpacityProps, 'children'> {
  onPress: () => void
  title: string
  testID?: string
}

export const SecondaryButton = ({
  onPress,
  title,
  testID,
  disabled,
  ...rest
}: SecondaryButtonProps) => {
  const { primary } = useTheme()
  const { t } = useTranslation()
  return (
    <TouchableOpacity
      {...rest}
      disabled={disabled}
      activeOpacity={0.8}
      onPress={onPress}
      testID={testID}
    >
      <ItemContainer
        style={{
          paddingHorizontal: 10,
          paddingVertical: 12,
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <Text size={16} style={{ textAlign: 'center' }} color={primary}>
          {t(title)}
        </Text>
      </ItemContainer>
    </TouchableOpacity>
  )
}
