import moment from 'moment/moment'
import { useCallback } from 'react'
import { dateFormatDB } from '../utils'

export function useIncDecDate(dateTo: string, dateFrom: string, setDate) {
  const decDate = useCallback(() => {
    setDate({
      dateTo: dateFormatDB(moment(dateTo).subtract(1, 'day')),
      dateFrom: dateFormatDB(moment(dateFrom).subtract(1, 'day')),
    })
  }, [dateFrom, dateTo, setDate])
  const incDate = useCallback(() => {
    setDate({
      dateTo: dateFormatDB(moment(dateTo).add(1, 'day')),
      dateFrom: dateFormatDB(moment(dateFrom).add(1, 'day')),
    })
  }, [dateFrom, dateTo, setDate])

  return { decDate, incDate }
}
