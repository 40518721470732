import { colors } from '@pay24/common'
import { useTranslation } from '@pay24/common/src/translate'
import {
  CheckItem,
  FormCheck,
  FormDone,
  FormFail,
  Pay3D,
} from './DefaultTemplate'
import { requisiteScheme } from './schemes'
import { amountPlaceholder, getItems } from './utils'
import paymentStore, { FORM } from '@pay24/common/src/store/PaymentStore'
import { Button } from '../Button'
import { Input, InputModel } from '../Input'
import ListPicker from '../ListPicker'
import Spacer from '../Spacer'
import { Text } from '../Text'
import TextAutocomplete from '../TextAutocomplete'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React, { useState } from 'react'

class Store {
  params = null
}

const store = new Store()

const FormInitial = observer((props) => {
  const { service, source, valid_fields, comments } = props
  const { payData, isAgent } = paymentStore
  const [fioShow, setFioShow] = useState('Нет')
  const fields = service.data && service.data.fields
  const placeholder = service?.placeholder || ''
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      payData,
      isAgent,
      source,
    },
    enableReinitialize: true,
    validationSchema: requisiteScheme(service, valid_fields),
    onSubmit: (values) => {
      payData.setValue('service_id', service.id)
      payData.setValue('service_code', service.type)
      payData.setValue('service_name', service.name)
      payData.setValue('form_scheme', service?.data?.form_scheme)
      if (fioShow === 'Нет') {
        payData.setOptions(fields[2].name, '')
        payData.setOptions(fields[3].name, '')
        payData.setOptions(fields[4].name, '')
      }
      paymentStore.requisiteCheck(values.source).then((r) => {
        store.params = r.params
        paymentStore.setPaymentState(FORM.CHECK)
        paymentStore.setStep(2)
      })
    },
  })

  const getValue = (key, value) => {
    if (!payData.options) return null
    const k = payData.getOptions(key)
    const v = payData.getOptions(value)
    return { id: k, name: v }
  }

  const renderFields = () => {
    if (!fields || fields.length === 0 || Object.keys(fields).length === 0)
      return null

    const renderField = (f, i) => {
      if (i === 0) {
        return (
          <>
            <Input
              testID={`text_name${i}`}
              label={f.label}
              value={payData.getOptions(f.name)}
              inputMask={'(999) 99 99 99'}
              keyboardType={'phone-pad'}
              onChangeText={(val) => {
                paymentStore.setOption(f.name, val)
              }}
              placeholder={'(___) __ __ __'}
            />
            <Spacer />
          </>
        )
      }

      if (f.type === 'select') {
        let flag = f.name_for_code
        return (
          <>
            <ListPicker
              testID={`select_mvd_items${i}`}
              items={getItems(f.data, flag)}
              key={i}
              label={f.label}
              value={getValue(f.name_for_code, f.name_for_text)}
              onChange={(val) => {
                if (f.name_for_code === 'mvd_was_changed') {
                  setFioShow(val.name)
                }
                paymentStore.setOption(f.name_for_code, val.id)
                paymentStore.setOption(f.name_for_text, val.name)
              }}
              onClearPress={() => {
                if (f.name_for_code === 'mvd_was_changed') {
                  setFioShow('Нет')
                }
                paymentStore.deleteOption(f.name_for_code)
                paymentStore.deleteOption(f.name_for_text)
              }}
            />
            <Spacer />
          </>
        )
      }

      return fioShow === 'Да' ? (
        <>
          <Input
            testID={`label${i}`}
            key={i}
            label={f.label.replace(/:$/, '')}
            value={payData.getOptions(f.name)}
            onChangeText={(val) => {
              paymentStore.setOption(f.name, val)
            }}
          />
          <Spacer />
        </>
      ) : null
    }

    return fields.map((f, i) => <>{renderField(f, i)}</>)
  }

  return (
    <>
      {!paymentStore.isAgent && formik.errors.source ? (
        <Text
          style={{
            color: colors.red,
            marginTop: 3,
            marginLeft: 3,
            fontSize: 10,
          }}
        >
          {formik.errors.source}
        </Text>
      ) : null}
      <Spacer />
      <InputModel
        testID="requisite"
        model={payData}
        name={'requisite'}
        errorText={formik.errors.payData ? formik.errors.payData.requisite : ''}
        label={service?.data?.requisite_message || 'requisite'}
        inputMask={placeholder.replace(/_/g, '*')}
        keyboardType={'phone-pad'}
        placeholder={service?.placeholder}
      />
      <Spacer />
      {renderFields()}
      <InputModel
        testID="moneyAmount"
        label="sum"
        keyboardType="numeric"
        name="amount"
        value={formik.values.payData.amount?.toString()}
        errorText={formik.errors.payData ? formik.errors.payData.amount : ''}
        model={formik.values.payData}
        placeholder={amountPlaceholder(service)}
      />
      <Spacer />
      <TextAutocomplete
        testID="comment"
        model={formik.values.payData}
        name="comment"
        label="comment"
        placeholder="optional"
        options={comments}
      />
      <Spacer />
      <Button
        testID="nextBtn"
        disabled={paymentStore.buttonBlock}
        onPress={() => {
          formik.handleSubmit()
        }}
      >
        {t('next')}
      </Button>
    </>
  )
})

const FormCustomCheck = observer((props) => {
  const renderFields = () => {
    return (
      <>
        <CheckItem title="Имя" model={store.params} name={'name'} />
        <CheckItem title="Фамилия" model={store.params} name={'sur_name'} />
        <CheckItem
          title="Отчество"
          model={store.params}
          name={'patronic_name'}
        />
        <CheckItem
          title="Нужная сумма для оплаты"
          model={store.params}
          name={'amount_to_pay'}
          currency
        />
      </>
    )
  }
  return <FormCheck {...props} customFields={renderFields} />
})

const FormCustomDone = observer((props) => {
  const renderFields = () => {
    return (
      <>
        <CheckItem title="Имя" model={store.params} name={'name'} />
        <CheckItem title="Фамилия" model={store.params} name={'sur_name'} />
        <CheckItem
          title="Отчество"
          model={store.params}
          name={'patronic_name'}
        />
      </>
    )
  }

  return <FormDone {...props} customFields={renderFields} />
})

export default (isAgent) => {
  if (isAgent) {
    return {
      steps: 3,
      [FORM.INITIAL]: FormInitial,
      [FORM.CHECK]: FormCustomCheck,
      [FORM.PAID]: FormCustomDone,
      [FORM.FAIL]: FormFail,
    }
  }
  return {
    steps: 4,
    [FORM.INITIAL]: FormInitial,
    [FORM.CHECK]: FormCustomCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormCustomDone,
    [FORM.FAIL]: FormFail,
  }
}
