import { translate } from '@pay24/common'
import Alert from './Alert'
const { i18n } = translate
const confirmAlert = async (title, message, cancelButtonText?, okButtonText?) =>
  new Promise((resolve, reject) => {
    Alert.alert(
      title || i18n.t('confirm'),
      message || i18n.t('are_you_sure'),
      [
        {
          text: cancelButtonText || i18n.t('cancel'),
          onPress: reject,
          style: 'cancel',
        },
        { text: okButtonText || i18n.t('ok'), onPress: resolve },
      ],
      {
        cancelable: true,
        onDismiss: reject,
      },
    )
  })

export { Alert, confirmAlert }
