import React, { useRef } from 'react'
import { Camera } from 'react-camera-pro'
import { View, ViewStyle } from 'react-native'
import { Button } from './Button'
import { Text } from './Text'
import Spacer from './Spacer'
import Image from './Image'
import { translate, hooks, colors } from '@pay24/common'

const { useTranslation } = translate
const { useResponsive } = hooks

export default ({
  step,
  setValue,
  image,
  visible,
  name,
  title,
  setImageStep,
}) => {
  const camera: any = useRef()
  const { t } = useTranslation()
  const { screenWidth } = useResponsive()

  let scanStyle: ViewStyle =
    screenWidth <= 420
      ? { width: 350, height: 200, margin: 'auto' }
      : { width: 550, height: 350, margin: 'auto' }

  return (
    <>
      {visible && camera ? (
        <View>
          <View style={scanStyle}>
            <Camera
              ref={camera}
              errorMessages={{
                noCameraAccessible: '',
                permissionDenied: '',
                switchCamera: '',
                canvas: '',
              }}
            />
          </View>
          <Spacer />
          <Text style={{ margin: 'auto', textAlign: 'center' }} size={18}>
            {t(title)}
          </Text>
          <Spacer />
          <Button
            testID={'scanBtn'}
            style={{ width: 200, alignSelf: 'center' }}
            onPress={() => {
              setValue(name, camera ? camera.current.takePhoto() : null)
              setValue('visible', false)
            }}
          >
            {t('scan')}
          </Button>
          <Spacer />
          <View style={{ alignSelf: 'center' }}>
            <Text style={{ textAlign: 'center', color: colors.metal }}>
              {title === 'passport_front'
                ? t('passport_front_image_text')
                : title === 'passport_back'
                ? t('passport_back_image_text')
                : title === 'passport_selfie'
                ? t('passport_selfie_image_text')
                : title === 'a_document'
                ? t('passport_document_image_text')
                : t('passport_image_text')}
            </Text>
          </View>
        </View>
      ) : (
        <View>
          <Image
            style={{
              width: 550,
              height: 350,
              alignSelf: 'center',
            }}
            resizeMode="contain"
            source={{ uri: image }}
          />
          <Spacer />
          <Text
            style={{ fontWeight: 'bold', textAlign: 'center' }}
            size={14}
            color={colors.metal}
          >
            {name === 'documentImg'
              ? t('you_uploading_document')
              : t('you_uploading_passport')}
          </Text>
          {step === 3 ? (
            <Text style={{ margin: 'auto' }} size={14} color={colors.metal}>
              {t('face_properly')}
            </Text>
          ) : (
            <>
              <Text style={{ margin: 'auto' }} size={14} color={colors.metal}>
                {t('your_passport')}
              </Text>
              <Text style={{ margin: 'auto' }} size={14} color={colors.metal}>
                {t('passport_original')}
              </Text>
              <Text style={{ margin: 'auto' }} size={14} color={colors.metal}>
                {t('only_passport')}
              </Text>
              <Text style={{ margin: 'auto' }} size={14} color={colors.metal}>
                {t('passport_background')}
              </Text>
            </>
          )}
          <Spacer />
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              testID={'resetBtn'}
              colorText={colors.primary}
              style={{
                width: 210,
                backgroundColor: colors.white,
                borderColor: colors.primary,
                borderWidth: 2,
                marginHorizontal: 5,
              }}
              onPress={() => {
                setValue(name, '')
                setValue('visible', true)
              }}
            >
              {t('reset')}
            </Button>
            <Spacer width={15} />
            <Button
              testID={'saveBtn'}
              style={{ width: 210, marginHorizontal: 5 }}
              onPress={() => {
                setImageStep('')
                setValue('visible', true)
              }}
            >
              {t('save')}
            </Button>
          </View>
        </View>
      )}
      <Spacer />
    </>
  )
}
