import { translate, utils } from '@pay24/common'
import { Layout, Modal } from '@ui-kitten/components'
import React, { ReactNode, useEffect } from 'react'
import { BackHandler, TouchableOpacity, View } from 'react-native'
import { BusyProgress } from './BusyProgress'
import { ThemedSafeAreaView } from './Components'
import Icon from './Icon'
import { Title } from './Text'

const { isAndroid, isMobile } = utils
const { useTranslation } = translate

interface ModalProps {
  title?: string
  children?: ReactNode
  onClose?: () => void
  closeText?: boolean
  visible?: boolean
  titleColor?: string
  iconColor?: string
  style?: any
  testID?: string
  closeColor?: string
}

export default ({
  title,
  children,
  onClose,
  closeText = false,
  visible,
  titleColor,
  iconColor,
  style,
  testID = 'modalID',
  ...rest
}: ModalProps) => {
  const { t } = useTranslation()

  const backAction = () => {
    if (visible) {
      onClose && onClose()
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    let backHandler
    if (isMobile) {
      backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      )
    }
    return () => backHandler && backHandler.remove()
  }, [backAction, visible])

  return (
    <Modal
      visible={visible}
      onBackdropPress={onClose}
      backdropStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      testID={testID}
      {...rest}
    >
      {isAndroid ? <BusyProgress /> : null}
      {visible && (
        <ThemedSafeAreaView
          style={[
            { alignSelf: 'center', minWidth: 300, borderRadius: 5 },
            style,
          ]}
        >
          <Layout level="1" style={[style && style, { borderRadius: 5 }]}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 15,
                alignItems: 'center',
              }}
            >
              {title ? (
                <Title
                  style={[{ flex: 1 }, titleColor ? { color: titleColor } : {}]}
                >
                  {t(title)}
                </Title>
              ) : (
                <View style={{ flex: 1 }} />
              )}

              {!closeText && onClose ? (
                <TouchableOpacity
                  hitSlop={{ bottom: 20, left: 20, right: 20, top: 20 }}
                  onPress={onClose}
                >
                  <Icon testID="closeBtn" name={'close'} color={iconColor} />
                </TouchableOpacity>
              ) : onClose ? (
                <TouchableOpacity
                  onPress={onClose}
                  style={{ flexDirection: 'row', alignItems: 'center' }}
                >
                  <Icon name={'close'} color={iconColor} />
                </TouchableOpacity>
              ) : null}
            </View>

            <View
              style={{
                paddingVertical: 10,
                borderRadius: 0,
                height: style && style.height ? style.height - 60 : undefined,
              }}
            >
              {children}
            </View>
          </Layout>
        </ThemedSafeAreaView>
      )}
    </Modal>
  )
}
