import { Layout } from '@ui-kitten/components'
import React, { ReactNode } from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import Modal from 'react-native-modal'
import Spacer from './Spacer'
import { Text } from './Text'

interface ModalNativeProps {
  visible: boolean
  closeModal: () => void
  children: ReactNode
  title?: string
  backdropColor?: string
  layoutLevel?: string
}

export function ModalNative(props: ModalNativeProps) {
  const {
    visible,
    closeModal,
    children,
    title,
    backdropColor,
    layoutLevel = '3',
  } = props

  const titleComponent = title ? (
    <>
      <Text style={styles.title} size={16}>
        {title}
      </Text>
      <Spacer height={16} />
    </>
  ) : null

  return (
    <Modal
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={visible}
      onBackdropPress={closeModal}
      onBackButtonPress={closeModal}
      backdropColor={backdropColor}
      style={styles.modal}
    >
      <Layout level={layoutLevel} style={styles.layout}>
        {titleComponent}
        <ScrollView>{children}</ScrollView>
      </Layout>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modal: {
    margin: 0,
    justifyContent: 'flex-end',
    paddingTop: 100,
  },
  layout: {
    borderRadius: 10,
    paddingHorizontal: 12,
    paddingTop: 12,
    maxHeight: '100%',
    paddingBottom: 16,
  },
  title: { textAlign: 'center', fontWeight: 'bold' },
})
