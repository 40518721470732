import { colors } from '@pay24/common'
import {
  amountFormatCurrency,
  i18nString,
  isCharge24,
} from '@pay24/common/src/utils'
import { DotsDropdown, ItemContainer } from '../Components'
import Icon from '../Icon'
import Image from '../Image'
import { Description, Title } from '../Text'
import { MenuItem } from '@ui-kitten/components'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { View, ViewStyle } from 'react-native'
import { useTheme } from '@pay24/common/src/hooks'

interface AccountCardProps {
  item: any
  onPress?(): void
  selectedItem?: string | null | boolean
  onRemove?(): void
  onChange?(): void
  arrow?: boolean
  isError?: boolean
  wired?: boolean
  empty?: boolean
  requisite?: number | string | null
  tid?: string
  testID?: string
  pressTestID?: string
  style?: ViewStyle
  country?: string
}

export function AccountCard(props: AccountCardProps) {
  const {
    item,
    onPress,
    selectedItem,
    onRemove,
    onChange,
    arrow = false,
    empty = false,
    requisite,
    tid = '',
    testID = 'accountCardID',
    pressTestID = '',
    style,
    country = 'KGZ',
  } = props

  let key = item?.db_id || item?.id

  const { isLight } = useTheme()

  return (
    <View style={style}>
      <ItemContainer
        testID={'debitCard_' + item?.name || ''}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          padding: 8,
        }}
        onPress={onPress}
      >
        <Image
          style={{
            width: 60,
            height: 60,
            marginRight: 8,
            backgroundColor: colors.white,
            borderWidth: 1,
            borderRadius: 4,
            borderColor: isLight ? colors.lightGrey : colors.borderDark,
          }}
          source={item.name === 'Kaspi' ? item.img : { uri: item?.img }}
          resizeMode={'contain'}
        />

        <View style={{ flex: 1, marginRight: 8 }}>
          <Title numberOfLines={2}>{capitalize(item?.name)}</Title>
          {requisite ? (
            <Description>{requisite}</Description>
          ) : (
            item?.requisite && (
              <Description numberOfLines={1}>
                {requisite
                  ? requisite
                  : item?.requisite
                  ? item?.requisite
                  : null}{' '}
              </Description>
            )
          )}
          {item?.group && (
            <Description numberOfLines={1}>
              {item?.group?.name} - {amountFormatCurrency(item?.group?.balance)}{' '}
              сом
            </Description>
          )}
          {item?.balance && (
            <Description numberOfLines={1}>
              {amountFormatCurrency(item.balance)}
            </Description>
          )}
        </View>
        {arrow ? (
          <Icon name={'chevron-down'} size={24} color={colors.metal} />
        ) : empty ? null : (
          <View
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {onChange || onRemove ? (
              <DotsDropdown placement={'bottom end'}>
                {!!onChange && (
                  <MenuItem
                    onPress={onChange}
                    testID={tid}
                    title={i18nString('edit')}
                  />
                )}
                {!!onRemove && (
                  <MenuItem
                    onPress={onRemove}
                    testID={testID}
                    title={i18nString('delete')}
                  />
                )}
              </DotsDropdown>
            ) : null}

            {isCharge24 ? (
              item?.id !== 'master' &&
              item?.id !== 'visa' &&
              item?.id !== 'bereke' ? (
                <Description size={10} style={{ fontWeight: '700' }}>
                  {country}
                </Description>
              ) : (
                <View style={{ height: 20 }} />
              )
            ) : (
              <Description size={10} style={{ fontWeight: '700' }}>
                {country}
              </Description>
            )}

            {!empty && !arrow ? (
              <Icon
                testID={pressTestID}
                size={24}
                name={key === selectedItem ? 'star' : 'star-outline'}
                color={key === selectedItem ? colors.secondary : colors.metal}
              />
            ) : null}
          </View>
        )}
      </ItemContainer>
    </View>
  )
}
