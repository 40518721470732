import { FormCheck, FormDone, FormFail, Pay3D } from './DefaultTemplate'
import { FormInitial } from './DefaultPassportTemplate'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { PaymentStore } from '@pay24/common/src/store'
import { Input } from '../Input'
import Spacer from '../Spacer'
import { observer } from 'mobx-react'
import React from 'react'
import * as yup from 'yup'

const FormBase = observer((props) => {
  const { service } = props
  const { payData } = PaymentStore

  const valid_fields = {
    unmasked_phone_number: yup
      .string()
      .required('Введите номер телефона в формате (0ХХХХХХХХХ)'),
  }

  const renderFields = (errors) => {
    const fields = service.data && service.data.fields
    if (!fields || fields.length === 0 || Object.keys(fields).length === 0) {
      return null
    }

    const unmasked_phone_number = fields.find(
      (value) => value.name === 'unmasked_phone_number',
    )

    return (
      <>
        <Input
          testID="unmasked_phone_number"
          errorText={errors?.unmasked_phone_number}
          label={unmasked_phone_number.label}
          value={payData.getOptions(unmasked_phone_number.name)}
          onChangeText={(val) => {
            PaymentStore.payData.setOptions(unmasked_phone_number.name, val)
          }}
        />
        <Spacer />
      </>
    )
  }

  return (
    <FormInitial
      {...props}
      customFields={renderFields}
      valid_fields={valid_fields}
    />
  )
})

export default (isAgent) => {
  if (isAgent) {
    return {
      steps: 4,
      [FORM.INITIAL]: FormBase,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormDone,
      [FORM.FAIL]: FormFail,
    }
  }
  return {
    steps: 5,
    [FORM.INITIAL]: FormBase,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
