import { APP_ENV } from '@pay24/common/src/utils'
import { BankCard } from './BankCard'
import { Bereke } from './Bereke'
import { ODengi } from './ODengi'
import { Balance } from './Balance'
export { default as Deposit } from './Deposit'
export { KaspiForm } from './KaspiForm'
export { BankCard }

export const ACCOUNTS = [
  {
    img: 'card.png',
    title: 'Visa',
    id: 'visa',
    FormComponent: BankCard,
    visible: true,
    category_id: 1,
  },
  {
    img: 'mastercard.png',
    title: 'Mastercard',
    id: 'master',
    FormComponent: BankCard,
    visible: true,
    category_id: 1,
  },
  {
    img: 'elcard.png',
    title: 'Элкарт',
    id: 'elcard',
    FormComponent: BankCard,
    visible: true,
    category_id: 1,
  },
  {
    img: 'bereke.png',
    title: 'Bereke',
    id: 'bereke',
    FormComponent: Bereke,
    visible: false,
    category_id: 1,
  },
  {
    img: 'odengi.png',
    title: 'O! Деньги',
    id: 'odengi',
    FormComponent: ODengi,
    visible: true,
    category_id: 2,
  },
  {
    img: 'balance.png',
    title: 'Balance.kg',
    id: 'balance',
    FormComponent: Balance,
    visible: APP_ENV !== 'prod',
    category_id: 2,
  },
]
