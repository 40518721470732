import React from 'react'
import { View, ViewProps } from 'react-native'
import useBreakPoint from './useBreakPoint'
import useBreakPointValue from './useBreakPointValue'

// noinspection PointlessArithmeticExpressionJS
const SIZES = {
  1: ((100 / 12) * 1).toFixed(6) + '%',
  2: ((100 / 12) * 2).toFixed(6) + '%',
  3: ((100 / 12) * 3).toFixed(6) + '%',
  4: ((100 / 12) * 4).toFixed(6) + '%',
  5: ((100 / 12) * 5).toFixed(6) + '%',
  6: ((100 / 12) * 6).toFixed(6) + '%',
  7: ((100 / 12) * 7).toFixed(6) + '%',
  8: ((100 / 12) * 8).toFixed(6) + '%',
  9: ((100 / 12) * 9).toFixed(6) + '%',
  10: ((100 / 12) * 10).toFixed(6) + '%',
  11: ((100 / 12) * 11).toFixed(6) + '%',
  12: ((100 / 12) * 12).toFixed(6) + '%',
}

interface Props extends ViewProps {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
}

export default function Col({ children, style, ...rest }: Props) {
  const point = useBreakPoint()
  const paddingHorizontal = useBreakPointValue(point, { xs: 5, sm: 5 })
  const size = useBreakPointValue(point, rest)
  const width = SIZES[size]
  const _style = style
    ? [{ paddingHorizontal, width }, style]
    : { paddingHorizontal, width }
  return <View style={_style}>{children}</View>
}
