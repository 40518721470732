import React from 'react'
import { View } from 'react-native'
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'

export default ({ url, title }) => {
  if (!url) {
    return null
  }
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}
    >
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={48} round />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={48} round />
      </TwitterShareButton>

      <TelegramShareButton url={url} title={title}>
        <TelegramIcon size={48} round />
      </TelegramShareButton>

      <WhatsappShareButton url={url} title={title} separator=":: ">
        <WhatsappIcon size={48} round />
      </WhatsappShareButton>

      <ViberShareButton url={url} title={title}>
        <ViberIcon size={48} round />
      </ViberShareButton>
    </View>
  )
}
