import { api, colors, translate, store } from '@pay24/common'
import { confirmAlert } from '../alert'
import { AccountCard } from './AccountCard'
import { Button } from '../Button'
import CheckBox from '../CheckBox'
import { NoData } from '../Components'
import Modal from '../Modal'
import Screen from '../ScreenNew'
import Spacer from '../Spacer'
import { SubTitle, Text } from '../Text'
import ToolbarButton from '../ToolbarButton'
import { Input } from '@ui-kitten/components/ui'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import { View } from 'react-native'
import { Col, Row } from '../grid'

const { passport } = api
const { useTranslation } = translate
const { AccountStore, AgentStore, RootStore } = store

export default observer(({ navigation }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [refresh, setRefresh] = useState(true)
  const { t } = useTranslation()

  const {
    setCredit,
    creditWired,
    setItem,
    selectedCredit,
    changeSelectedCreditName,
    refreshCredit,
    removeAccount,
  } = AccountStore

  useEffect(() => {
    if (!refresh) return
    refreshCredit().finally(() => setRefresh(false))
    RootStore.getUserInfo()
  }, [refresh])

  const openModal = (item) => {
    setModalVisible(true)
    setItem(item, false)
  }

  const closeModal = () => setModalVisible(false)

  const onRemove = (iid) => {
    confirmAlert(
      t('confirmation'),
      t('are_you_sure_to_delete'),
      t('cancel'),
      t('yes'),
    ).then(() => removeAccount(iid).then(() => setRefresh(true)))
  }

  const onSaveTitle = () => {
    passport
      .addAccount({
        id: selectedCredit?.db_id,
        name: selectedCredit?.name,
      })
      .then(() => {
        setRefresh(true)
        closeModal()
      })
  }

  const onRefresh = () => {
    refreshCredit().finally(() => setRefresh(false))
  }

  return (
    <Screen
      onRefresh={onRefresh}
      title={'accounts_deposit'}
      onBackPress={() =>
        navigation.canGoBack()
          ? navigation.goBack()
          : navigation.navigate('main', { screen: 'home' })
      }
      back
      rightComponent={
        <ToolbarButton
          label="add"
          icon="plus"
          onPress={() => navigation.navigate('credit/add')}
        />
      }
    >
      {creditWired.length > 0 ? (
        <>
          <SubTitle>{t('binded')}</SubTitle>
          <Row>
            {creditWired.map((item, idx) => (
              <Col xs={12} sm={6} md={4} key={idx}>
                <AccountCard
                  testID="pressBidnedCard"
                  wired
                  requisite={
                    item.id === 'agent' ? AgentStore.sub_code : undefined
                  }
                  item={item}
                  selectedItem={RootStore.user?.data.default_credit}
                  onChange={
                    item.id !== 'agent' ? () => openModal(item) : undefined
                  }
                  onRemove={
                    item.id !== 'agent' ? () => onRemove(item.db_id) : undefined
                  }
                  onPress={() => setCredit(item)}
                  tid={`el-${idx}`}
                />
                <Spacer />
              </Col>
            ))}
          </Row>
        </>
      ) : (
        !RootStore.isBusy && <NoData title="not_binded" />
      )}
      <AccountCreditModal
        closeModal={closeModal}
        modalVisible={modalVisible}
        onSaveTitle={onSaveTitle}
        selectedCredit={selectedCredit}
        changeSelectedCreditName={changeSelectedCreditName}
        setCredit={setCredit}
      />
    </Screen>
  )
})

interface IAccountModalProps {
  modalVisible: boolean
  closeModal: () => void
  onSaveTitle: () => void
  selectedCredit: any
  changeSelectedCreditName: (val) => void
  setCredit: (item) => void
}

const AccountCreditModal: FC<IAccountModalProps> = observer(
  ({
    modalVisible,
    closeModal,
    onSaveTitle,
    selectedCredit,
    changeSelectedCreditName,
    setCredit,
  }) => {
    const { t } = useTranslation()

    return (
      <Modal
        testID="modal"
        title={'change_title'}
        visible={modalVisible}
        onClose={closeModal}
      >
        <View style={{ paddingHorizontal: 12, maxWidth: 320 }}>
          <AccountCard item={selectedCredit} empty />
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginVertical: 10,
            }}
          >
            <Text>{t('master_account')}</Text>
            <CheckBox
              value={
                RootStore.user?.data.default_credit === selectedCredit?.db_id
              }
              onChange={(val) => setCredit(val ? selectedCredit : null)}
            />
          </View>
          <Text color={colors.metal}>{t('master_account_text')}</Text>
          <Spacer />
          <Input
            testID="changeTitle"
            value={selectedCredit?.name}
            onChangeText={(val) => changeSelectedCreditName(val)}
          />
          <Spacer />
          <Button testID="save-title" onPress={onSaveTitle}>
            {t('save')}
          </Button>
        </View>
      </Modal>
    )
  },
)
