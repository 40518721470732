import { colors } from '@pay24/common'
import { pay24 as pay24Api } from '@pay24/common/src/api'
import { OPERATION } from '@pay24/common/src/store/PaymentHistoryStore'
import {
  amountFormatCurrency,
  dateTimeFormat,
  getStatusText,
  isCharge24,
} from '@pay24/common/src/utils'
import Icon from '../Icon'
import { PAGES } from './types'
import { DottedDivider, ItemContainer, NoData, TextDetail } from '../Components'
import Spacer from '../Spacer'
import { Text } from '../Text'
import { Spinner } from '@ui-kitten/components'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Pressable, View } from 'react-native'
import { useTheme } from '@pay24/common/src/hooks'
import { useTranslation } from '@pay24/common/src/translate'

interface RecordProps {
  amount: number
  data: any
  dc: string
  guid: string
  id: number
  local_id: string
  payment_method: string
  rec_date: string
  status: string
  user_id: number | string
}

interface DetailProperty {
  record: RecordProps
  forwards?: any
}

interface ChequeContentProps {
  page: any
  navigation: any
  openPage: (val) => void
  onClose: () => void
}

const HistoryInfo = observer(function HistoryInfo({
  openPage,
  page,
  navigation,
  onClose,
}: ChequeContentProps) {
  const [detail, setDetail] = useState<DetailProperty | null>(null)
  const [loading, setLoading] = useState(false)
  const { primary } = useTheme()
  const { t } = useTranslation()

  const fetchChequeData = () => {
    setLoading(true)
    const req = pay24Api.recordDetails({
      record_id: page?.params.id,
      is_charge: isCharge24,
    })
    req.then((r) => setDetail(r))
    req.finally(() => {
      setLoading(false)
    })
    return req.cancel
  }

  useEffect(() => {
    if (page?.params?.id) {
      return fetchChequeData()
    }
  }, [page?.params?.id])

  return (
    <View>
      {loading ? (
        <View style={{ alignItems: 'center', marginTop: 10 }}>
          <Spinner />
        </View>
      ) : detail ? (
        <>
          <ItemContainer>
            <View style={{ padding: 12 }}>
              {detail.record.dc === 'd' && (
                <Text style={{ fontWeight: 'bold' }}>
                  {detail.record.data.payload.service_name}
                </Text>
              )}
            </View>
            <DottedDivider />
            <View style={{ paddingHorizontal: 12, paddingVertical: 8 }}>
              <TextDetail
                name={'requisite'}
                value={detail.record.data?.payload?.requisite}
              />
              <TextDetail
                name={'amount'}
                value={amountFormatCurrency(
                  detail.record.data?.payload?.amount,
                  true,
                )}
              />
              <TextDetail
                name={'payment_commission'}
                value={amountFormatCurrency(
                  detail.record.data?.payload?.paymentCommission,
                  true,
                )}
              />
              <TextDetail
                name={'acquiring_commission'}
                value={amountFormatCurrency(
                  detail.record.data?.payload?.commission -
                    detail.record.data?.payload?.paymentCommission >
                    0
                    ? detail.record.data?.payload?.commission -
                        detail.record.data?.payload?.paymentCommission
                    : 0,
                  true,
                )}
              />
              <TextDetail
                name={'total'}
                value={amountFormatCurrency(
                  detail.record.data.payload.totalAmount,
                )}
              />
              <Spacer />
              <TextDetail
                name={'date'}
                value={dateTimeFormat(detail.record.rec_date)}
              />
              <TextDetail
                name={'status'}
                value={getStatusText(detail.record.status)}
                status={detail.record.status}
              />
              <Spacer />
              <TextDetail
                name={'operation'}
                value={detail.record.dc === 'c' ? t('incomes') : t('expenses')}
              />
              <TextDetail
                name={'source'}
                value={
                  detail.record.data?.payload?.source?.name ||
                  detail.record.payment_method
                }
              />
              {detail.record.dc === 'd' && (
                <TextDetail
                  name={'transaction_number'}
                  value={detail.record.data.acquiring_response?.referenceNo}
                />
              )}
              <TextDetail
                name={'comment'}
                value={detail.record?.data.payload?.comment}
              />
              <Spacer />
              {detail.forwards && detail.forwards.length > 0
                ? detail.forwards.map((item, idx) => (
                    <View key={idx}>
                      <TextDetail
                        title={'Счет пополнения:'}
                        value={item.service_code}
                      />
                      <TextDetail title={' '} value={item.requisite} />

                      {item.status === 'fail' && (
                        <Text
                          style={{ color: colors.red, textAlign: 'center' }}
                        >
                          {item.data.error_message}
                        </Text>
                      )}
                    </View>
                  ))
                : null}
            </View>
            <DottedDivider />
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              {detail.record.dc === OPERATION.EXPENSE &&
              !detail.record.data?.requisite?.includes('***') ? (
                <Pressable
                  style={{ padding: 8 }}
                  onPress={() => {
                    const param = {
                      amount: parseFloat(detail.record?.data?.payload?.amount),
                      requisite: detail.record?.data?.payload?.requisite,
                      serviceId: detail.record?.data?.payload?.service_id,
                    }
                    navigation.navigate('main', {
                      screen: 'payment',
                      params: param,
                    })
                    onClose()
                  }}
                >
                  <Icon name="refresh" color={primary} />
                </Pressable>
              ) : null}
              {!isCharge24 &&
              detail.record.dc === OPERATION.EXPENSE &&
              !detail.record.data?.requisite?.includes('***') ? (
                <Pressable
                  style={{ padding: 8 }}
                  onPress={() => {
                    const param = {
                      requisite: detail.record?.data?.payload?.requisite,
                      service_id: detail.record?.data?.payload?.service_id,
                      options: detail.record?.data?.payload?.options,
                    }
                    navigation.navigate('main', {
                      screen: 'favorite',
                      params: { favorite: param },
                    })
                    onClose()
                  }}
                >
                  <Icon name="heart-outline" color={primary} />
                </Pressable>
              ) : null}
              <Pressable
                style={{ padding: 8 }}
                onPress={() => {
                  openPage({
                    screen: PAGES.CHEQUE,
                    params: { id: detail.record?.id },
                  })
                }}
              >
                <Icon name="text-box-outline" color={primary} />
              </Pressable>
            </View>
          </ItemContainer>
        </>
      ) : (
        <NoData />
      )}
    </View>
  )
})

export default HistoryInfo
