import charge24Api from '@pay24/common/src/api/charge24'
import { useTheme } from '@pay24/common/src/hooks'
import { isCharge24 } from '@pay24/common/src/utils'
import { parse } from 'query-string'
import { pay24 as pay24Api } from '@pay24/common/src/api'
import Payment3D from '../payment3D'
import { Text } from '../Text'
import React, { useEffect, useState } from 'react'

export function BankCard({ onSave }) {
  let [params, setParams] = useState<any>(null)
  let [status, setStatus] = useState('init')
  let [error, setError] = useState('Ошибка')
  const { isLight } = useTheme()

  useEffect(() => {
    if (isCharge24) {
      charge24Api
        .debitAccountAddStart('card', {})
        .then((e) => {
          console.log(e)
          setParams(e)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      pay24Api
        .debitAccountAddStart('card', { theme: isLight ? 'light' : 'dark' })
        .then((e) => {
          console.log(e)
          setParams(e)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [isLight])
  const onSavePress = (data) => {
    let saveParams = parse(data)
    let secure = saveParams.token
    let requisite = saveParams.requisite
    let type: any = saveParams.type
    let service_code: string | undefined = 'card'
    switch (type) {
      case 'MAESTRO':
      case 'MASTERCARD':
        service_code = 'master'
        break
      case null:
        service_code = 'card'
        break
      default:
        service_code = type?.toLowerCase()
    }
    if (onSave) {
      onSave({ requisite, service_code, secure })
    }
  }

  const onFail = (data) => {
    setStatus('failed')
    let paramsFail = parse(data)
    if (typeof paramsFail.error === 'string') setError(paramsFail.error)
  }

  return (
    <>
      {status === 'init' && (
        <Payment3D {...params} onSuccess={onSavePress} onFail={onFail} />
      )}
      {status === 'failed' && <Text>{error}</Text>}
    </>
  )
}
