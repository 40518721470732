import React, { useState } from 'react'
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  ImageBackgroundProps,
} from 'react-native'
import placeholderImage from '../../assets/img/noimage.png'

interface Props extends Omit<ImageBackgroundProps, 'source'> {
  placeholderStyle?: any
  placeholderSource?: any
  image_name?: string
  source?: any
}

export default ({
  placeholderStyle,
  style,
  placeholderSource,
  image_name,
  source,
  ...props
}: Props) => {
  const [isLoaded, setLoaded] = useState(false)
  const [isError, setError] = useState(false)

  const onLoadEnd = () => {
    setLoaded(true)
  }
  const onError = () => {
    setError(true)
  }

  return (
    <ImageBackground
      onLoadEnd={onLoadEnd}
      onError={onError}
      {...props}
      style={[
        {
          position: 'relative',
          overflow: 'hidden',
          alignItems: 'center',
          justifyContent: 'center',
        },
        style,
      ]}
      source={
        source || {
          uri: `https://static.pay24.asia:4267/images/${image_name}`,
        }
      }
    >
      {isError ? (
        <Image
          source={placeholderSource ? placeholderSource : placeholderImage}
          style={placeholderStyle ? placeholderStyle : style}
        />
      ) : null}
      {!isLoaded ? <ActivityIndicator size={'small'} /> : null}
    </ImageBackground>
  )
}
