export const BREAK_POINT = { xs: 0, sm: 600, md: 1024, lg: 1440, xl: 1920 }

/**
 * @type {Array<{key: string, value: number}>}
 */
export const BREAK_POINTS = [
  { key: 'xl', value: BREAK_POINT.xl },
  { key: 'lg', value: BREAK_POINT.lg },
  { key: 'md', value: BREAK_POINT.md },
  { key: 'sm', value: BREAK_POINT.sm },
  { key: 'xs', value: BREAK_POINT.xs },
]

export const GAP_SIZE = { xs: 4, sm: 8, md: 8, lg: 16, xl: 16 }

export const SCREEN_MAX_WIDTH = { xs: 536, sm: 560, md: 832, lg: 1184, xl: 1184 }
