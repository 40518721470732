import { useTranslation } from '@pay24/common/src/translate'
import {
  FormCheck,
  FormDone,
  FormFail,
  Pay3D,
  FormInput,
} from './DefaultTemplate'
import { clearItems, getItems } from './utils'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { PaymentStore } from '@pay24/common/src/store'
import { Input } from '../Input'
import ListPicker from '../ListPicker'
import Spacer from '../Spacer'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import * as yup from 'yup'

export const FormGNSInitial = observer((props) => {
  const { service, source, customFields, gns_valid_fields } = props
  const { payData } = PaymentStore
  const [f, setF] = useState<string>('')
  const [i, setI] = useState<string>('')
  const [o, setO] = useState<string>('')
  const { t } = useTranslation()

  const fields = service.data && service.data.fields

  const oblast = fields.find(
      (value) => value.name_for_code === 'gns_oblast_code',
    ),
    raion = fields.find((value) => value.name_for_code === 'gns_raion_code'),
    aimak = fields.find((value) => value.name_for_code === 'gns_aimak_code'),
    fio = fields.find((value) => value.name === 'fio')

  const getValue = (key, value) => {
    if (!payData.options) return null
    const k = payData.getOptions(key)
    const v = payData.getOptions(value)
    return { id: k, name: v }
  }

  const aimaks = getItems(aimak.data, aimak.name_for_code)

  const valid_fields = Object.assign(
    {
      gns_oblast_code: yup.string().required('Выберите область'),
      gns_raion_code: yup.string().required('Выберите район'),
      gns_aimak_code:
        aimaks?.length === 0
          ? yup.string()
          : yup.string().required('Выберите аймак'),
    },
    gns_valid_fields,
  )

  const add_param_valid_fields = {
    f: yup.string().required(t('required_last_name')),
    i: yup.string().required(t('required_name')),
    aimaks: yup.array(),
  }

  const renderFields = (options_errors, add_params_errors) => {
    if (!fields || fields.length === 0 || Object.keys(fields).length === 0) {
      return null
    }

    PaymentStore.setOption(fio.name, f?.concat(' ', i, ' ', o))

    return (
      <>
        {customFields && customFields(options_errors)}
        <ListPicker
          testID="region"
          errorText={options_errors?.gns_oblast_code}
          items={getItems(oblast.data)}
          label={oblast.label}
          value={getValue(oblast.name_for_code, oblast.name_for_text)}
          onChange={(val) => {
            PaymentStore.setOption(oblast.name_for_code, val.id)
            PaymentStore.setOption(oblast.name_for_text, val.name)
            clearItems(PaymentStore, oblast.name_for_code)
          }}
          onClearPress={() => {
            PaymentStore.deleteOption(oblast.name_for_code)
            PaymentStore.deleteOption(oblast.name_for_text)
            clearItems(PaymentStore, oblast.name_for_code)
          }}
        />
        <Spacer />

        <ListPicker
          testID="raion"
          errorText={options_errors?.gns_raion_code}
          disabled={!getItems(raion.data, raion.name_for_code).length}
          items={getItems(raion.data, raion.name_for_code)}
          label={raion.label}
          value={getValue(raion.name_for_code, raion.name_for_text)}
          onChange={(val) => {
            PaymentStore.setOption(raion.name_for_code, val.id)
            PaymentStore.setOption(raion.name_for_text, val.name)
            clearItems(PaymentStore, raion.name_for_code)
          }}
          onClearPress={() => {
            PaymentStore.deleteOption(raion.name_for_code)
            PaymentStore.deleteOption(raion.name_for_text)
            clearItems(PaymentStore, raion.name_for_code)
          }}
        />
        <Spacer />
        <ListPicker
          testID="aimak"
          errorText={options_errors?.gns_aimak_code}
          disabled={!getItems(aimak.data, aimak.name_for_code).length}
          items={getItems(aimak.data, aimak.name_for_code)}
          label={aimak.label}
          value={getValue(aimak.name_for_code, aimak.name_for_text)}
          onChange={(val) => {
            PaymentStore.setOption(aimak.name_for_code, val.id)
            PaymentStore.setOption(aimak.name_for_text, val.name)
          }}
          onClearPress={() => {
            PaymentStore.deleteOption(aimak.name_for_code)
            PaymentStore.deleteOption(aimak.name_for_text)
          }}
        />
        <Spacer />
        <Input
          testID="lastName"
          errorText={add_params_errors?.f}
          label={'last_name'}
          value={f}
          onChangeText={(val) => {
            setF(val)
          }}
        />
        <Spacer />
        <Input
          testID="firstName"
          errorText={add_params_errors?.i}
          label={'name'}
          value={i}
          onChangeText={(val) => {
            setI(val)
          }}
        />
        <Spacer />
        <Input
          testID="surName"
          label={'patronymic'}
          value={o}
          onChangeText={(val) => {
            setO(val)
          }}
        />
        <Spacer />
      </>
    )
  }

  return (
    <FormInput
      {...props}
      source={source}
      customFields={renderFields}
      valid_fields={valid_fields}
      add_params={{ f, i }}
      add_param_valid_fields={add_param_valid_fields}
    />
  )
})

export default (isAgent) => {
  if (isAgent) {
    return {
      steps: 3,
      [FORM.INITIAL]: FormGNSInitial,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormDone,
      [FORM.FAIL]: FormFail,
    }
  }
  return {
    steps: 4,
    [FORM.INITIAL]: FormGNSInitial,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
