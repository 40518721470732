import { MenuItem } from '@ui-kitten/components'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FlatList, TouchableOpacity, View } from 'react-native'
import { colors } from '@pay24/common'
import { Input } from './Input'
import Modal from './Modal'
import Icon from './Icon'

const MONTHS = Array(12)
  .fill(0)
  .map((_, month) => {
    const m = moment().set({ month })
    const name = m.format('MMMM')
    return {
      value: m.month(),
      name: name[0].toUpperCase() + name.slice(1),
    }
  })

const YEARS = Array(6)
  .fill(0)
  .map((_, year) => moment().subtract(year, 'years').year())

interface MonthsPickerProperty {
  value: string
  label?: string
  onChange: (val) => void
}

export default function MonthsPicker({
  value,
  label,
  onChange,
}: MonthsPickerProperty) {
  const [visible, setVisible] = useState(false)
  const valueText = moment(value).format('MMMM YYYY')

  return (
    <>
      <TouchableOpacity onPress={() => setVisible(true)}>
        <Input
          value={valueText[0].toUpperCase() + valueText.slice(1)}
          label={label}
          disabled
          clearable={false}
          accessoryRight={
            <Icon name="calendar" color={colors.metal} size={20} />
          }
        />
      </TouchableOpacity>
      <DateModal
        visible={visible}
        onClose={() => setVisible(false)}
        onChange={onChange}
        date={value}
      />
    </>
  )
}

interface DateModalProperty {
  visible: boolean
  onClose: () => void
  onChange: (val) => void
  date: string
}

const ITEM_HEIGHT = 43

const itemLayout = (data, index) => ({
  length: ITEM_HEIGHT,
  offset: ITEM_HEIGHT * index,
  index,
})

function Years({
  value,
  onChange,
}: {
  value: number
  onChange: (value: number) => void
}) {
  const [mounted, setMounted] = useState(0)
  useEffect(() => setMounted((v) => (v ? v : value)), [value])

  const ref = useRef<FlatList>(null)

  useEffect(() => {
    if (!mounted) return
    let scroll_index = YEARS.indexOf(mounted)
    if (scroll_index > 2) scroll_index -= 2
    ref.current?.scrollToIndex({ animated: true, index: scroll_index })
  }, [mounted])

  return mounted ? (
    <FlatList
      ref={ref}
      data={YEARS}
      extraData={value}
      renderItem={({ item: year }) => (
        <View>
          <MenuItem
            title={`${year}`}
            style={value === year ? { backgroundColor: colors.cyan } : null}
            onPress={() => onChange(year)}
          />
        </View>
      )}
      getItemLayout={itemLayout}
      initialNumToRender={10}
      maxToRenderPerBatch={20}
      keyExtractor={(year) => `${year}`}
    />
  ) : (
    <></>
  )
}

function Months({ value, onChange }: { value: any; onChange: (val) => void }) {
  const [mounted, setMounted] = useState<number>(0)
  useEffect(() => setMounted((v) => (v ? v : value)), [value])
  const ref = useRef<FlatList>(null)

  useEffect(() => {
    if (!mounted) return
    let scroll_index = MONTHS.findIndex((m) => m.value === mounted)
    if (scroll_index > 2) scroll_index -= 2
    ref.current?.scrollToIndex({ animated: true, index: scroll_index })
  }, [mounted])

  return (
    <FlatList
      ref={ref}
      data={MONTHS}
      extraData={value}
      renderItem={({ item: month }) => (
        <View>
          <MenuItem
            title={`${month.name}`}
            style={
              value === month.value ? { backgroundColor: colors.cyan } : null
            }
            onPress={() => {
              onChange(month.value)
            }}
          />
        </View>
      )}
      getItemLayout={itemLayout}
      initialNumToRender={10}
      maxToRenderPerBatch={20}
      keyExtractor={(year) => `${year.value}`}
    />
  )
}

const DateModal = function DateModal({
  visible,
  onClose,
  onChange,
  date,
}: DateModalProperty) {
  const setYear = useCallback(
    (year) => onChange(moment(date).clone().set({ year })),
    [date, onChange],
  )

  const setMonth = useCallback(
    (month) => onChange(moment(date).clone().set({ month })),
    [date, onChange],
  )

  return (
    <Modal visible={visible} onClose={onClose} title="date">
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: 320,
          height: 200,
        }}
      >
        <View style={{ flex: 1 }}>
          <Years value={moment(date).year()} onChange={setYear} />
        </View>
        <View style={{ flex: 1 }}>
          <Months value={moment(date).month()} onChange={setMonth} />
        </View>
      </View>
    </Modal>
  )
}
