import { observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { TextInputProps } from 'react-native'
import { colors, hooks } from '@pay24/common/src'
import { Input } from './Input'
import NativeInput, {
  isModelName,
  NativeInputProps,
  TEditable,
} from './NativeInput'

const { useTheme } = hooks

interface DecimalInputProps<T extends TEditable<T>>
  extends NativeInputProps<T> {}

function isDecimalEditable<T extends TEditable<T>>(
  props: DecimalInputProps<T> | TextInputProps,
): props is DecimalInputProps<T> {
  return isModelName<T, DecimalInputProps<T>>(props)
}

export const DecimalInput = observer(function DecimalInput<
  T extends TEditable<T>,
>(props: DecimalInputProps<T> | TextInputProps) {
  let { value = '' } = props

  const { isLight } = useTheme()

  let textInputProps
  if (isModelName(props)) {
    const { model, name, ...rest } = props
    textInputProps = rest
  } else {
    textInputProps = props
  }

  if (isDecimalEditable(props)) {
    const { model, name } = props
    if (model && name) {
      value = model[name]?.toString() || '0'
    }
  }

  const { onChangeText } = props

  const _onChangeText = useCallback(
    (v: string) => {
      v = String(v)
      v = v.replace(/[^\d.]+/g, '')
      if (v[0] === '.') {
        v = v.replace(/^\./, '')
      }
      if (v[0] === '0' && v.length > 1 && v[1] !== '.') {
        v = v.replace('0', '')
      }
      if (v.split('.').length > 2) v = v.replace(/\.+$/, '')
      if (isDecimalEditable(props)) {
        const { model, name } = props
        model?.setValue(name, v)
      }
      onChangeText && onChangeText(v)
    },
    [onChangeText, props],
  )

  if (textInputProps.outline) {
    return (
      <Input
        {...textInputProps}
        disabled={props.editable === false}
        style={[
          textInputProps.style,
          {
            padding: 0,
            color: isLight ? colors.black : colors.white,
            fontSize: textInputProps.style?.fontSize || 16,
          },
        ]}
        keyboardType="numeric"
        label={textInputProps.label}
        value={value}
        onChangeText={_onChangeText}
      />
    )
  }

  return (
    <NativeInput
      {...props}
      value={value}
      keyboardType="numeric"
      onChangeText={_onChangeText}
    />
  )
})
