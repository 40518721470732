import type { ComponentType } from 'react'
import React, { Suspense } from 'react'
import { Spinner } from './spinner'

export const withLazy =
  <P extends NonNullable<unknown>>(Component: ComponentType<P>) =>
  (props: P) => {
    return (
      <Suspense fallback={<Spinner />}>
        <Component {...props} />
      </Suspense>
    )
  }
