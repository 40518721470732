import {
  FormCheck,
  FormDone,
  FormFail,
  FormInput,
  Pay3D,
} from './DefaultTemplate'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import Image from '../Image'
import Modal from '../Modal'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { View } from 'react-native'

const FormJivayaVodaDone = observer(({ navigation }) => {
  const [visible, setVisible] = useState(true)

  return (
    <View>
      <FormDone navigation={navigation} />
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <Image
          source={{ uri: 'https://agent.pay24.asia/img/common/vodomat.jpg' }}
          style={{
            borderRadius: 5,
            width: 400,
            height: 300,
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
          }}
          resizeMode={'contain'}
        />
      </Modal>
    </View>
  )
})

export default (isAgent = false) => {
  if (isAgent) {
    return {
      steps: 3,
      [FORM.INITIAL]: FormInput,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormJivayaVodaDone,
      [FORM.FAIL]: FormFail,
    }
  }

  return {
    steps: 4,
    [FORM.INITIAL]: FormInput,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormJivayaVodaDone,
    [FORM.FAIL]: FormFail,
  }
}
