import React, { CSSProperties, FC } from 'react'

interface IRenderHtml {
  source: string
  styles?: CSSProperties
}

const HTMLRender: FC<IRenderHtml> = ({ source, styles }) => {
  return <div dangerouslySetInnerHTML={{ __html: source }} style={styles} />
}

export default HTMLRender
