import { colors } from '@pay24/common'
import { FormCheck, FormDone, FormFail, Pay3D } from './DefaultTemplate'
import { requisiteScheme } from './schemes'
import { amountPlaceholder } from './utils'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { PaymentStore } from '@pay24/common/src/store'
import { Button } from '../Button'
import { Input, InputModel } from '../Input'
import ListPicker from '../ListPicker'
import Spacer from '../Spacer'
import { Text } from '../Text'
import TextAutocomplete from '../TextAutocomplete'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { MaskService } from 'react-native-masked-text'
import { useTranslation } from '@pay24/common/src/translate'

export const mbank_items = [
  {
    id: 1,
    name: 'По номеру телефона КР',
    mask: '996 (***) ** ** **',
    placeholder: '996 (___) __ __ __',
  },
  {
    id: 2,
    name: 'По номеру телефона РФ или РК',
    mask: '7**********',
    placeholder: '7__________',
  },
  {
    id: 3,
    name: 'По расчётному счёту',
    mask: '103* **** **** ****',
    placeholder: '103_ ____ ____ ____',
  },
]

export const mbank_maskInput = (v, item) => {
  let value = MaskService.toMask('custom', v, {
    mask: item?.mask,
    translation: {
      '#': function (val) {
        if (val === ' ') {
          return val
        }
        return null
      },
      '9': function (val) {
        return [' ', '#', ',', '.', '!'].indexOf(val) >= 0 ? val : null
      },
    },
  })
  value = (value || '').replace(/[^\d-+_*#,.\\/|]/g, '')
  return value
}

const FormInitial = observer((props) => {
  const [item, setItem] = useState<any>()
  const { service, source, valid_fields, comments } = props
  const { payData, isAgent } = PaymentStore
  const { t } = useTranslation()

  useEffect(() => {
    if (payData.requisite.startsWith('103')) setItem(mbank_items[2])
    else if (payData.requisite.startsWith('7')) setItem(mbank_items[1])
    else setItem(mbank_items[0])
  }, [])

  const formik = useFormik({
    initialValues: {
      payData,
      isAgent,
      source,
    },
    enableReinitialize: true,
    validationSchema: requisiteScheme(service, valid_fields),
    onSubmit: (values) => {
      payData.setValue('service_id', service.id)
      payData.setValue('service_code', service.type)
      payData.setValue('service_name', service.name)
      payData.setValue('form_scheme', service?.data?.form_scheme)
      PaymentStore.requisiteCheck(values.source).then(() => {
        PaymentStore.setPaymentState(FORM.CHECK)
        PaymentStore.setStep(2)
      })
    },
  })

  return (
    <>
      {!PaymentStore.isAgent && formik.errors.source ? (
        <Text
          style={{
            color: colors.red,
            marginTop: 3,
            marginLeft: 3,
            fontSize: 10,
          }}
        >
          {formik.errors.source}
        </Text>
      ) : null}
      <Spacer />
      <ListPicker
        testID="select_mbank_items"
        items={mbank_items}
        label={'Введите'}
        value={item}
        onChange={(val) => {
          setItem(val)
          payData.setValue('requisite', '')
        }}
        clearable={false}
      />
      <Spacer />
      <Input
        testID="requisite"
        errorText={formik.errors.payData ? formik.errors.payData.requisite : ''}
        label={'requisite'}
        keyboardType={'phone-pad'}
        inputMask={item?.mask}
        placeholder={item?.placeholder}
        value={payData.requisite}
        onChangeText={(val) =>
          payData.setValue('requisite', mbank_maskInput(val, item))
        }
      />
      <Spacer />
      <InputModel
        testID="amount"
        label="sum"
        keyboardType="numeric"
        name="amount"
        value={formik.values.payData.amount?.toString()}
        errorText={formik.errors.payData ? formik.errors.payData.amount : ''}
        model={formik.values.payData}
        placeholder={amountPlaceholder(service)}
      />
      <Spacer />
      <TextAutocomplete
        testID="comment"
        model={formik.values.payData}
        name="comment"
        label="comment"
        placeholder="optional"
        options={comments}
      />
      <Spacer />
      <Button
        testID="nextBtn"
        disabled={PaymentStore.buttonBlock}
        onPress={() => {
          formik.handleSubmit()
        }}
      >
        {t('next')}
      </Button>
    </>
  )
})

export default (isAgent) => {
  if (isAgent) {
    return {
      steps: 3,
      [FORM.INITIAL]: FormInitial,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormDone,
      [FORM.FAIL]: FormFail,
    }
  }
  return {
    steps: 4,
    [FORM.INITIAL]: FormInitial,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
