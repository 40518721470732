import { api, utils, hooks } from '@pay24/common'
import { PagesProperty } from '@pay24/common/src/hooks'
import React, { forwardRef, LegacyRef, useEffect, useState } from 'react'
import { Pressable, View } from 'react-native'
import { DottedDivider, ItemContainer, NoData } from '../../Components'
import Icon from '../../Icon'
import { ChequeContent } from '../../PaymentCheque'
import { useShare } from '../../Share'
import Spacer from '../../Spacer'
import { PageName } from '../types'

const { isWeb, getImagePath, FetchBlob } = utils
const { pay24, agent } = api
const { useTheme } = hooks

interface RecordProps {
  amount: number
  data: any
  dc: string
  guid: string
  id: number
  local_id: string
  payment_method: string
  rec_date: string
  status: string
  user_id: number | string
}

interface ShotContainerProperty {
  page: PagesProperty<PageName, any>
  handlePrint: () => void
}

interface DetailProperty {
  record: RecordProps
  forwards?: any
}

const ShotContainer = forwardRef(
  (
    { page, handlePrint }: ShotContainerProperty,
    ref: LegacyRef<View> | any,
  ) => {
    const [print, setPrint] = useState(false)
    const [chequeImg, setChequeImg] = useState<string>('')
    const [detail, setDetail] = useState<DetailProperty | null>(null)
    const share = useShare()
    const { primary } = useTheme()

    const fetchChequeData = () => {
      const req = pay24.recordDetails({
        record_id: page?.params?.id,
      })
      req.then((r) => setDetail(r))
      return req.cancel
    }

    useEffect(() => {
      if (page?.params?.id) {
        return fetchChequeData()
      }
    }, [page?.params?.id])

    useEffect(() => {
      if (detail?.record?.data && !detail?.record?.data?.cheque_path) {
        agent.getChequeImage(detail.record.guid).then((res) => {
          setChequeImg(res.cheque_path)
        })
      }
    }, [detail?.record?.data, detail?.record?.guid])

    const disable = !detail?.record?.data?.cheque_path
      ? !chequeImg
      : !detail?.record?.data?.cheque_path

    const shareCheque = () => {
      const url = getImagePath(detail?.record?.data?.cheque_path || chequeImg)
      FetchBlob.get(url).then((data) => share.onShare({ url: data }))
    }

    return detail ? (
      <>
        <ItemContainer style={{ padding: 12 }}>
          <ChequeContent
            record={detail.record}
            forwards={detail.forwards}
            print={print}
            ref={ref}
          />
          <DottedDivider />
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Pressable
              style={{ padding: 10, marginRight: 8 }}
              disabled={disable}
              onPress={shareCheque}
            >
              <Icon
                name="share-variant-outline"
                color={primary}
                style={{ opacity: disable ? 0.3 : 1 }}
              />
            </Pressable>
            {isWeb ? (
              <Pressable
                style={{ padding: 10 }}
                onPress={() => {
                  setPrint(true)
                  setTimeout(() => {
                    handlePrint()
                  }, 100)
                }}
              >
                <Icon name="printer-outline" color={primary} />
              </Pressable>
            ) : null}
          </View>
        </ItemContainer>
        {share.component}
      </>
    ) : (
      <NoData />
    )
  },
)

export default ShotContainer
