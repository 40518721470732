import { useMemo } from 'react'

const isValid = value => isFinite(value) && value >= 0

/**
 * @param point {string}
 * @param values {{xs?:number,sm?:number,md?:number,lg?:number,xl?:number}}
 * @return {number}
 */
export default function useBreakPointValue (point, values) {
  const { xs, sm, md, lg, xl } = values
  return useMemo(() => ({
    xl: [xl, lg, md, sm, xs],
    lg: [lg, md, sm, xs],
    md: [md, sm, xs],
    sm: [sm, xs],
    xs: [xs],
  })[point]?.find(isValid) || 0, [point, xs, sm, md, lg, xl])
}
