import { colors, store } from '@pay24/common'
import { charge24 as charge24Api } from '@pay24/common/src/api'
import { useTranslation } from '@pay24/common/src/translate'
import { amountFormatCoins } from '@pay24/common/src/utils'
import { DecimalInput } from '../DecimalInput'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { Linking, View } from 'react-native'
import { ItemContainer, TextDetail } from '../Components'
import Icon from '../Icon'
import PhoneInput from '../PhoneInput'
import Spacer from '../Spacer'
import { Button, SecondaryButton } from '../Button'
import { Description, Title } from '../Text'
import { Loading } from '../Loading'
import { parsePhoneNumber } from 'libphonenumber-js'

type Status = 'success' | 'fail' | 'pending' | ''

const { PaymentStore } = store

export const KaspiForm = observer(function KaspiForm() {
  const { payData } = PaymentStore
  const nationalNumber = payData.requisite
    ? parsePhoneNumber(payData.requisite).nationalNumber
    : ''
  const [requisite, setRequisite] = useState(nationalNumber)
  const [amount, setAmount] = useState('')
  const [txnId, setTxnId] = useState('')
  const [showInputError, setShowInputError] = useState(false)
  const [status, setStatus] = useState<Status>('')
  const [clicked, setClicked] = useState(false)
  const { t } = useTranslation()

  const isFocused = useIsFocused()

  const onPay = useCallback(() => {
    setShowInputError(true)
    if (parseInt(amount) < 1000) return
    setClicked(true)
    charge24Api
      .kaspiPay({ requisite, amount: parseInt(amount) })
      .then(async (resp) => {
        setTxnId(resp.txn_id)
        setStatus('pending')
        if (resp.redirectUrl) {
          await Linking.openURL(resp.redirectUrl)
        }
      })
      .finally(() => setClicked(false))
  }, [amount, requisite])

  useEffect(() => {
    if (!isFocused || !txnId) return
    const interval = setInterval(() => {
      charge24Api.kaspiCheckStatus(txnId).then((resp) => {
        switch (resp.status) {
          case 'success':
            clearInterval(interval)
            setStatus(resp.status)
            break
          case 'fail':
            clearInterval(interval)
            setStatus(resp.status)
            break
        }
      })
    }, 1e3)
    return () => {
      clearInterval(interval)
    }
  }, [txnId, isFocused])

  const disabled = clicked || !amount || requisite.length < 10

  if (status === 'pending') {
    return <Loading />
  }

  if (status === 'success') {
    return <SuccessForm requisite={requisite} amount={amount} />
  }

  if (status === 'fail') {
    return <FailForm onBack={() => setStatus('')} />
  }

  return (
    <>
      <Spacer />
      <PhoneInput
        label={t('input_phone_number')}
        value={requisite}
        onChangeText={setRequisite}
        countryCode={'kz'}
        countryCodeDisabled
        flag={false}
      />
      <Spacer />
      <DecimalInput
        label={t('amount')}
        outline
        onChangeText={setAmount}
        value={amount}
        placeholder={'От 1000 тенге'}
        errorText={
          showInputError && parseInt(amount) < 1000
            ? 'Минимум 1000 тенге'
            : undefined
        }
      />
      <Spacer />
      <Button disabled={disabled} onPress={onPay}>
        {t('pay')}
      </Button>
    </>
  )
})

interface SuccessFormProps {
  requisite: string
  amount: string
}

function SuccessForm({ requisite, amount }: SuccessFormProps) {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const onClose = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('home' as never, {} as never)
    }
  }

  return (
    <>
      <View style={{ marginVertical: 6, padding: 20 }}>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginVertical: 10,
          }}
        >
          <Icon
            name="check-circle-outline"
            size={48}
            color={colors.darkGreen}
          />
          <Spacer />
          <Title testID="paymentAcceptedMsg" color={colors.darkGreen}>
            {t('Ваш платеж принят').toUpperCase()}
          </Title>
          <Description>Спасибо за доверие!</Description>
        </View>
      </View>
      <ItemContainer style={{ padding: 12 }}>
        <TextDetail name={'service'} value={'CHARGE 24 - пополнение счета'} />
        <TextDetail name={'requisite'} value={requisite} />
        <TextDetail name={'amount'} value={amountFormatCoins(amount, 'KZT')} />
        <Spacer />
        <TextDetail name={'commission'} value={amountFormatCoins(0, 'KZT')} />
        <TextDetail
          name={'amount_for_credit'}
          value={amountFormatCoins(amount, 'KZT')}
        />
        <Spacer />
        <TextDetail name={'status'} value={t('success')} status={'success'} />
      </ItemContainer>
      <Spacer />
      <SecondaryButton title={t('to_complete')} onPress={onClose} />
    </>
  )
}

function FailForm({ onBack }) {
  const { t } = useTranslation()
  return (
    <>
      <ItemContainer style={{ paddingVertical: 20, marginVertical: 15 }}>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon name="alert-outline" size={48} color={colors.red} />
          <Spacer />
          <Title color={colors.red}>{t('payment_failed')}</Title>
          <Spacer />
        </View>
      </ItemContainer>
      <Button onPress={onBack}>{t('back')}</Button>
    </>
  )
}
