import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { View } from 'react-native'

const dropzoneOptions = {
  accept: { 'video/*': ['*'] },
  maxFiles: 1,
  multiple: false,
}

interface Props {
  onChange?: (file, fileName) => void
  children: any
  style?: any
}

export default ({ onChange, children, style }: Props) => {
  const onDrop = useCallback(
    async (files) => {
      if (files && files.length) {
        const file = files[0]
        if (onChange) {
          onChange(file, file.name)
        }
      }
    },
    [onChange],
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...dropzoneOptions,
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <View style={style}>{children}</View>
    </div>
  )
}
