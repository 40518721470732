import { Layout } from '@ui-kitten/components'
import React, {
  forwardRef,
  ReactNode,
  Ref,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Content } from './Components'
import Toolbar from './Toolbar'

import { utils, hooks } from '@pay24/common'

const { useTheme } = hooks
const { detectDevice } = utils

const device = detectDevice()

interface IScreen {
  children: React.ReactNode
  toolbar: React.DetailedReactHTMLElement<any, any>
  title?: string | ReactNode
  menu?: boolean
  back?: boolean
  onBackPress?: () => void
  onLoadMore?: () => void
  loading?: boolean
}

const Screen = forwardRef(
  (
    {
      children,
      toolbar,
      title,
      menu,
      back,
      onBackPress,
      onLoadMore,
      loading,
    }: IScreen,
    ref: Ref<any>,
  ) => {
    const [lastScrollTop, setLastScrollTop] = useState(0)
    const [topPos, setTopPos] = useState('0')
    const { isLight } = useTheme()

    let tb = useMemo(() => {
      if (!toolbar && (title || menu || back)) {
        return (
          <Toolbar
            menu={menu}
            onBackPress={onBackPress}
            back={back}
            title={title}
          />
        )
      } else {
        if (!toolbar) return null
        return React.cloneElement(toolbar, {
          ...toolbar?.props,
          onPress: () => {
            if (myRef) {
              myRef.current.scrollIntoView({ behavior: 'smooth' })
            }
          },
        })
      }
    }, [back, menu, onBackPress, title, toolbar])

    const handleScroll = ({ target }) => {
      const scrollTop = target.scrollTop

      //100 - height of header
      if (lastScrollTop > 100) {
        setTopPos('-100px')
      } else {
        setTopPos('0')
      }
      if (lastScrollTop > scrollTop) {
        setTopPos('0')
      }
      setLastScrollTop(scrollTop)
      const bottom =
        Math.floor(target.scrollHeight - scrollTop) === target.clientHeight
      if (bottom) {
        if (onLoadMore) {
          onLoadMore()
        }
      }
    }

    const marginBottom = useMemo(() => {
      return device === 'ios' ? 110 : device === 'android' ? 85 : 0
    }, [device])

    const myRef = useRef() as React.MutableRefObject<HTMLInputElement>

    return (
      <Layout level={isLight ? '3' : '1'}>
        <div ref={ref} className="screen-scroll" onScroll={handleScroll}>
          {!!tb && (
            <div
              style={{
                position: 'absolute',
                zIndex: '1000',
                width: '100%',
                top: topPos,
                transition: 'top 0.5s',
              }}
            >
              {tb}
            </div>
          )}
          <div ref={myRef} />
          <Content style={{ marginBottom }}>
            {loading ? null : children}
          </Content>
        </div>
      </Layout>
    )
  },
)

export default Screen
