import { OverflowMenu } from '@ui-kitten/components'
import React, { useMemo, useState } from 'react'

interface IDropdown {
  children: any
  anchor: any
  placement?: string
  setChevron?: (b: boolean) => void
  toggle?: () => void
  menuVisible?: boolean
  [x: string]: any
}

export default ({
  children,
  anchor,
  placement = 'bottom end',
  setChevron,
  menuVisible = true,
  toggle,
  ...rest
}: IDropdown) => {
  const [visible, setVisible] = useState(false)

  const anch = useMemo(() => {
    if (!anchor) return null
    return React.cloneElement(anchor, {
      ...anchor?.props,
      onPress: () => {
        if (anchor.props.onPress) {
          anchor.props.onPress()
        }
        if (toggle) {
          toggle()
        }
        setVisible(true)
        if (setChevron) {
          setChevron(true)
        }
      },
    })
  }, [anchor])

  if (!anch) return null

  return (
    <OverflowMenu
      anchor={() => anch}
      visible={!menuVisible ? menuVisible : visible}
      placement={placement}
      onBackdropPress={() => {
        setVisible(false)
        if (setChevron) {
          setChevron(false)
        }
        if (toggle) {
          toggle()
        }
      }}
      {...rest}
    >
      {React.Children.map(children, (child) => {
        if (!child) return null
        return React.cloneElement(child, {
          ...child?.props,
          onPress: () => {
            setVisible(false)
            if (child.props?.onPress) {
              child.props.onPress()
            }
          },
        })
      })}
    </OverflowMenu>
  )
}
