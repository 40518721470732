import { charge24 as charge24Api } from '@pay24/common/src/api'
import { useTheme } from '@pay24/common/src/hooks'
import rootStore from '@pay24/common/src/store/RootStore'
import { parse } from 'query-string'
import React, { useEffect, useState } from 'react'
import Payment3D from '../payment3D'
import { Text } from '../Text'

export function Bereke({ onSave }) {
  let [params, setParams] = useState<any>(null)
  let [status, setStatus] = useState('init')
  let [error, setError] = useState('Ошибка')
  useEffect(() => {
    charge24Api
      .berekePay({
        id: 'bereke',
        oauth_id: rootStore.user?.id,
        access_token: rootStore.token,
      })
      .then((e) => {
        setParams(e)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  const onSavePress = (data) => {
    let saveParams = parse(data)
    let st = saveParams.status
    if (st === 'success') {
      onSave({ bereke: true })
    }
  }

  const onFail = (data) => {
    setStatus('failed')
    let paramsFail = parse(data)
    if (typeof paramsFail.error === 'string') setError(paramsFail.error)
  }

  return (
    <>
      {status === 'init' && (
        <Payment3D {...params} onSuccess={onSavePress} onFail={onFail} />
      )}
      {status === 'failed' && <Text>{error}</Text>}
    </>
  )
}
