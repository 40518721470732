import { pay24 as pay24Api } from '@pay24/common/src/api'
import colors from '@pay24/common/src/colors'
import { AccountStore, AgentStore, PaymentStore } from '@pay24/common/src/store'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { amountFormatCurrency, isIos, isMobile } from '@pay24/common/src/utils'
import { useTheme } from '@pay24/common/src/hooks'
import { requisiteScheme } from './schemes'
import { amountPlaceholder, categoryId, getItems, maskOfInput } from './utils'
import { AccountCard } from '../account'
import { Button } from '../Button'
import { Divider, DotsDropdown, ItemContainer } from '../Components'
import ContactPicker from '../ContactPicker'
import Icon from '../Icon'
import { Input, InputModel } from '../Input'
import ListPicker from '../ListPicker'
import PreviewCheck from '../PreviewCheck'
import Spacer from '../Spacer'
import { Description, Text, Title } from '../Text'
import TextAutocomplete from '../TextAutocomplete'
import { MenuItem } from '@ui-kitten/components'
import { FormikProps, useFormik } from 'formik'
import { observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import React, { useEffect, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import Payment3D from '../payment3D'
import { i18n, useTranslation } from '@pay24/common/src/translate'

export interface FormBaseValues {
  options: {
    explanation: string
  }
  payData: {
    requisite: string
    amount: number | null
  }
  isAgent: boolean
  source: object
  add_params: any
}

export const FormInput = observer((props) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const {
    service,
    source,
    customFields,
    // поле схемы для дополнительных полей услуги (service.data.fields)
    valid_fields,
    // переменные из наследуемого от этого класса
    add_params,
    // поле схемы для переменных из наследуемого от этого класса
    add_param_valid_fields,
    comments = [],
    navigation,
    selectRequisite = true,
    selectComment = true,
    paymentCheckApi = undefined,
  } = props
  const { payData, isAgent } = PaymentStore
  const options = getSnapshot<any>(payData.options)

  const formik: FormikProps<FormBaseValues> = useFormik<FormBaseValues>({
    initialValues: {
      payData,
      options,
      isAgent,
      source,
      add_params,
    },
    enableReinitialize: true,
    // вызывается общая схема
    validationSchema: requisiteScheme(
      service,
      valid_fields,
      add_param_valid_fields,
      selectRequisite,
    ),
    onSubmit: (values, { setErrors }) => {
      if (paymentCheckApi) return paymentCheckApi()

      const should_validate =
        categoryId(service) !== 1 &&
        !service.placeholder?.trim() &&
        categoryId(service) !== 999999 &&
        categoryId(service) !== 100000
      if (should_validate) {
        const validate = new RegExp(`^${service.validation}$`)
        if (!validate.test(payData.requisite)) {
          setErrors({
            payData: { requisite: t('check_your_requisite') },
          })
          return
        }
      }
      let requisite = payData.requisite.replace(/\s/g, '')

      requisite =
        categoryId(service) === 95
          ? requisite.replaceAll(/[^\w\s]/gi, '')
          : requisite

      payData.setValue('service_id', service.id)
      payData.setValue('service_code', service.type)
      payData.setValue('service_name', service.name)
      payData.setValue('requisite', requisite)
      payData.setValue('form_scheme', service?.data?.form_scheme)
      PaymentStore.requisiteCheck(values.source).then(() => {
        PaymentStore.setPaymentState(FORM.CHECK)
        PaymentStore.setStep(2)
      })
    },
  })

  const getValue = (key, value) => {
    if (!payData.options) return null
    const k = payData.getOptions(key)
    const v = payData.getOptions(value)
    return { id: k, name: v }
  }

  // автоматическое добавление дополнительных полей услуги (нужно, если нету под неё шаблона)
  const renderFields = () => {
    const fields = service.data && service.data.fields
    if (!fields || fields.length === 0 || Object.keys(fields).length === 0)
      return null

    const renderField = (f, i) => {
      if (f.type === 'select') {
        let flag = f.name_for_code
        return (
          <ListPicker
            testID={`SelectComponent${i}`}
            items={getItems(f.data, flag)}
            key={i}
            label={f.label}
            value={getValue(f.name_for_code, f.name_for_text)}
            disabled={!getItems(f.data, flag).length}
            onChange={(val) => {
              PaymentStore.setOption(f.name_for_code, val.id)
              PaymentStore.setOption(f.name_for_text, val.name)
            }}
            onClearPress={() => {
              PaymentStore.setOption(f.name_for_code, null)
              PaymentStore.setOption(f.name_for_text, null)
            }}
          />
        )
      }
      if (f.type === 'double_select') {
        return (
          <ListPicker
            testID={`double_select${i}`}
            items={getItems(f.data)}
            key={i}
            label={f.label}
            value={getValue('sf_selected_type_id', 'sf_selected_type_text')}
            onChange={(val) => {
              PaymentStore.setOption('sf_selected_type_id', val.id)
              PaymentStore.setOption('sf_service_id', val.ext)
              PaymentStore.setOption('sf_selected_type_text', val.name)
            }}
            onClearPress={() => {
              PaymentStore.setOption('sf_selected_type_id', null)
              PaymentStore.setOption('sf_service_id', null)
              PaymentStore.setOption('sf_selected_type_text', null)
            }}
          />
        )
      }
      return (
        <Input
          testID={`type_text${i}`}
          key={i}
          label={f.label?.replace(/:$/, '')}
          value={payData.options ? payData.getOptions(f.name) : ''}
          onChangeText={(val) => {
            PaymentStore.setOption(f.name, val)
          }}
        />
      )
    }

    return fields.map((f, i) => {
      return (
        <View key={i}>
          {renderField(f, i)}
          <Spacer />
        </View>
      )
    })
  }

  const templateSum = [50, 100, 200, 500, 1000]

  return (
    <>
      {!PaymentStore.isAgent && formik.errors.source ? (
        <Text
          style={{
            color: colors.red,
            marginTop: 3,
            marginLeft: 3,
            fontSize: 10,
          }}
        >
          {formik.errors.source}
        </Text>
      ) : null}
      <Spacer />
      {selectRequisite ? (
        <>
          <InputModel
            testID="requisite"
            model={formik.values.payData}
            name="requisite"
            value={payData.requisite}
            errorText={
              formik.errors.payData ? formik.errors.payData.requisite : ''
            }
            label={service?.data?.requisite_message || 'requisite'}
            inputMask={
              categoryId(service) === 1
                ? maskOfInput(service)?.mask
                : service?.placeholder?.replace(/ /g, '') !== ''
                ? service?.placeholder?.replace(/_/g, '9')
                : ''
            }
            keyboardType={
              (!isIos && service?.data.keyboard_type === 'numpad') ||
              categoryId(service) === 1
                ? 'phone-pad'
                : 'default'
            }
            placeholder={
              categoryId(service) === 1
                ? maskOfInput(service)?.placeholder
                : service?.placeholder
            }
            rightComponent={
              <DotsDropdown placement="bottom end">
                {isMobile && categoryId(service) === 1 ? (
                  <MenuItem
                    testID="from_contacts"
                    title={<Text>Из контактов</Text>}
                    onPress={() => setVisible(true)}
                  />
                ) : null}
                <MenuItem
                  testID="from_favorites"
                  title={<Text>{t('from_favorites')}</Text>}
                  onPress={() => {
                    navigation.navigate('favorites', {
                      service_id: service.id,
                    })
                  }}
                />
              </DotsDropdown>
            }
          />
          <Spacer />
        </>
      ) : null}
      {customFields
        ? customFields(formik.errors.options, formik.errors.add_params)
        : renderFields()}
      <InputModel
        testID="moneyAmount"
        label="sum"
        keyboardType="numeric"
        name="amount"
        value={formik.values.payData.amount?.toString()}
        errorText={formik.errors.payData ? formik.errors.payData.amount : ''}
        model={formik.values.payData}
        placeholder={amountPlaceholder(service)}
        rightComponent={
          <DotsDropdown placement="bottom end">
            {templateSum.map((sum) => (
              <MenuItem
                key={sum}
                title={<Text>{sum}</Text>}
                onPress={() => payData.setValue('amount', sum)}
              />
            ))}
          </DotsDropdown>
        }
      />
      <Spacer />
      {selectComment ? (
        <>
          <TextAutocomplete
            testID="comment"
            model={formik.values.payData}
            name="comment"
            label="comment"
            placeholder="optional"
            options={comments}
          />
          <Spacer />
        </>
      ) : null}
      <Button
        testID="nextBtn"
        disabled={PaymentStore.buttonBlock}
        onPress={() => {
          formik.handleSubmit()
        }}
      >
        {i18n.t('next')}
      </Button>
      {isMobile && categoryId(service) === 1 ? (
        <ContactPicker
          onClose={() => setVisible(false)}
          show={visible}
          onChange={(val) => payData.setValue('requisite', val)}
        />
      ) : null}
    </>
  )
})

export const CheckItem = ({ title, model, name, currency = false }) => (
  <>
    {model?.[name] ? (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingVertical: 3,
          alignItems: 'center',
        }}
      >
        <Description>{i18n.t(title)}:</Description>
        <Text style={{ textAlign: 'right', fontWeight: 'bold', flex: 1 }}>
          {currency ? amountFormatCurrency(model?.[name]) : model?.[name]}
        </Text>
      </View>
    ) : null}
  </>
)

export const FormCheck = observer(
  ({ source, customFields, paymentApi, debit = true }) => {
    const { payData, buttonBlock, isAgent } = PaymentStore
    const { primary, isLight } = useTheme()
    const [checkVisible, setCheckVisible] = useState(false)

    return (
      <>
        <Spacer />
        <Text size={12} style={{ color: colors.metal }}>
          {source?.source !== 'NETEX'
            ? 'Информация о платеже'
            : 'Информация об обмене'}
        </Text>
        <Spacer />
        <ItemContainer testID="paymentInfo" style={{ padding: 20 }}>
          <CheckItem title="Абонент" model={payData} name={'customer_name'} />
          <CheckItem title="Реквизит" model={payData} name={'requisite'} />
          {customFields && customFields()}
          <CheckItem title="Сумма" model={payData} name={'amount'} currency />
          <Spacer />
          <CheckItem
            title="Комиссия платежа"
            model={payData}
            name={'paymentCommission'}
            currency
          />
          {!!payData.acquiringCommission && debit && (
            <CheckItem
              title="Комиссия эквайринга"
              model={payData}
              name={'acquiringCommission'}
            />
          )}
          {!!payData.commission && (
            <CheckItem
              title="Комиссия всего"
              model={payData}
              name={'commission'}
              currency
            />
          )}
          {!!payData.comment && (
            <CheckItem title="comment" model={payData} name={'comment'} />
          )}
          {debit && <CheckItem title="source" model={source} name="name" />}
          <Spacer />
          <Divider />
          <Spacer />
          <CheckItem
            title="К списанию"
            model={payData}
            name={'totalAmount'}
            currency
          />
          <Spacer />
          {isAgent ? (
            <TouchableOpacity
              testID="preview_cheque"
              onPress={() => setCheckVisible(true)}
              style={{
                alignSelf: 'center',
              }}
            >
              <Text color={primary}>{i18n.t('preview_cheque')}</Text>
            </TouchableOpacity>
          ) : null}
        </ItemContainer>
        <Spacer />
        <Button
          testID="nextBtn"
          disabled={buttonBlock}
          onPress={() => {
            const data = {
              amount: payData.amount,
              requisite: payData.requisite,
              service_name: payData?.service_name,
              service_id: payData.service_id,
              service_code: payData.service_code,
              form_scheme: payData?.form_scheme,
              options: getSnapshot(payData.options),
            }
            if (paymentApi) {
              paymentApi(data)
            } else {
              PaymentStore.payData.setValue('theme', isLight ? 'light' : 'dark')
              PaymentStore.pay(source, () => {
                PaymentStore.setStep(PaymentStore.step + 1)
              })
            }
          }}
        >
          {i18n.t('next')}
        </Button>

        <PreviewCheck
          item={payData}
          visible={checkVisible}
          onClose={() => setCheckVisible(false)}
        />
      </>
    )
  },
)

interface FormDoneProperty {
  navigation?: any
}

export const FormDone = observer(({ navigation }: FormDoneProperty) => {
  const { payData } = PaymentStore
  const { t } = useTranslation()

  const onClose = () => {
    PaymentStore.resetPayData()
    payData.reset()
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('home')
    }
  }

  return (
    <View>
      <View style={{ marginVertical: 6, padding: 20 }}>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginVertical: 10,
          }}
        >
          <Icon
            name="check-circle-outline"
            size={48}
            color={colors.darkGreen}
          />
          <Spacer />
          <Title testID="paymentAcceptedMsg" color={colors.darkGreen}>
            {t('Ваш платеж принят').toUpperCase()}
          </Title>
          <Description>Спасибо за доверие!</Description>
        </View>
      </View>
      <Spacer />
      <Button testID="completePayment" onPress={onClose}>
        {t('to_complete')}
      </Button>
    </View>
  )
})

export const FormFail = observer(() => {
  const { payData } = PaymentStore
  return (
    <View>
      <ItemContainer style={{ paddingVertical: 20, marginVertical: 15 }}>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon name="alert-outline" size={48} color={colors.red} />
          <Spacer />
          <Title color={colors.red}>
            {payData.errorMessage || 'Payment Failed'}
          </Title>
          <Spacer />
        </View>
      </ItemContainer>
      <Button
        testID="backBtn"
        onPress={() => {
          PaymentStore.resetPayData()
          payData.reset()
        }}
      >
        {i18n.t('back')}
      </Button>
    </View>
  )
})

export const Pay3D = observer(({ navigation }) => {
  const { primary } = useTheme()
  const { debit } = AccountStore
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      pay24Api.cancelPaymentPoll()
    }
  }, [])

  return (
    <View>
      <Text size={12} style={{ fontWeight: 'bold', color: colors.metal }}>
        {t('payment_type')}
      </Text>
      <AccountCard
        item={debit}
        empty
        requisite={debit?.id === 'agent' ? AgentStore.sub_code : undefined}
      />
      <Spacer />

      <Payment3D
        onFail={() => {
          PaymentStore.setStep(FORM.FAIL)
          PaymentStore.setPaymentState(FORM.FAIL)
        }}
        onSuccess={() => {
          PaymentStore.setStep(FORM.PAID)
          PaymentStore.setPaymentState(FORM.PAID)
        }}
      />
      <Spacer />
      <Text style={{ textAlign: 'center' }}>
        Используя программу вы принимаете в полном объеме
      </Text>
      <TouchableOpacity
        testID="rules_and_ofer"
        onPress={() => {
          navigation.navigate('info', { screen: 'rules' })
        }}
      >
        <Text color={primary} style={{ textAlign: 'center' }}>
          {i18n.t('rules_and_ofer')}
        </Text>
      </TouchableOpacity>
      <Spacer />
    </View>
  )
})

export default function DefaultTemplate(isAgent = false) {
  if (isAgent) {
    return {
      steps: 3,
      [FORM.INITIAL]: FormInput,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormDone,
      [FORM.FAIL]: FormFail,
    }
  }

  return {
    steps: 4,
    [FORM.INITIAL]: FormInput,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
