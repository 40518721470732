import { constants } from '@pay24/common'
import { PaymentStore } from '@pay24/common/src/store'

export const categoryId = (service) =>
  service?.categoryid ? service?.categoryid : service?.category_id

// формирует список для select объектов
export const getItems = (data, flag = '') => {
  const { payData } = PaymentStore
  const items: any[] = []
  if (data && data.length > 0) {
    let newData = data
    if (flag === 'gns_raion_code') {
      newData = data.filter((d) => {
        if (d.length === 3) {
          return d[2] === payData.getOptions('gns_oblast_code')
        }
        return false
      })
    } else if (flag === 'gns_aimak_code') {
      newData = data.filter((d) => {
        if (d.length === 3) {
          return d[2] === payData.getOptions('gns_raion_code')
        }
        return false
      })
    }
    newData.forEach((d) => {
      if (d.length === 2 || flag.length > 0) {
        return items.push({ id: d[0], name: d[1] })
      }
      return items.push({ id: d[0], ext: d[1], name: d[2] })
    })

    if (!newData) {
      payData.setOptions(flag, 0)
    }
  }
  return items
}

// очищает список для select объектов
export const clearItems = (store, flag) => {
  if (flag === 'gns_oblast_code') {
    store.deleteOption('gns_raion_code', null)
    store.deleteOption('gns_raion_text', null)
  }
  if (flag === 'gns_oblast_code' || flag === 'gns_raion_code') {
    store.deleteOption('gns_aimak_code', null)
    store.deleteOption('gns_aimak_text', null)
  }
}

// маска для телефона
export const maskOfInput: any = (service) =>
  Object.values(constants.PHONE_CODES).find(
    (code: any) => code.country === service.data.contact_data?.country,
  )

// показывает сколько принимает услуга
export const amountPlaceholder = (service) =>
  (service.min_amount ? `от ${service.min_amount}` : '') +
  (service.max_amount ? ` до ${service.max_amount} ` : '')
