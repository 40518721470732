import React from 'react'
import { ActivityIndicator, Dimensions, View } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { colors } from '@pay24/common'

const { width, height } = Dimensions.get('window')

export function Spinner() {
  const theme = useTheme()
  return (
    <View
      style={{
        width,
        height,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme['background-basic-color-2'],
      }}
    >
      <ActivityIndicator size={'large'} animating color={colors.primary} />
    </View>
  )
}
