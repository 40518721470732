import 'react-native-vector-icons/dist/MaterialCommunityIcons'
import iconFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'

const iconFontStyles = `@font-face {
  src: url(${iconFont}) format(truetype);
  font-family: MaterialCommunityIcons;
}`

// Create a stylesheet
const style = document.createElement('style')
style.type = 'text/css'

declare global {
  interface HTMLStyleElement {
    styleSheet?: { cssText: string }
  }
}

if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles
} else {
  style.appendChild(document.createTextNode(iconFontStyles))
}

// Inject the stylesheet into the document head
document.head.appendChild(style)
