import { observer } from 'mobx-react'
import React from 'react'
import {
  _constants,
  hooks,
  store,
  colors,
  translate,
  utils,
} from '@pay24/common'
import { Pressable } from 'react-native'
import { ItemContainer } from '../Components'
import { Text } from '../Text'
import { Input } from '../Input'
import Icon from '../Icon'
import Spacer from '../Spacer'
import { Button } from '../Button'

const { MESSAGE_TYPE } = _constants
const { useTranslation } = translate
const { useInput } = hooks
const { RootStore } = store
const { Keychain } = utils

export const Pincode = observer(function Pincode({
  setMain,
}: {
  setMain: () => void
}) {
  const pincode = useInput('')
  const newPincode = useInput('')
  const confirmNewPincode = useInput('')
  const [securePin, setSecurePin] = React.useState(true)
  const { t } = useTranslation()

  const enabled =
    pincode &&
    pincode.value.length === 4 &&
    newPincode &&
    newPincode.value.length === 4 &&
    confirmNewPincode &&
    confirmNewPincode.value.length === 4

  return (
    <>
      <ItemContainer style={{ padding: 8, marginBottom: 10 }}>
        <Text color={colors.metal}>{t('pin_code_text')}</Text>
      </ItemContainer>
      <Input
        testID="current_pin_code"
        label={'current_pin_code'}
        keyboardType="numeric"
        maxLength={4}
        secureTextEntry={securePin}
        rightComponent={
          <Pressable onPress={() => setSecurePin(!securePin)}>
            <Icon
              name={securePin ? 'eye-outline' : 'eye-off-outline'}
              color={colors.metal}
            />
          </Pressable>
        }
        {...pincode}
      />
      <Spacer height={16} />
      <Input
        testID="new_pin_code"
        label="new_pin_code"
        keyboardType="numeric"
        maxLength={4}
        {...newPincode}
      />
      <Spacer height={16} />
      <Input
        testID="confirm_pin_code"
        label="confirm_pin_code"
        keyboardType="numeric"
        maxLength={4}
        {...confirmNewPincode}
      />
      <Spacer height={16} />
      <Button
        testID="change_pin_code"
        disabled={!enabled}
        onPress={() => {
          if (newPincode.value !== confirmNewPincode.value) {
            RootStore.setMessage({
              type: MESSAGE_TYPE.WARNING,
              text: t('incorrect_new_pins'),
            })
          } else {
            Keychain.getPassword().then((credentials) => {
              if (credentials) {
                if (credentials.password === pincode.value) {
                  Keychain.setPassword(RootStore.token, newPincode.value).then(
                    () => {
                      RootStore.setMessage({
                        type: MESSAGE_TYPE.WARNING,
                        text: t('success_change_pin'),
                      })
                      setMain()
                    },
                  )
                } else {
                  RootStore.setMessage({
                    type: MESSAGE_TYPE.WARNING,
                    text: t('incorrect_pin'),
                  })
                }
              } else {
                RootStore.setMessage({
                  type: MESSAGE_TYPE.WARNING,
                  text: t('system_error'),
                })
              }
            })
          }
        }}
      >
        {t('save').toUpperCase()}
      </Button>
    </>
  )
})
