import React from 'react'
import { QRCode } from 'react-qr-svg'

export default ({ value }) =>
  !value ? null : (
    <QRCode
      bgColor="#FFFFFF"
      fgColor="#000000"
      level="Q"
      style={{ width: 256 }}
      value={value}
    />
  )
