import { colors } from '@pay24/common'
import { flattenData } from '@pay24/common/src/utils'
import { Col, Row } from '@pay24/ui'
import React, { useMemo } from 'react'
import { Linking, TouchableOpacity } from 'react-native'
import Alert from './alert/Alert'
import { FrameContainer, HistoryCard } from './Components'
import Spacer from './Spacer'
import { Text } from './Text'
import { useCopyToClipboard, useTheme } from '@pay24/common/src/hooks'
import { useTranslation } from '@pay24/common/src/translate'

const OpenURLButton = ({ url }) => {
  const { primary } = useTheme()
  const { t } = useTranslation()
  const handlePress = React.useCallback(async () => {
    const supported = await Linking.canOpenURL(url)

    if (supported) {
      await Linking.openURL(url)
    } else {
      Alert.alert(`Не может открыть ссылку: ${url}`)
    }
  }, [url])

  return (
    <TouchableOpacity onPress={handlePress}>
      <Text color={primary}>{t('show_on_map')}</Text>
    </TouchableOpacity>
  )
}

export function ServiceInfo({ page }) {
  const [_, handleCopy] = useCopyToClipboard()
  const { t } = useTranslation()

  const CONTACTS = useMemo(() => {
    return [
      {
        title: 'Phone',
        desc: page.params.contact_data.support_phone_num,
        phone: page.params.contact_data.support_phone_num,
        icon: 'phone',
        bg: colors.metal,
      },
      {
        title: 'Phone',
        desc: page.params.contact_data.support_phone_num_beeline,
        phone: page.params.contact_data.support_phone_num_beeline,
        icon: 'phone',
        bg: colors.orange,
      },
      {
        title: 'Phone',
        desc: page.params.contact_data.support_phone_num_megacom,
        phone: page.params.contact_data.support_phone_num_megacom,
        icon: 'phone',
        bg: colors.pink,
      },
      {
        title: 'Phone',
        desc: page.params.contact_data.support_phone_num_nurtelecom,
        phone: page.params.contact_data.support_phone_num_nurtelecom,
        icon: 'phone',
        bg: colors.violet,
      },
      {
        title: 'Phone',
        desc: page.params.contact_data.support_phone_num_second,
        phone: page.params.contact_data.support_phone_num_second,
        icon: 'phone',
        bg: colors.metal,
      },
      {
        title: 'Telegram',
        desc: page.params.contact_data.support_telegram_num,
        uri: `https://t.me/${page.params.contact_data.support_telegram_num}`,
        icon: 'send',
        bg: colors.blue,
      },
      {
        title: 'WhatsApp',
        desc: page.params.contact_data.support_whatsapp_num,
        uri: `https://api.whatsapp.com/send?phone=${page.params.contact_data.support_whatsapp_num}`,
        icon: 'whatsapp',
        bg: colors.green,
      },
      {
        title: 'Почта',
        desc: page.params.contact_data.email,
        uri: `mailto:${page.params.contact_data.email}`,
        icon: 'email-outline',
        bg: colors.brown,
      },
      {
        title: 'Facebook',
        desc: page.params.contact_data.facebook,
        uri: `https://www.facebook.com/${page.params.contact_data.facebook}`,
        icon: 'facebook',
        bg: colors.primary,
      },
      {
        title: 'Instagram',
        desc: page.params.contact_data.instagram,
        uri: `https://www.instagram.com/${page.params.contact_data.instagram}`,
        icon: 'instagram',
        bg: colors.red,
      },
      {
        title: 'Twitter',
        desc: page.params.contact_data.twitter,
        uri: `https://twitter.com/${page.params.contact_data.twitter}`,
        icon: 'twitter',
        bg: colors.blue,
      },
      {
        title: 'Website',
        desc: page.params.contact_data.web_site,
        uri: page.params.contact_data.web_site,
        icon: 'earth',
        bg: colors.coal,
      },
    ].filter((c) => !!c.desc && c.desc !== '-')
  }, [page.params])

  const contacts_data = {
    service_info: page.params.contact_data.service_info + '\n',
    company_address: page.params.contact_data.company_address,
    work_time: page.params.contact_data.work_time + '\n',
    support_phone_num: page.params.contact_data.support_phone_num,
    Телефон2: page.params.contact_data.support_phone_num_second,
    Beeline: page.params.contact_data.support_phone_num_beeline,
    Megacom: page.params.contact_data.support_phone_num_megacom,
    O: page.params.contact_data.support_phone_num_nurtelecom + '\n',
    email: page.params.contact_data.email,
    web_site: page.params.contact_data.web_site + '\n',
    facebook: page.params.contact_data.facebook,
    Instagram: page.params.contact_data.instagram,
    Twitter: page.params.contact_data.twitter,
    support_telegram_num: page.params.contact_data.support_telegram_num,
    support_whatsapp_num: page.params.contact_data.support_whatsapp_num + '\n',
    GoogleMap: `https://www.google.com/maps/place/${page.params.contact_data.logitude},${page.params.contact_data.latitude}`,
  }

  return (
    <>
      {!!page.params.contact_data.company_activity &&
      !!page.params.contact_data.service_info ? (
        <HistoryCard
          title={t('information')}
          onCopy={() => handleCopy(flattenData(contacts_data))}
        >
          <>
            <Text>{page.params.contact_data.service_info}</Text>
            <Spacer />
            {page.params.contact_data.company_address && (
              <>
                <Text>
                  {t('address')}: {page.params.contact_data.company_address}
                </Text>
                <OpenURLButton
                  url={`https://www.google.com/maps/place/${page.params.contact_data.logitude},${page.params.contact_data.latitude}`}
                />
              </>
            )}
            <Spacer />
            <Text>Время работы: {page.params.contact_data.work_time}</Text>
          </>
        </HistoryCard>
      ) : null}
      <Spacer />
      <Row>
        {CONTACTS.map(
          (item, idx) =>
            !!item.desc && (
              <Col xs={12} key={idx}>
                <Contacts
                  title={item.title}
                  desc={item.desc}
                  phone={item.phone ? item.phone : null}
                  uri={item.uri || ''}
                  icon={item.icon}
                  bg={item.bg}
                />
                <Spacer />
              </Col>
            ),
        )}
      </Row>
    </>
  )
}

const Contacts = ({ uri, icon, bg, title, desc, phone }) => {
  if (title === 'Facebook' || title === 'Instagram' || title === 'Twitter') {
    const link = uri.split('//')
    uri = link.length > 2 ? link[0] + '//' + link[2] : uri
  }
  return (
    <FrameContainer
      icon={icon}
      iconBg={bg}
      title={title}
      subTitle={desc}
      arrow
      onPress={() => {
        if (uri) {
          Linking.openURL(uri)
        } else if (phone) {
          Linking.openURL(`tel:${phone}`)
        }
      }}
    />
  )
}
