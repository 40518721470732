import { View, ViewStyle } from 'react-native'
import React from 'react'
import { colors, hooks, utils } from '@pay24/common'

const { useTheme } = hooks
const { composeStyle } = utils

export default ({
  step = 1,
  stepCount,
  containerStyle,
}: {
  step: number
  stepCount: number
  containerStyle?: ViewStyle
}) => {
  if (!stepCount || stepCount < 1) return null

  const arr = [...Array(stepCount - 1).keys()]

  return (
    <View style={composeStyle({ flexDirection: 'row' }, containerStyle)}>
      <Circle status={1} step={1} />
      {arr.map((a) => (
        <Circle key={a} status={a + 2} step={step} />
      ))}
    </View>
  )
}

const Circle = ({ status, step }) => {
  const { isLight } = useTheme()
  const activeLine = isLight ? colors.primary : colors.orange
  const line = isLight ? colors.borderLight : colors.borderDark
  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        height: 5,
        borderRadius: 4,
        marginHorizontal: 6,
        flex: 1,
        backgroundColor: step >= status ? activeLine : line,
      }}
    />
  )
}
