import { useMemo } from 'react'

const isValid = value => value === true ||
  (typeof value === 'number' && isFinite(value)) ||
  (typeof value === 'string' && value.length > 0)

/**
 * @param point {string}
 * @param values {{xs?:(number|string|boolean),sm?:(number|string|boolean),md?:(number|string|boolean),lg?:(number|string|boolean),xl?:(number|string|boolean)}}
 * @return {(number|string|undefined)}
 */
export default function useBreakPointWidth (point, values) {
  const { xs, sm, md, lg, xl } = values
  return useMemo(() => {
    const value = ({
      xl: [xl, lg, md, sm, xs],
      lg: [lg, md, sm, xs],
      md: [md, sm, xs],
      sm: [sm, xs],
      xs: [xs],
    })[point]?.find(isValid)
    return value === true ? '100%' : value
  }, [point, xs, sm, md, lg, xl])
}
