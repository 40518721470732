import { PaymentStore } from '@pay24/common/src/store'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { observer } from 'mobx-react'
import React from 'react'
import { Input } from '../Input'
import Spacer from '../Spacer'
import { FormInitial } from './DefaultPassportTemplate'
import { FormCheck, FormDone, FormFail, Pay3D } from './DefaultTemplate'

const FormBase = observer((props) => {
  const { service } = props
  const { payData } = PaymentStore

  const renderFields = () => {
    const fields = service.data && service.data.fields
    if (!fields || fields.length === 0 || Object.keys(fields).length === 0) {
      return null
    }

    const ev = fields.find((value) => value.name === 'ev_id')

    return (
      <>
        <Input
          testID="label_name_text"
          label={ev.label}
          value={payData.getOptions(ev.name)}
          onChangeText={(val) => {
            PaymentStore.payData.setOptions(ev.name, val)
          }}
        />
        <Spacer />
      </>
    )
  }

  return <FormInitial {...props} customFields={renderFields} />
})

export default (isAgent) => {
  if (isAgent) {
    return {
      steps: 4,
      [FORM.INITIAL]: FormBase,
      [FORM.CHECK]: FormCheck,
      [FORM.PAID]: FormDone,
      [FORM.FAIL]: FormFail,
    }
  }
  return {
    steps: 5,
    [FORM.INITIAL]: FormBase,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
