import { colors } from '@pay24/common'
import { PaymentStore } from '@pay24/common/src/store'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { useTranslation } from '@pay24/common/src/translate'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Button } from '../Button'
import { InputModel } from '../Input'
import Select from '../Select'
import Spacer from '../Spacer'
import { Text } from '../Text'
import TextAutocomplete from '../TextAutocomplete'
import { FormCheck, FormDone, FormFail, Pay3D } from './DefaultTemplate'
import { requisiteScheme } from './schemes'
import { amountPlaceholder, categoryId, maskOfInput } from './utils'

const FormBase = observer((props) => {
  const { service, source, valid_fields, comments } = props
  const { payData, isAgent } = PaymentStore
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      payData,
      isAgent,
      source,
    },
    enableReinitialize: true,
    validationSchema: requisiteScheme(service, valid_fields),
    onSubmit: (values) => {
      let requisite = payData.requisite.replace(/\s/g, '')
      payData.setValue('service_id', service.id)
      payData.setValue('service_code', service.type)
      payData.setValue('service_name', service.name)
      payData.setValue('requisite', requisite)
      payData.setValue('form_scheme', service?.data?.form_scheme)
      PaymentStore.requisiteCheck(values.source).then((res) => {
        payData.setValue('branches', res?.dynamic_check_data?.data || [])
        PaymentStore.setStep(2)
      })
    },
  })

  return (
    <>
      {!PaymentStore.isAgent && formik.errors.source ? (
        <Text
          style={{
            color: colors.red,
            marginTop: 3,
            marginLeft: 3,
            fontSize: 10,
          }}
        >
          {formik.errors.source}
        </Text>
      ) : null}
      <Spacer />
      <InputModel
        testID="requisite"
        model={formik.values.payData}
        name="requisite"
        value={payData.requisite}
        errorText={formik.errors.payData ? formik.errors.payData.requisite : ''}
        label={service?.data?.requisite_message || 'requisite'}
        inputMask={
          categoryId(service) === 1
            ? maskOfInput(service)?.mask
            : service?.placeholder?.replace(/ /g, '') !== ''
            ? service?.placeholder?.replace(/_/g, '9')
            : ''
        }
        keyboardType="default"
        placeholder={service?.placeholder}
      />
      <Spacer />
      <InputModel
        testID="amount"
        label="sum"
        keyboardType="numeric"
        name="amount"
        value={formik.values.payData.amount?.toString()}
        errorText={formik.errors.payData ? formik.errors.payData.amount : ''}
        model={formik.values.payData}
        placeholder={amountPlaceholder(service)}
      />
      <Spacer />
      <TextAutocomplete
        testID="comment"
        model={formik.values.payData}
        name="comment"
        label="comment"
        placeholder="optional"
        options={comments}
      />
      <Spacer />
      <Button
        testID="nextBtn"
        disabled={PaymentStore.buttonBlock}
        onPress={() => {
          formik.handleSubmit()
        }}
      >
        {t('next')}
      </Button>
    </>
  )
})

const FormReceiver = observer((props) => {
  const [contractor_id, setContractorId] = useState<string | null>(null)
  const { service, source, valid_fields } = props
  const { isAgent } = PaymentStore
  const payData: any = PaymentStore.payData
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      payData,
      isAgent,
      source,
    },
    enableReinitialize: true,
    validationSchema: requisiteScheme(service, valid_fields),
    onSubmit: (values) => {
      payData.setOptions('contractor_id', contractor_id)
      PaymentStore.requisiteCheck(values.source).then(() => {
        PaymentStore.setPaymentState(FORM.CHECK)
        PaymentStore.setStep(3)
      })
    },
  })

  return (
    <>
      {!PaymentStore.isAgent && formik.errors.source ? (
        <Text
          style={{
            color: colors.red,
            marginTop: 3,
            marginLeft: 3,
            fontSize: 10,
          }}
        >
          {formik.errors.source}
        </Text>
      ) : null}
      <Spacer />
      <Select
        testID="select_items"
        options={payData.branches || []}
        label={'Выберите'}
        keyName="Id"
        valueName="Name"
        value={contractor_id}
        onChange={(val) => setContractorId(val.Id)}
        onClearPress={() => setContractorId(null)}
      />
      <Spacer />
      <Button
        testID="nextBtn"
        disabled={PaymentStore.buttonBlock || !contractor_id}
        onPress={() => {
          formik.handleSubmit()
        }}
      >
        {t('next')}
      </Button>
    </>
  )
})

const FormInitial = observer((props) => {
  const {
    service,
    source,
    customFields,
    valid_fields,
    add_params,
    add_param_valid_fields,
    comments,
    selectRequisite = true,
    selectComment = true,
  } = props

  return PaymentStore.step === 1 ? (
    <FormBase
      service={service}
      source={source}
      paymentStore={PaymentStore}
      customFields={customFields}
      valid_fields={valid_fields}
      add_params={add_params}
      add_param_valid_fields={add_param_valid_fields}
      comments={comments}
      selectRequisite={selectRequisite}
      selectComment={selectComment}
    />
  ) : PaymentStore.step === 2 ? (
    <FormReceiver
      service={service}
      source={source}
      paymentStore={PaymentStore}
    />
  ) : null
})

export default () => {
  return {
    steps: 5,
    [FORM.INITIAL]: FormInitial,
    [FORM.CHECK]: FormCheck,
    [FORM.PAYMENT_3D]: Pay3D,
    [FORM.PAID]: FormDone,
    [FORM.FAIL]: FormFail,
  }
}
