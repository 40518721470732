import { store, translate, _constants, utils } from '@pay24/common'
import { useDrawerPages } from '@pay24/common/src/hooks'
import { FORM } from '@pay24/common/src/store/PaymentStore'
import { useIsFocused } from '@react-navigation/native'
import capitalize from 'lodash/capitalize'
import { observer } from 'mobx-react'
import { getSnapshot } from 'mobx-state-tree'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { TabButton } from '../Button'
import { FrameContainer } from '../Components'
import { Col, Row } from '../grid'
import ScreenNew from '../ScreenNew'
import Spacer from '../Spacer'
import StepLine from '../StepLine'
import { categoryId, loadTemplate } from '../templates'
import ToolbarButton from '../ToolbarButton'
import { PageName, PAGES } from './types'
import {
  AccountSelect,
  ModalIdentification,
  PaymentsSidebar,
} from './components'

const { useTranslation } = translate
const { RootStore, PaymentStore } = store
const { isDevMode } = utils
const { CHARGE24_SERVICE_ID, INTERNAL_TRANSFER_WALLET_ID } = _constants

export default observer(({ navigation, route }) => {
  const [service, setService] = useState<any>()
  const [template, setTemplate] = useState<any>(null)
  const [account, setAccount] = useState(null)
  const { pages, openPage, onClose, onBackPress } = useDrawerPages<
    PageName,
    any
  >()
  const [visible, setVisible] = useState<boolean>(false)
  const { params } = route
  const scrollRef: MutableRefObject<any> | null = useRef()
  const isFocused = useIsFocused()
  const { t } = useTranslation()

  const { payData, step, paymentState, setStep, setPaymentState } = PaymentStore

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('home')
    }
  }

  async function init() {
    if (!route.params || !route.params.serviceId) {
      goBack()
    } else {
      const { serviceId } = route.params
      PaymentStore.setAgent(false)
      const srv = await PaymentStore.getService(serviceId)
      if (!srv) {
        goBack()
      } else {
        setService(srv)
        const tmp = loadTemplate(srv)
        setTemplate(tmp)
        if (isDevMode() && serviceId === INTERNAL_TRANSFER_WALLET_ID) {
          payData.setValue('amount', 1)
          payData.setValue('requisite', '777333744')
        }
        if (route.params.requisite) {
          if (
            service?.data?.keyboard_type === 'numpad' ||
            categoryId(service) === 1
          ) {
            route.params.requisite.replace(/[^\d-+_*#,.\\/|]/g, '')
          }
          payData.setValue('requisite', route.params.requisite)
        }
        if (route.params.options && typeof route.params.options === 'object') {
          payData.setValue('options', route.params.options)
        }
        if (route.params.amount) {
          payData.setValue('amount', parseFloat(route.params.amount))
        }

        payData.setValue('service_id', srv.id)
        payData.setValue('service_code', srv.type)
        payData.setValue('service_name', srv.name)
      }
    }
  }

  useEffect(() => {
    init()
  }, [route.params])

  const scrollUp = () => {
    scrollRef.current?.scrollToPosition(0, 0)
  }

  useEffect(() => {
    scrollUp()
  }, [paymentState])

  useEffect(() => {
    PaymentStore.resetPayData()
    payData.reset()
    setStep(1)
    setPaymentState(1)
    payData.setValue('requisite', '')
    payData.setValue('amount', null)
    payData.setValue('comment', '')
    return
  }, [route.params?.serviceId])

  let Form: any = null
  if (template) {
    Form = template[paymentState]
  }

  const isCharge24Service = params?.serviceId === CHARGE24_SERVICE_ID
  const title = isCharge24Service
    ? 'fill_balance'
    : capitalize(service?.category_name)

  const country = (c) => {
    const countryName = c.name.toLowerCase()
    if (countryName.includes('азербайджан')) {
      return 'АЗЕРБАЙДЖАН'
    }
    return c.data?.contact_data?.country.toUpperCase()
  }

  useEffect(() => {
    if (!isFocused) {
      setVisible(false)
      return
    }
    if (RootStore.message?.result === 214) {
      setVisible(true)
    }
  }, [isFocused, RootStore.message?.result])

  return (
    <ScreenNew
      ref={scrollRef}
      title={title || ' '}
      back={!route.params?.cantBack || step !== FORM.INITIAL}
      onRefresh={init}
      backScreen={'home'}
      rightComponent={
        service?.id !== 3481 ? (
          <ToolbarButton testID="share" label="share" icon="share-variant" />
        ) : undefined
      }
      onBackPress={() => {
        if (step === FORM.INITIAL) {
          return goBack()
        } else {
          PaymentStore.setPaymentState(FORM.INITIAL)
          PaymentStore.setStep(FORM.INITIAL)
        }
      }}
    >
      {service ? (
        <Row>
          <Col xs={12} sm={12} md={5}>
            {service?.categoryid !== INTERNAL_TRANSFER_WALLET_ID ? (
              <>
                <FrameContainer
                  titleNumberOfLines={3}
                  image={service?.image_logo}
                  title={service?.name}
                  country={isCharge24Service ? '' : country(service)}
                />
                <Spacer />
                <StepLine step={step} stepCount={template?.steps} />
                <Spacer />
              </>
            ) : null}

            {paymentState === FORM.INITIAL && (
              <>
                <AccountSelect
                  navigation={navigation}
                  service={service}
                  onAccountSelect={(acc) => {
                    setAccount(acc ? getSnapshot(acc) : null)
                  }}
                />
              </>
            )}
            {Form ? (
              <Form
                service={service}
                source={account}
                navigation={navigation}
              />
            ) : null}
            <Spacer />
          </Col>

          <Col xs={12} sm={12} md={7}>
            <Row>
              {paymentState === FORM.PAID ? (
                <>
                  <Col xs={12} md={6}>
                    <TabButton
                      testID="show_cheque"
                      onPress={() =>
                        openPage({
                          screen: PAGES.CHEQUE,
                          params: { id: payData.payment_id },
                        })
                      }
                    >
                      {t('show_cheque')}
                    </TabButton>
                    <Spacer />
                  </Col>
                  <Col xs={12} md={6}>
                    <TabButton
                      testID="pay_again"
                      onPress={() => PaymentStore.resetPayData()}
                    >
                      {t('pay_again')}
                    </TabButton>
                    <Spacer />
                  </Col>
                  <Col xs={12} md={6}>
                    <TabButton
                      testID="add_to_favorite"
                      onPress={() => {
                        const param = {
                          favorite: {
                            serviceId: payData.service_id,
                            requisite: payData.requisite,
                            options: getSnapshot(payData.options),
                          },
                        }
                        navigation.navigate('favorite', param)
                      }}
                    >
                      {t('add_to_favorite')}
                    </TabButton>
                    <Spacer />
                  </Col>
                </>
              ) : null}
              <Col xs={12} md={6}>
                <TabButton
                  testID="lastTenPayments"
                  onPress={() =>
                    openPage({
                      screen: PAGES.LAST_PAYMENTS,
                      params: {
                        serviceId: parseFloat(route.params?.serviceId),
                      },
                    })
                  }
                >
                  {t('last_payments')}
                </TabButton>
                <Spacer />
              </Col>
              {service?.data?.contact_data?.company_activity ? (
                <Col xs={12} md={6}>
                  <TabButton
                    testID="paymentReceiver"
                    onPress={() =>
                      openPage({
                        screen: PAGES.PAYMENT_RECEIVER,
                        params: {
                          contact_data: service.data.contact_data,
                          service_name: service.name,
                        },
                      })
                    }
                  >
                    {t('payment_receiver')}
                  </TabButton>
                  <Spacer />
                </Col>
              ) : null}
            </Row>
          </Col>
        </Row>
      ) : null}
      <PaymentsSidebar
        pages={pages}
        openPage={openPage}
        onClose={onClose}
        goBack={onBackPress}
        navigation={navigation}
      />
      <ModalIdentification
        visible={visible}
        setVisible={setVisible}
        navigation={navigation}
      />
    </ScreenNew>
  )
})
