import { COUNTRIES_OBJECT } from '@pay24/common/src/utils'
import { colors } from '@pay24/common'
import { Deposit } from '../paymentForms'
import { TabButton } from '../Button'
import accountStore from '@pay24/common/src/store/AccountStore'
import { CustomDropdown, FrameContainer, NoData } from '../Components'
import Icon from '../Icon'
import Screen from '../ScreenNew'
import ScrollHorizontal from '../ScrollHorizontal'
import SearchBar from '../SearchBar'
import Spacer from '../Spacer'
import { MenuItem } from '@ui-kitten/components'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { Col, Row } from '../grid'
import { useTranslation } from '@pay24/common/src/translate'

const TYPES = [
  { id: null, name: 'all', icon: 'folder-outline', color: colors.metal },
  { id: 105, name: 'accounts', icon: 'bank-outline', color: colors.metal },
  {
    id: 90,
    name: 'wallets',
    icon: 'credit-card-outline',
    color: colors.metal,
  },
  { id: 106, name: 'cards', icon: 'wallet-outline', color: colors.metal },
]

export default observer(({ navigation, route }) => {
  const { getDepositableServices } = accountStore

  useEffect(() => {
    getDepositableServices()
  }, [])

  return (
    <Screen
      onRefresh={getDepositableServices}
      title="add_account_enrollment"
      back
    >
      {route.params?.id ? (
        <CreditForm
          model={accountStore}
          service_id={route.params.id}
          navigation={navigation}
        />
      ) : (
        <CreditList model={accountStore} navigation={navigation} />
      )}
    </Screen>
  )
})

const CreditForm = ({ model, service_id, navigation }) => {
  const source = model.getSource(service_id)
  return (
    <Row>
      {source && (
        <Col xs={12} sm={12} md={6} lg={4}>
          <FrameContainer
            image={source.image_logo}
            country={source.data.contact_data.country}
            title={source.name}
          />
          <Spacer />
          <Deposit
            model={model}
            service_id={service_id}
            navigation={navigation}
          />
        </Col>
      )}
    </Row>
  )
}

const CreditList = observer(({ model, navigation }) => {
  const {
    changeSelectedCountry,
    selectedCountry,
    countries,
    changeSearchText,
    searchText,
    changeSelectedType,
    selectedType,
    filteredServices,
    filterTypesCount,
    changeCustomer,
    changeValue,
  } = model
  const { t } = useTranslation()

  const navigateToAccountCreditAdd = (id) => {
    return () => {
      changeCustomer('')
      changeValue('')
      navigation.push('credit/add', { id })
    }
  }

  return (
    <>
      <ScrollHorizontal>
        {countries.length > 1 &&
          countries
            .filter((item) => item !== 'Международные')
            .map((item, idx) => (
              <TabButton
                key={idx}
                active={selectedCountry === item}
                onPress={() => {
                  changeSelectedCountry(item)
                }}
              >
                {t(COUNTRIES_OBJECT[item])}
              </TabButton>
            ))}
      </ScrollHorizontal>
      <SearchBar
        placeholder={''}
        value={searchText}
        onChangeText={(val) => {
          changeSearchText(val)
        }}
      />
      <Spacer />

      <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
        <CustomDropdown
          title={`${t(selectedType.name)} (${filteredServices.length})`}
        >
          {TYPES.map((item, idx) => (
            <MenuItem
              key={idx}
              title={filterTypesCount(item)}
              accessoryLeft={() => <Icon name={item.icon} color={item.color} />}
              onPress={() => changeSelectedType(item)}
            />
          ))}
        </CustomDropdown>
      </View>

      <Spacer />
      {filteredServices.length > 0 ? (
        <Row>
          {filteredServices.map((item, idx) => (
            <Col xs={12} sm={12} md={6} lg={4} key={idx}>
              <FrameContainer
                image={item.image_logo}
                country={item.data.contact_data.country.toUpperCase()}
                title={item.name}
                arrow
                onPress={navigateToAccountCreditAdd(item.id)}
              />
              <Spacer />
            </Col>
          ))}
        </Row>
      ) : (
        <NoData />
      )}
    </>
  )
})
