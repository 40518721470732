import React, { MutableRefObject, useCallback, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import Icon from './Icon'
import { Input } from './Input'
import { colors } from '@pay24/common'

interface Props {
  onFileSave: (value: File | File[]) => void
  options?: { multiple?: boolean }
  value: string | string[] | File | File[] | null
  label: string
  onClear: (value) => void
}

export default ({
  onFileSave,
  options = {},
  value,
  label = '',
  onClear,
}: Props) => {
  const { multiple = false } = options
  const onDrop = useCallback(
    async (files) => {
      if (files && files.length && onFileSave) {
        onFileSave(multiple ? files : files[0])
      }
    },
    [multiple],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...options,
  })
  const ref = useRef() as MutableRefObject<HTMLDivElement>
  const upload = () => {
    ref.current.click()
  }
  return (
    <>
      <Input
        leftComponent={
          <Icon
            size={18}
            style={{ marginRight: 8 }}
            name="plus-box-outline"
            color={colors.metal}
          />
        }
        style={{ flex: 0.6, marginRight: 10 }}
        label={label}
        value={
          Array.isArray(value)
            ? value.map((f) => (typeof f === 'string' ? f : f.name)).join(', ')
            : typeof value === 'string'
            ? value
            : value?.name || ''
        }
        editable={false}
        onPress={upload}
        onChangeText={() => onClear(null)}
      />
      <>
        <div {...getRootProps()} ref={ref}>
          <input {...getInputProps()} />
        </div>
      </>
    </>
  )
}
