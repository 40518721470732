import { colors, hooks, translate } from '@pay24/common'
import React from 'react'
import { Pressable, ViewStyle } from 'react-native'
import Icon from '../Icon'
import { Text } from '../Text'

const { useTranslation } = translate
const { useTheme } = hooks

interface MenuTabButtonProps {
  isFocused?: boolean
  onPress?: () => void
  onLongPress?: () => void
  menu: any
  style?: ViewStyle
}

export const MenuTabButton = (props: MenuTabButtonProps) => {
  const { isFocused, onLongPress, onPress, menu, style } = props
  const { primary } = useTheme()
  const tintColor = isFocused ? primary : colors.metal

  const { t } = useTranslation()

  return (
    <Pressable
      onPress={onPress}
      onLongPress={onLongPress}
      style={[
        {
          height: '100%',
          flex: 1,
          alignItems: 'center',
          // justifyContent: 'center',
          paddingTop: 8,
          width: 80,
        },
        style,
      ]}
    >
      <Icon name={menu.icon} color={tintColor} />
      <Text
        style={{ color: tintColor, flexWrap: 'wrap', textAlign: 'center' }}
        size={13}
        numberOfLines={2}
      >
        {t(menu.title || menu.path)}
      </Text>
    </Pressable>
  )
}
