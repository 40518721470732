import { colors, translate, api, _constants, store, utils } from '@pay24/common'
import { confirmAlert } from '../alert'
import { AccountCard } from './AccountCard'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import { View } from 'react-native'
import { Button } from '../Button'
import CheckBox from '../CheckBox'
import { Col, Row } from '../grid'
import { Input } from '../Input'
import Modal from '../Modal'
import Screen from '../ScreenNew'
import Spacer from '../Spacer'
import { SubTitle, Text } from '../Text'
import ToolbarButton from '../ToolbarButton'

const { useTranslation } = translate
const { passport } = api
const { INTERNAL_TRANSFER_WALLET_ID } = _constants
const { AccountStore, AgentStore, RootStore } = store
const { APP_ENV } = utils

export default observer(({ navigation, route }) => {
  const [accountSelected, setAccountSelect] = useState(false)
  const [serviceId, setServiceId] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [refresh, setRefresh] = useState(true)
  const { t } = useTranslation()
  const { params } = route

  const {
    debitUnWired,
    debitWired,
    setItem,
    selectedDebit,
    changeSelectedDebitName,
    refreshDebit,
    setDebit,
    changeCryptoDebit,
    removeAccount,
  } = AccountStore

  const { user } = RootStore
  let debitCards = debitWired

  if (params?.service_id === INTERNAL_TRANSFER_WALLET_ID) {
    debitCards = debitWired.filter((item) => item?.id !== 'agent')
  }

  useEffect(() => {
    if (!refresh) return
    if (params) {
      const { accountSelect, service_id } = params
      setAccountSelect(accountSelect)
      setServiceId(service_id)
    }
    const isSubAgent = !!user?.data?.sub_agent
    refreshDebit(serviceId, isSubAgent).finally(() => setRefresh(false))
    RootStore.getUserInfo()
  }, [params, refresh, refreshDebit])

  const openModal = (item) => {
    setModalVisible(true)
    setItem(item, true)
  }
  const closeModal = () => setModalVisible(false)

  const onSaveTitle = () => {
    passport
      .addAccount({
        id: selectedDebit?.db_id,
        name: selectedDebit?.name,
      })
      .then(() => {
        setRefresh(true)
        closeModal()
      })
  }

  const onPress = (item, db_id: boolean) => {
    let account =
      (db_id ? item.db_id : item.id) !== RootStore.user?.data.default_debit &&
      item
    if (accountSelected) {
      if (account) {
        if (!params?.onlyCrypto) {
          setDebit(account)
        } else {
          changeCryptoDebit(account)
        }
      }
      navigation.goBack()
    } else {
      if (!params?.onlyCrypto) {
        setDebit(account)
      } else {
        changeCryptoDebit(account)
      }
    }
  }

  const onRemove = (db_id) => {
    confirmAlert(
      t('confirmation'),
      t('are_you_sure_to_delete'),
      t('cancel'),
      t('yes'),
    ).then(() => removeAccount(db_id).then(() => setRefresh(true)))
  }

  let filterDebitUnWired = debitUnWired.filter((item) =>
    APP_ENV !== 'beta'
      ? item.id !== 'balance' &&
        item.id !== 'elsom' &&
        item.id !== 'megapay' &&
        item?.source !== 'NETEX'
      : item.id && item.id !== 'megapay' && item?.source !== 'NETEX',
  )

  if (params?.onlyCrypto) {
    filterDebitUnWired = debitUnWired.filter((item) => item?.source === 'NETEX')
  }

  const onRefresh = () => {
    refreshDebit(serviceId).finally(() => setRefresh(false))
  }

  return (
    <Screen
      onRefresh={onRefresh}
      backScreen={'home'}
      title={params?.onlyCrypto ? 'exchanges_type' : 'accounts_debit'}
      back
      rightComponent={
        !params?.onlyCrypto && (
          <ToolbarButton
            testID="addBtn"
            label="add"
            icon="plus"
            onPress={() => navigation.navigate('debit/add')}
          />
        )
      }
    >
      {!params?.onlyCrypto
        ? debitCards.length > 0 && <SubTitle>{t('binded')}</SubTitle>
        : null}
      <Row>
        {!params?.onlyCrypto
          ? debitCards.map((item, idx) => (
              <Col xs={12} sm={6} md={4} key={idx}>
                <AccountCard
                  pressTestID="bindedCheck"
                  item={item}
                  wired
                  requisite={
                    item.id === 'agent' ? AgentStore.sub_code : undefined
                  }
                  selectedItem={RootStore.user?.data.default_debit}
                  onRemove={
                    item.id !== ('agent' || 'terminal')
                      ? () => onRemove(item.db_id)
                      : undefined
                  }
                  onChange={
                    item.id !== ('agent' || 'terminal')
                      ? () => openModal(item)
                      : undefined
                  }
                  onPress={() => onPress(item, true)}
                  tid={`el-${idx}`}
                />
                <Spacer height={16} />
              </Col>
            ))
          : null}
      </Row>

      {debitUnWired.length > 0 && <SubTitle>{t('not_binded')}</SubTitle>}
      <Row>
        {filterDebitUnWired.map((item, idx) => (
          <Col xs={12} sm={6} md={4} key={idx}>
            <AccountCard
              pressTestID="notBindedCheck"
              item={item}
              selectedItem={RootStore.user?.data.default_debit}
              onPress={() => onPress(item, false)}
            />
            <Spacer />
          </Col>
        ))}
      </Row>

      <AccountDebitModal
        modalVisible={modalVisible}
        closeModal={closeModal}
        onSaveTitle={onSaveTitle}
        selectedDebit={selectedDebit}
        changeSelectedDebitName={changeSelectedDebitName}
        onPress={onPress}
      />
    </Screen>
  )
})

interface IAccountModalProps {
  modalVisible: boolean
  closeModal: () => void
  onSaveTitle: () => void
  selectedDebit: any
  changeSelectedDebitName: (val) => void
  onPress: (item, db_id: boolean) => void
}

const AccountDebitModal: FC<IAccountModalProps> = observer(
  ({
    modalVisible,
    closeModal,
    selectedDebit,
    changeSelectedDebitName,
    onSaveTitle,
    onPress,
  }) => {
    const { t } = useTranslation()

    return (
      <Modal
        testID="modal"
        title={'change_title'}
        visible={modalVisible}
        onClose={closeModal}
      >
        <View style={{ paddingHorizontal: 12, maxWidth: 320 }}>
          <AccountCard item={selectedDebit} empty />
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginVertical: 10,
            }}
          >
            <Text>{t('master_account')}</Text>
            <CheckBox
              value={
                RootStore.user?.data.default_debit === selectedDebit?.db_id
              }
              onChange={() => onPress(selectedDebit, true)}
            />
          </View>
          <Text color={colors.metal}>{t('master_account_text')}</Text>
          <Spacer />
          <Input
            testID="changeTitle"
            value={selectedDebit?.name}
            onChangeText={(val) => changeSelectedDebitName(val)}
          />
          <Spacer />
          <Button testID="save-title" onPress={onSaveTitle}>
            {t('save')}
          </Button>
        </View>
      </Modal>
    )
  },
)
