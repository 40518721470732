import { Layout, Modal as KittenModal, Spinner } from '@ui-kitten/components'
import capitalize from 'lodash/capitalize'
import { observer } from 'mobx-react'
import { SnapshotOut } from 'mobx-state-tree'
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  FlatList,
  Image as NativeImage,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import Modal from 'react-native-modal'
import { NoData } from './Components'
import Icon from './Icon'
import Image from './Image'
import { Input } from './Input'
import SearchBar from './SearchBar'
import Spacer from './Spacer'
import { Text } from './Text'
import {
  store,
  _constants,
  api,
  translate,
  utils,
  hooks,
  colors,
} from '@pay24/common'

const { AgentModel, LocalStorage, RootStore } = store
const { useResponsive, useTheme } = hooks
const {
  getCategoryCountries,
  filterCategoriesByCountry,
  COUNTRY_FLAGS,
  COUNTRIES_OBJECT,
} = utils
const { wallet, agent } = api
const { useTranslation } = translate
const { INTERNAL_TRANSFER_WALLET_ID, INTERNAL_TRANSFER_AGENT_ID } = _constants

const keyExtractor = (item: IPickerItem) =>
  'category' in item
    ? `category-${item.category.id}`
    : 'country' in item
    ? `country-${item.country}`
    : 'category_country' in item
    ? `category_country-${item.category_country}`
    : `service-${item.service.id}`

interface ICategory extends SnapshotOut<typeof AgentModel.AgentCategoryModel> {}

interface IService
  extends SnapshotOut<typeof AgentModel.AgentAllServicesModel> {}

interface ISelectServiceProps {
  service: IService | null
  onSelect: (service: IService | null) => void
  source?: ServiceSource
  showInModal?: boolean
  defaultCategory?: ICategory | null
}

type Type = 'country' | 'category_country' | 'category' | 'service'

interface PickerItemProps {
  item: IPickerItem

  onPress(item: ICategory | string | IService, type: Type): void
}

interface SearchListProps {
  source: ServiceSource
  queryText: string

  onSelect(service: IService | null)
}

enum PickerItemVariant {
  Parent = 'parent',
  Children = 'children',
}

type ServiceSource = 'wallet' | 'agent'
type IPickerItem = {
  variant: PickerItemVariant
} & (
  | { category: ICategory }
  | { country: string }
  | { service: IService }
  | { category_country: string }
)

// noinspection SpellCheckingInspection
const COUNTRIES = [
  'Кыргызстан',
  'Казахстан',
  'Узбекистан',
  'Таджикистан',
  'Россия',
]

function internalTransferIdBySource(source: ServiceSource): number {
  return source === 'wallet'
    ? INTERNAL_TRANSFER_WALLET_ID
    : INTERNAL_TRANSFER_AGENT_ID
}

function internalTransferBySource(source: ServiceSource, t): IPickerItem {
  return {
    variant: PickerItemVariant.Children,
    service: {
      blocked: false,
      category_name: t('internal_transfer'),
      contact_data: {},
      categoryid: INTERNAL_TRANSFER_WALLET_ID,
      commission: null,
      id: internalTransferIdBySource(source),
      image: 'logo-wallet.png',
      image_logo: 'logo-wallet.png',
      keyboard_type: 'default',
      max_amount: 500000.0,
      min_amount: 5.0,
      service_id: INTERNAL_TRANSFER_WALLET_ID,
      type: 'wallet_service',
      name: 'internal_transfer',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data: {},
    },
  }
}

const GOS_CATEGORY_ID = 86

async function fetchCategories(source: ServiceSource): Promise<ICategory[]> {
  const fetcher = source === 'wallet' ? wallet.getServices : agent.getServices
  const storeKey = source === 'wallet' ? 'categories' : 'agentCategories'
  try {
    const { categories } = await LocalStorage.get(storeKey)
    if (categories?.length > 0) {
      return categories
    }
  } catch {}
  let { categories } = await fetcher({ only_categories: true })
  categories = categories.sort((a, b) => a.sorter_id - b.sorter_id)
  try {
    await LocalStorage.set(storeKey, { categories })
  } catch {}
  return categories
}

function SelectService(props: ISelectServiceProps) {
  const {
    service,
    onSelect,
    source = 'wallet',
    showInModal = true,
    defaultCategory = null,
  } = props

  const [open, setOpen] = useState<boolean>(false)
  const showPicker = () => setOpen(true)
  const closePicker = () => setOpen(false)

  const [search, setSearch] = useState('')

  const [categories, setCategories] = useState<ICategory[]>([])
  const [category, setCategory] = useState<ICategory | null>(defaultCategory)
  const [services, setServices] = useState<IService[]>([])
  const [country, setCountry] = useState<string | null>(null)
  const [categoryCountry, setCategoryCountry] = useState<string | null>(null)

  const { t } = useTranslation()

  const INTERNAL_TRANSFER: IPickerItem = internalTransferBySource(source, t)
  const INTERNAL_TRANSFER_ID: number = internalTransferIdBySource(source)

  const parent_categories = useMemo(() => {
    const parent_categories_: ICategory[] = []
    let _category = category
    while (true) {
      if (!_category) break
      parent_categories_.push(_category)
      const parent_id = _category.parent
      _category = categories?.find((c) => c.id === parent_id) || null
    }
    return parent_categories_.reverse()
  }, [category, categories])

  const child_categories = useMemo(() => {
    return !category
      ? categories
          .filter((c) => c.parent === 0)
          .concat(categories.filter((c) => c.parent === 17))
      : categories.filter((c) => c.parent === category.id)
  }, [category, categories])

  const countries = useMemo(() => {
    const _countries = new Set(
      services.map((s) => s.data?.contact_data?.country || '').filter(Boolean),
    )
    const found_counties = COUNTRIES.filter((c) => _countries.has(c))
    _countries.size !== found_counties.length &&
      found_counties.push('Другие страны')
    return found_counties
  }, [services])

  const country_services = useMemo(() => {
    if (!country) return []
    if (COUNTRIES.includes(country)) {
      return services.filter((s) => {
        const c = s.data?.contact_data?.country
        return c === country || c === 'Международные'
      })
    }
    return services.filter((s) => {
      const c = s.data?.contact_data?.country || ''
      return !COUNTRIES.includes(c) || c === 'Международные'
    })
  }, [country, services])

  const categoryCountries = getCategoryCountries(child_categories)
  const categoriesByCountry = child_categories.filter((item) =>
    filterCategoriesByCountry(item, categoryCountry),
  )

  const data = useMemo(() => {
    const _data = [
      ...parent_categories
        .map((c) => ({
          variant: PickerItemVariant.Parent,
          category: c,
        }))
        .slice(defaultCategory ? 1 : 0),
      ...(categoryCountries.length > 1
        ? categoryCountry
          ? [
              {
                variant: PickerItemVariant.Parent,
                category_country: categoryCountry,
              },
            ]
          : categoryCountries.map((c) => ({
              variant: PickerItemVariant.Children,
              category_country: c,
            }))
        : []),
      ...categoriesByCountry.map((c) => ({
        variant: PickerItemVariant.Children,
        category: c,
      })),
      ...(categoryCountries.length > 1
        ? []
        : child_categories
            .sort(
              category?.id === GOS_CATEGORY_ID
                ? (a, b) => a.name.localeCompare(b.name)
                : undefined,
            )
            .map((c) => ({
              variant: PickerItemVariant.Children,
              category: c,
            }))),
      ...(countries.length > 1
        ? country
          ? [{ variant: PickerItemVariant.Parent, country }]
          : countries.map((c) => ({
              variant: PickerItemVariant.Children,
              country: c,
            }))
        : []),
      ...country_services
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((s) => ({
          variant: PickerItemVariant.Children,
          service: s,
        })),
    ]
    if (!parent_categories.length) {
      _data.unshift(INTERNAL_TRANSFER)
    }
    return _data
  }, [
    parent_categories,
    defaultCategory,
    categoryCountries,
    categoryCountry,
    categoriesByCountry,
    child_categories,
    countries,
    country,
    country_services,
    INTERNAL_TRANSFER,
  ])

  useEffect(() => {
    if (!open && showInModal) return
    let active = true
    fetchCategories(source).then((_categories) => {
      active && setCategories(_categories.filter((c) => c.id !== 17))
    })
    return () => {
      active = false
    }
  }, [open, showInModal, source])

  const fetchServices = useCallback<(category_id: number) => () => void>(
    (category_id) => {
      const fetcher =
        source === 'wallet' ? wallet.getServices : agent.getServices
      const req = fetcher({ category_id })
      req.then((resp) =>
        setServices(
          resp.services
            .filter((s) => !s.is_down && !s.blocked)
            .sort((a, b) => a.sorter_id - b.sorter_id),
        ),
      )
      return req.cancel
    },
    [source],
  )

  const category_id =
    (open || !showInModal) && category && child_categories.length === 0
      ? category.id
      : null

  useEffect(() => {
    if (!category_id) return
    return fetchServices(category_id)
  }, [category_id, fetchServices])

  useEffect(() => {
    if (!countries.length || countries.length > 1) return
    setCountry(countries[0])
  }, [countries])

  const onPressItem = useCallback(
    (item: ICategory | string | IService, type: Type) => {
      if (typeof item === 'string') {
        if (type === 'country') {
          setCountry((c) => (c ? null : item))
        } else if (type === 'category_country') {
          setCategoryCountry((c) => (c ? null : item))
        }
      } else if ('parent' in item) {
        const parent = parent_categories.some((c) => c.id === item.id)
          ? parent_categories.find((c) => c.id === item.parent) || null
          : item
        setCategory((c) => {
          setCountry(null)
          setCategoryCountry(null)
          if (c !== parent) {
            setServices([])
          }
          return parent
        })
      } else if (
        'categoryid' in item ||
        (typeof item === 'object' &&
          (item as ICategory).id === INTERNAL_TRANSFER_ID)
      ) {
        onSelect(item)
        closePicker()
      }
    },
    [onSelect, parent_categories],
  )

  const onPressService = useCallback(
    (item: IService) => {
      onSelect(item)
      closePicker()
    },
    [onSelect],
  )

  const renderItem = useCallback(
    ({ item }: { item: IPickerItem }) => {
      return <PickerItem item={item} onPress={onPressItem} />
    },
    [onPressItem],
  )

  const { sm } = useResponsive()
  const ModalWrapper = sm ? CategoryModalWeb : CategoryModalNative

  let content

  if (search.length >= 3) {
    content = (
      <SearchServicesList
        source={source}
        queryText={search}
        onSelect={onPressService}
      />
    )
  } else {
    content = (
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListFooterComponent={RootStore.isBusy ? <Loader /> : null}
      />
    )
  }

  if (!showInModal) {
    return (
      <>
        <SearchBar
          placeholder={t('search_by_services')}
          value={search}
          onChangeText={setSearch}
        />
        <Spacer />
        {content}
      </>
    )
  }

  return (
    <>
      <Input
        label={t('service')}
        value={t(service?.name || '')}
        onChangeText={(value) => !value && onSelect(null)}
        editable={false}
        clearable
        placeholder={t('select_service')}
        onPress={showPicker}
        overlayPress
      />
      <ModalWrapper visible={open} closeModal={closePicker}>
        <ModalHeader
          closePicker={closePicker}
          search={search}
          setSearch={setSearch}
        />
        {content}
      </ModalWrapper>
    </>
  )
}

export default observer(SelectService)

function CountryIcon({ name }: { name: string }) {
  return COUNTRY_FLAGS[name] ? (
    <NativeImage
      source={COUNTRY_FLAGS[name]}
      style={{ width: 24, height: 24 }}
    />
  ) : (
    <View style={{ width: 24, height: 24 }} />
  )
}

function ServiceIcon({
  source,
  isService,
}: {
  source?: string | null
  isService: boolean
}) {
  return source ? (
    <Image
      image_name={source}
      style={{
        width: 36,
        height: 36,
        borderRadius: 4,
        backgroundColor: isService ? colors.white : 'transparent',
      }}
      resizeMode="contain"
    />
  ) : (
    <View style={{ width: 24, height: 24 }} />
  )
}

function InternalTransferIcon() {
  return (
    <View
      style={{
        backgroundColor: colors.blue,
        borderRadius: 36,
        width: 36,
        height: 36,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Icon name="share-all-outline" color="white" size={20} />
    </View>
  )
}

const PickerItem = observer(function PickerItem({
  item,
  onPress,
}: PickerItemProps) {
  const { t, i18n } = useTranslation()
  const { isLight } = useTheme()
  const _onPress = () => {
    'category' in item && onPress(item.category, 'category')
    'country' in item && onPress(item.country, 'country')
    'service' in item && onPress(item.service, 'service')
    'category_country' in item &&
      onPress(item.category_country, 'category_country')
  }
  const image_logo =
    'category' in item
      ? item.category.image_logo
      : 'service' in item
      ? item.service.image_logo
      : null
  const label =
    'category' in item
      ? i18n.language === 'en'
        ? item.category.name_eng || item.category.name
        : i18n.language === 'kg'
        ? item.category.name_kgs || item.category.name
        : item.category.name
      : 'country' in item
      ? COUNTRIES_OBJECT[item.country]
      : 'category_country' in item
      ? COUNTRIES_OBJECT[item.category_country]
      : item.service.name
  const testId =
    'category' in item
      ? `category_item_${item.category.id}`
      : 'country' in item
      ? `country_${item.country}`
      : 'category_country' in item
      ? `category_country_${item.category_country}`
      : `service_item_${item.service.id}`

  return (
    <TouchableOpacity
      style={[
        styles.itemContainer,
        {
          borderColor: isLight ? colors.borderLight : colors.borderDark,
        },
      ]}
      onPress={_onPress}
      testID={testId}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {item.variant === PickerItemVariant.Parent && (
          <Icon name="chevron-left" size={24} color={colors.metal} />
        )}
        {'country' in item ? (
          <CountryIcon name={item.country} />
        ) : 'category_country' in item ? (
          <CountryIcon name={item.category_country} />
        ) : 'service' in item &&
          (item.service.id === INTERNAL_TRANSFER_AGENT_ID ||
            item.service.id === INTERNAL_TRANSFER_WALLET_ID) ? (
          <InternalTransferIcon />
        ) : item.variant === PickerItemVariant.Children ? (
          <ServiceIcon source={image_logo} isService={'service' in item} />
        ) : null}
        <View style={{ flex: 1, marginLeft: 8 }}>
          <Text>{capitalize(t(label))}</Text>
        </View>
        {item.variant === PickerItemVariant.Children &&
          !('service' in item) && (
            <Icon name="chevron-right" size={24} color={colors.metal} />
          )}
      </View>
    </TouchableOpacity>
  )
})

const SearchServicesList = observer(function SearchServicesList(
  props: SearchListProps,
) {
  const { source, queryText = '', onSelect } = props
  const [searsService, setSearchService] = useState(queryText)
  const [services, setServices] = useState<IService[]>([])

  const fetchServices = useCallback<(text: string) => () => void>(
    (text) => {
      const fetcher =
        source === 'wallet' ? wallet.getServicesSearch : agent.getServicesSearch
      const req = fetcher(text)
      req.then((resp) =>
        setServices(
          resp.services
            .filter((s) => !s.is_down && !s.blocked)
            .sort((a, b) => a.sorter_id - b.sorter_id),
        ),
      )
      return req.cancel
    },
    [source],
  )

  useEffect(() => {
    const id = setTimeout(() => setSearchService(queryText), 1e3)
    return () => clearTimeout(id)
  }, [queryText])

  useEffect(() => fetchServices(searsService), [searsService, fetchServices])

  const renderItem = useCallback(
    ({ item }: { item: IService }) => {
      return <SearchServiceItem service={item} onPress={onSelect} />
    },
    [onSelect],
  )

  return (
    <FlatList
      data={services}
      renderItem={renderItem}
      keyExtractor={(item, index) => `${index}-${item.id.toString()}`}
      ListEmptyComponent={RootStore.isBusy ? <Loader /> : <NoData />}
      refreshing={RootStore.isBusy}
    />
  )
})

const SearchServiceItem = observer(function SearchServiceItem({
  service,
  onPress,
}: {
  service: IService
  onPress: (service: IService) => void
}) {
  const { isLight } = useTheme()
  const _onPress = () => onPress(service)
  const { name, category_name } = service
  const { country } = service.data?.contact_data || {}
  return (
    <TouchableOpacity
      style={[
        styles.itemContainer,
        {
          borderColor: isLight ? colors.borderLight : colors.borderDark,
        },
      ]}
      onPress={_onPress}
      testID={`search_service_item_${service.id}`}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <ServiceIcon source={service.image_logo} isService />
        <View style={{ flex: 1 }}>
          <Text>{name}</Text>
          {category_name && country ? (
            <Text size={12} color={colors.metal}>
              {capitalize(category_name)} / {service.data?.contact_data.country}
            </Text>
          ) : null}
        </View>
      </View>
    </TouchableOpacity>
  )
})

function ModalTitle({ closeModal }) {
  const { t } = useTranslation()
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 25,
      }}
    >
      <Text category="h1" size={16}>
        {t('services')}
      </Text>
      <Icon name="close" onPress={closeModal} testID="close_modal" />
    </View>
  )
}

function ModalHeader({
  closePicker,
  search,
  setSearch,
}: {
  closePicker: () => void
  search: string
  setSearch: Dispatch<SetStateAction<string>>
}) {
  const { t } = useTranslation()
  return (
    <View style={{ paddingHorizontal: 16, paddingVertical: 13 }}>
      <ModalTitle closeModal={closePicker} />
      <SearchBar
        placeholder={t('search_by_services')}
        value={search}
        onChangeText={setSearch}
      />
    </View>
  )
}

function CategoryModalWeb({ visible, closeModal, children }) {
  return (
    <KittenModal
      visible={visible}
      onBackdropPress={closeModal}
      backdropStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      style={{ height: 500, width: 400 }}
    >
      <Layout style={{ flex: 1, borderRadius: 5 }}>{children}</Layout>
    </KittenModal>
  )
}

function CategoryModalNative({ visible, closeModal, children }) {
  return (
    <Modal
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={visible}
      onBackdropPress={closeModal}
      onBackButtonPress={closeModal}
      style={{ margin: 0, marginTop: 100, flex: 1 }}
    >
      <Layout style={{ flex: 1 }}>{children}</Layout>
    </Modal>
  )
}

function Loader() {
  return (
    <View style={{ alignItems: 'center', marginTop: 8 }}>
      <Spinner />
    </View>
  )
}

const styles = StyleSheet.create({
  itemContainer: {
    paddingHorizontal: 8,
    paddingVertical: 8,
    borderBottomWidth: 1,
  },
})
