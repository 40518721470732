import { translate } from '@pay24/common'
import React from 'react'
import { Text } from '../Text'

const { useTranslation } = translate

interface MenuTitleProps {
  title?: string
  color?: string
  bold?: boolean
}

export const MenuTitle = (props: MenuTitleProps) => {
  const { title, color, bold } = props
  const { t } = useTranslation()
  return (
    <Text
      numberOfLines={1}
      style={{
        fontFamily: 'System',
        fontSize: 16,
        marginHorizontal: 8,
        width: '100%',
        fontWeight: bold ? '500' : '400',
      }}
      color={color}
    >
      {t(title || '')}
    </Text>
  )
}
