import { PagesProperty } from '@pay24/common/src/hooks'
import { OPERATION } from '@pay24/common/src/store/PaymentHistoryStore'
import React, { useEffect, useState } from 'react'
import { utils, api, translate, hooks } from '@pay24/common'
import { Pressable, View } from 'react-native'
import { TabButton } from '../../Button'
import { Divider, ItemContainer, NoData, TextDetail } from '../../Components'
import Icon from '../../Icon'
import Spacer from '../../Spacer'
import { Text } from '../../Text'
import { PageName, PAGES } from '../types'
import { observer } from 'mobx-react'

const { dateTimeFormat, getStatusText, amountFormatCurrency } = utils
const { useTheme } = hooks
const { useTranslation } = translate
const { pay24 } = api

export default function ({ page, navigation, openPage, onClose }) {
  return (
    <LastPayments
      navigation={navigation}
      page={page}
      openPage={openPage}
      onClose={onClose}
    />
  )
}

interface LastPaymentsProperty {
  navigation: any
  page: any
  openPage: (val: PagesProperty<PageName, any>) => void
  onClose: () => void
}

const LastPayments = observer(
  ({ navigation, page, openPage, onClose }: LastPaymentsProperty) => {
    const [history, setHistory] = useState<any>([])
    const { primary } = useTheme()
    const { t } = useTranslation()

    useEffect(() => {
      const param = {
        service_id: page.params?.serviceId,
        limit: 10,
        dc: 'd',
      }
      pay24.getLastRecords(param).then((res) => {
        setHistory(res?.records || [])
      })
    }, [])

    const navigateToHistory = () => {
      navigation.navigate('history/payment')
      onClose()
    }

    if (!history.length) {
      return (
        <NoData
          title={t(
            page.params?.serviceId === '999999'
              ? 'no_last_transfers'
              : 'no_last_payments',
          )}
        />
      )
    }

    return (
      <>
        {history.map((item: any, idx) => (
          <ItemContainer
            key={idx}
            style={{ paddingTop: 12, paddingHorizontal: 12, marginBottom: 10 }}
          >
            <Text style={{ fontWeight: 'bold' }}>
              {item.data?.payload?.service_name}
            </Text>
            <Spacer />
            <TextDetail
              name={'requisite'}
              value={item.data?.payload?.requisite}
            />
            <TextDetail
              name={'amount'}
              value={amountFormatCurrency(item.data?.payload?.amount, true)}
            />
            <TextDetail
              name={'payment_commission'}
              value={amountFormatCurrency(
                item.data?.payload?.paymentCommission,
                true,
              )}
            />
            <TextDetail
              name={'acquiring_commission'}
              value={amountFormatCurrency(
                item.data?.payload?.commission -
                  item.data?.payload?.paymentCommission >
                  0
                  ? item.data?.payload?.commission -
                      item.data?.payload?.paymentCommission
                  : 0,
                true,
              )}
            />
            <TextDetail
              name={'total'}
              value={amountFormatCurrency(
                item?.data?.payload?.totalAmount,
                true,
              )}
            />
            <Spacer />
            <TextDetail name={'date'} value={dateTimeFormat(item.rec_date)} />
            <TextDetail
              name={'status'}
              value={getStatusText(item.status)}
              status={item.status}
            />
            <Divider style={{ marginHorizontal: -12, marginTop: 10 }} />
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              {item.dc === OPERATION.EXPENSE &&
              !item.data?.requisite?.includes('***') ? (
                <Pressable
                  style={{ padding: 10 }}
                  onPress={() => {
                    const param = {
                      amount: parseFloat(item.data?.payload?.amount),
                      requisite: item.data?.payload?.requisite,
                      serviceId: item.data?.payload?.service_id,
                    }
                    navigation.navigate('payment', param)
                    onClose()
                  }}
                >
                  <Icon name="refresh" color={primary} />
                </Pressable>
              ) : null}
              {item.dc === OPERATION.EXPENSE &&
              !item.data?.requisite?.includes('***') ? (
                <Pressable
                  style={{ padding: 10 }}
                  onPress={() => {
                    const param = {
                      requisite: item.data?.payload?.requisite,
                      service_id: item.data?.payload?.service_id,
                      options: item.data?.payload?.options,
                    }
                    navigation.navigate('favorite', { favorite: param })
                    onClose()
                  }}
                >
                  <Icon name="heart-outline" color={primary} />
                </Pressable>
              ) : null}
              <Pressable
                style={{ padding: 10 }}
                onPress={() => {
                  openPage({
                    screen: PAGES.CHEQUE,
                    params: { id: item.id },
                  })
                }}
              >
                <Icon name="text-box-outline" color={primary} />
              </Pressable>
            </View>
          </ItemContainer>
        ))}
        <Spacer />
        {history.length >= 10 && (
          <TabButton testID="showHistory" onPress={navigateToHistory}>
            {t('show_history')}
          </TabButton>
        )}
        <Spacer />
      </>
    )
  },
)
